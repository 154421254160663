/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

/** Import helpers section **/
import { toast } from 'react-toastify';
import moment from 'moment';

/** Import services section **/
import {
  getWorkOrderStatusService,
  getWorkOrdersService,
  deleteWorkOrderService,
  workOrderPDFService
} from '../../api/services/work-orders-services';
import { getSelectClientsService } from '../../api/services/clients-services';
import { getResponsibleListService } from '../../api/services/users-services';

/** Import resources section **/
import {
  WORK_ORDER_SERVICES_ROUTE,
  ASSIGNATIONS_ROUTE,
  CLIENT_DOCUMENTS_STORAGE_ROUTE
} from '../../config/URL_ROUTES';

/** Import component section **/
import Modal from '../../components/Modal';
import WorkOrdersView from './WorkOrdersView';
import WorkOrderModal from './WorkOrderModal';

const WorkOrderModel = {
  id: null,
  proposal: '',
  work_order_status_id: 1,
  order_num: null,
  comments: ''
};

const currentYear = moment().format('Y');
const yearModel = {
  id: null,
  year: currentYear
};

const WorkOrdersComponent = () => {
  const [clients, setClients] = useState([]);
  const [responsibles, setResponsibles] = useState([]);
  const [status, setStatus] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [workOrders, setWorkOrders] = useState([]);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);
  const [modalMode, setModalMode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(yearModel);
  let history = useHistory();

  const getClients = useCallback(() => {
    getSelectClientsService()
      .then((response) => {
        if (response?.clients) setClients(response?.clients);
      })
      .catch((error) => {
        console.error(
          'WorkOrders Component - Error requesting clients list: ',
          error
        );
      });
  }, []);

  const getResponsibles = useCallback(() => {
    getResponsibleListService()
      .then((response) => {
        if (response?.responsibles) setResponsibles(response?.responsibles);
      })
      .catch((error) => {
        toast.warning('Error al conseguir los responsables');
      });
  }, []);

  const getWorkOrderStatus = useCallback(() => {
    getWorkOrderStatusService()
      .then((response) => {
        if (response?.status) setStatus(response?.status);
      })
      .catch((error) => {
        console.error(
          'WorkOrders Component - Error requesting clients list: ',
          error
        );
      });
  }, []);

  const getWorkOrders = useCallback(() => {
    setLoading(true);
    let params = {
      work_order_status_id: selectedTab === 0 ? 1 : 2,
      year: selectedYear?.year
    };
    getWorkOrdersService(params)
      .then((response) => {
        if (response?.work_orders) setWorkOrders(response?.work_orders);
        setOriginalData(response?.work_orders);
        if (response?.years) setYears(response?.years);
      })
      .catch((error) => {
        console.error(
          'WorkOrders Component - Error requesting workOrders list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedTab, selectedYear]);

  useEffect(() => {
    getClients();
    getResponsibles();
    getWorkOrderStatus();
    getWorkOrders();
  }, [getClients, getWorkOrderStatus, getWorkOrders, getResponsibles]);

  const handleOnUpdateList = (selectedWorkOrder) => {
    getWorkOrders();
  };

  const handleOnFilterWorkOrders = (filterVal) => {
    let filteredItems;

    if (!filterVal || filterVal.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterVal
        ?.toLowerCase()
        ?.normalize('NFD')
        ?.replace(/[\u0300-\u036f]/g, '');

      filteredItems = originalData.filter((item) => {
        const field1 = String(item?.order_num).padStart(4, '0').toLowerCase();
        const field2 = item?.client?.title
          ?.toString()
          ?.toLowerCase()
          ?.normalize('NFD')
          ?.replace(/[\u0300-\u036f]/g, '');
        const field3 = item?.responsible_name
          .toString()
          .toLowerCase()
          ?.normalize('NFD')
          ?.replace(/[\u0300-\u036f]/g, '');
        const field4 = item?.proposal
          .toString()
          .toLowerCase()
          ?.normalize('NFD')
          ?.replace(/[\u0300-\u036f]/g, '');
        return (
          field1.match(filteringText) ||
          field2.match(filteringText) ||
          field3.match(filteringText) ||
          (field4.match(filteringText) && true)
        );
      });
    }

    setWorkOrders(filteredItems);
  };

  const handleOnAddWorkOrder = () => {
    const serviceItem = Object.assign({}, WorkOrderModel);
    setSelectedWorkOrder(serviceItem);
    setModalMode('edit');
  };

  const handleOnEditWorkOrder = (row) => {
    const serviceItem = Object.assign({}, row);
    setSelectedWorkOrder(serviceItem);
    setModalMode('edit');
  };

  const handleOnModalClose = () => {
    setSelectedWorkOrder(null);
    setModalMode(null);
  };

  const handleOnDeleteWorkOrder = (row) => {
    setSelectedWorkOrder(row);
    setModalMode('delete');
  };
  const handleOnDownloadPdf = (row) => {
    workOrderPDFService({ id: row?.id })
      .then((response) => {
        window.open(response?.url, '_blank');
      })
      .catch((err) => {
        if (err?.response_status === 406) {
          if (err?.errors === 'noState') {
            toast.warning('Registre el estado del cliente');
          }
        }
      })
      .finally(() => {});
  };

  const handleOnDeleteConfirm = () => {
    deleteWorkOrderService({ id: selectedWorkOrder.id })
      .then(() => {
        getWorkOrders();
        toast.success('Servicio borrado');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar el servicio.');
      })
      .finally(() => {
        setSelectedWorkOrder(null);
        setModalMode(null);
      });
  };

  const handleOnWorkOrderServices = (row) => {
    history.push(WORK_ORDER_SERVICES_ROUTE + '/' + row?.id);
  };

  const handleOnAssignationView = (row) => {
    history.push({
      pathname: ASSIGNATIONS_ROUTE + '/' + row?.id
    });
  };

  const handleOnTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleOnInputChangeYear = (data) => {
    console.log(data);
    const { name, value } = data;
    setSelectedYear((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnClientRequiredDocs = (row) => {
    console.log(row?.client_id);
    const clientId = row?.client_id;
    history.push({
      pathname: CLIENT_DOCUMENTS_STORAGE_ROUTE,
      state: {
        from: 'clients',
        clientId: clientId
      }
    });
  };

  return (
    <>
      <WorkOrdersView
        loading={loading}
        workOrders={workOrders}
        onFilterWorkOrders={handleOnFilterWorkOrders}
        onDownloadPdf={handleOnDownloadPdf}
        onAddWorkOrder={handleOnAddWorkOrder}
        onEditWorkOrder={handleOnEditWorkOrder}
        onWorkOrderServices={handleOnWorkOrderServices}
        onDeleteWorkOrder={handleOnDeleteWorkOrder}
        onAssignationView={handleOnAssignationView}
        onTabChange={handleOnTabChange}
        selectedTab={selectedTab}
        years={years}
        onInputChangeYear={handleOnInputChangeYear}
        selectedYear={selectedYear}
        onRequiredDocs={handleOnClientRequiredDocs}
      />
      {selectedWorkOrder && modalMode === 'edit' && (
        <WorkOrderModal
          workOrderData={selectedWorkOrder}
          status={status}
          clients={clients}
          responsibles={responsibles}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedWorkOrder && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar orden de trabajo"
        >
          {'¿Estas seguro que quieres borrar la orden de trabajo ' +
            selectedWorkOrder.order_num +
            '?'}
        </Modal>
      )}
    </>
  );
};

export default WorkOrdersComponent;
