/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';

/** Import helpers section **/
import { toast } from 'react-toastify';
import './AssignationsStyles.scss';

/** Import services section **/
import { getAssignationsService } from '../../api/services/assignations-services';
import { workOrderPDFService } from '../../api/services/work-orders-services';
import { updateStatusWorkOrderService } from '../../api/services/work-orders-services';
import { autorizedDocumentService } from '../../api/services/work-order-services-services';

/** Import resources section **/
// import { ASSIGNATION_DELIVERABLES_ROUTE } from '../../config/URL_ROUTES';
import {
  CLIENT_DOCUMENTS_ROUTE,
  WORK_ORDERS_ROUTE,
  CLIENT_WORKORDERS_STATUS_ROUTE
} from '../../config/URL_ROUTES';

/** Import component section **/
import SpinnerModal from '../../components/SpinnerModal';
import AssignationsView from './AssignationsView';
import AssignationModal from './AssignationModal';
import MessageModal from './MessageModal';
import Modal from '../../components/Modal';
import AssignationDeliverableModalComponent from './AssignationDeliverableModal/AssignationDeliverableModalComponent';
import ClientModalComponent from '../Clients/ClientModal/ClientModalComponent';

// const IN_PROCESS_STATUS_ID = 1;

const AssignationsComponent = () => {
  const [workOrders, setWorkOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalMode, setModalMode] = useState('');
  const [pdfLoading, setPdfLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  let params = useParams();
  let workOrderId = params['id'];

  let location = useLocation();
  let backUrl =
    location?.state?.from === 'orderStatus'
      ? CLIENT_WORKORDERS_STATUS_ROUTE
      : WORK_ORDERS_ROUTE;
  const WORK_ORDER_CLOSE_ID = 2;

  const history = useHistory();
  const getAssignations = useCallback((workOrderId) => {
    setLoading(true);
    const processParams = {
      id: workOrderId
    };
    getAssignationsService(processParams)
      .then((response) => {
        const workOrdersArr = response?.work_orders;
        if (workOrdersArr) {
          setWorkOrders(workOrdersArr);
        }
      })
      .catch((error) => {
        toast.error('No se pudieron leer los documentos');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleOnDeliverables = (row) => {
    // history.push(ASSIGNATION_DELIVERABLES_ROUTE + '/' + row?.id);
    setSelectedItem(row);
    setModalMode('drive-document');
  };

  const handleOnModalClose = () => {
    setSelectedItem(null);
    setModalMode('');
  };

  const handleOnMessages = (row) => {
    setSelectedItem(row);
    setModalMode('messages');
  };

  const handleOnDownloadPDF = (row) => {
    const worOrderId = row?.id;
    if (!worOrderId) return;
    setPdfLoading(true);
    workOrderPDFService({ id: worOrderId })
      .then((response) => {
        window.open(response?.url, '_blank');
      })
      .catch((err) => {
        if (err?.response_status) toast.error('Error, no puede obtener el pdf');
        console.error('Error getting pdf: ', err);
      })
      .finally(() => {
        setPdfLoading(false);
      });
  };

  useEffect(() => {
    getAssignations(workOrderId);
  }, [getAssignations, workOrderId]);

  const handleOnClientDocs = (row) => {
    history.push({
      pathname: CLIENT_DOCUMENTS_ROUTE + '/' + row?.client_id,
      state: {
        from: 'assignations',
        orderId: workOrderId
      }
    });
  };

  const handleOnAuthorizedDocs = (row) => {
    if (!row?.id) {
      return;
    }
    setSelectedItem(row);
    setModalMode('authorize');
  };

  const authorizedDocs = () => {
    if (!selectedItem.id) {
      return;
    }

    const authorized =
      selectedItem.authorized === 1 ? 'deshabilitado' : 'habilitado';

    autorizedDocumentService({
      work_order_service_id: selectedItem.id
    })
      .then(() => {
        toast.success('Documento ha sido ' + authorized + ' correctamente');
        getAssignations(workOrderId);
      })
      .catch((error) => {
        toast.warning('Error al ' + authorized + ' el documento');
      });
    handleOnModalClose();
  };

  const handleOnDriveRedirect = (row) => {
    window.open(row.url, '_blank');
  };

  const handleOnRefreshList = (row) => {
    getAssignations(workOrderId);
  };

  const handleOnInfoClient = (row) => {
    setSelectedClient(row?.client);
    setModalMode('clients');
  };

  const handleOnModalClientClose = (row) => {
    setSelectedClient(null);
    setModalMode('');
  };

  const handleOnCloseOrder = (row) => {
    setSelectedItem(row);
    setModalMode('close-order');
  };

  const onConfirmCloseOrder = () => {
    let params = {
      id: selectedItem.id,
      work_order_status_id: WORK_ORDER_CLOSE_ID
    };
    updateStatusWorkOrderService(params)
      .then(() => {
        toast.success('Órden cerrada correctamente');
        getAssignations(workOrderId);
      })
      .catch((error) => {
        toast.warning('Error al cerrar la órden');
      });
    handleOnModalClose();
  };

  const handleOnRedirectToWOServices = (workOrderId) => {
    const url = window.location.origin + '/servicios-orden/' + workOrderId;
    if (!!url) window.open(url, '_blank');
  };

  const handleOnPaymentAlert = () => {
    setModalMode('payment-alert');
  };

  return (
    <>
      <AssignationsView
        loading={loading}
        workOrders={workOrders}
        onDriveRedirect={handleOnDriveRedirect}
        onMessages={handleOnMessages}
        onDownloadPDF={handleOnDownloadPDF}
        onClientDocs={handleOnClientDocs}
        onDeliverables={handleOnDeliverables}
        onAuthorizedDocument={handleOnAuthorizedDocs}
        onInfoClient={handleOnInfoClient}
        backUrl={backUrl}
        onCloseOrder={handleOnCloseOrder}
        onRedirectToWOServices={handleOnRedirectToWOServices}
        onPaymentAlert={handleOnPaymentAlert}
      />
      {selectedItem && modalMode === 'info' && (
        <AssignationModal
          assignation={selectedItem}
          onClose={handleOnModalClose}
        />
      )}
      {selectedItem && modalMode === 'messages' && (
        <MessageModal assignation={selectedItem} onClose={handleOnModalClose} />
      )}
      {pdfLoading && <SpinnerModal open={pdfLoading} />}
      {selectedItem && modalMode === 'authorize' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={authorizedDocs}
          title={
            !!selectedItem?.authorized
              ? 'Deshabilitar documento'
              : 'Habilitar documento'
          }
        >
          <div>
            {'¿Estas seguro que quieres ' +
              (!!selectedItem?.authorized ? 'habilitar ' : 'deshabilitar ') +
              ' el documento ' +
              selectedItem?.service_name +
              '?'}
          </div>
        </Modal>
      )}
      {selectedItem && modalMode === 'drive-document' && (
        <AssignationDeliverableModalComponent
          document={selectedItem}
          onClose={handleOnModalClose}
          onUpdateList={handleOnRefreshList}
        />
      )}
      {selectedClient && modalMode === 'clients' && (
        <ClientModalComponent
          clientData={selectedClient}
          onClose={handleOnModalClientClose}
          onUpdateList={handleOnModalClose}
          view="assignations"
        />
      )}
      {selectedItem && modalMode === 'close-order' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={onConfirmCloseOrder}
          title={'Cerrar órden'}
        >
          <div>{'¿Estas seguro que quieres cerrar esta órden de trabajo?'}</div>
        </Modal>
      )}

      {modalMode === 'payment-alert' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={null}
          title={'Error al descargar documento'}
        >
          <div>
            El documento podra ser descargado una vez que este liquidado, para
            más información puede comunicarse a: Contabilidad 442 455 2145 Ext.
            202
          </div>
        </Modal>
      )}
    </>
  );
};

export default AssignationsComponent;
