import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  filterBy: {
    width: '100%',
    minWidth: '100px'
  }
}));

export const FilterBy = ({ onChange, width, ...props }) => {
  const classes = useStyles();
  const divStyle = {
    width: width ? width : '100%'
  };

  return (
    <div style={divStyle}>
      <TextField
        label="Filtrar por..."
        className={classes.filterBy}
        fullWidth
        onChange={(el) => {
          onChange(el.target.value);
        }}
      />
    </div>
  );
};

export default FilterBy;
