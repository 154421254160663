/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/
import {
  addClientDocumentService,
  editClientDocumentService
} from '../../../api/services/client-documents-services';

import { documentStatusCatalogService } from '../../../api/services/documents-catalogue-services';

/** Import component section **/
import ClientDocumentModalView from './ClientDocumentModalView';

const ClientDocumentModalComponent = ({
  isClientAccess,
  document,
  documentTypes,
  clientId,
  onClose,
  onUpdateList
}) => {
  const [documentData, setDocumentData] = useState(document);
  const [dataComplete, setDataComplete] = useState(false);
  const [documentStatus, setDocumentStatus] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const getDocumentStatus = useCallback(() => {
    documentStatusCatalogService()
      .then((response) => {
        if (response?.document_status)
          setDocumentStatus(response.document_status);
      })
      .catch((error) => {
        console.error(
          'Documents status - Error requesting document types list: ',
          error
        );
      });
  }, []);

  useEffect(() => {
    setDocumentData(document);
    getDocumentStatus();
    if (document?.document_type_id)
      setSelectedDocument(document?.document_type_id);
  }, [document, getDocumentStatus]);

  const handleOnInputChange = (data) => {
    const { name, value } = data;
    console.log(data?.document_category);
    if (data?.document_category) {
      setSelectedDocument(data?.id);
      setDocumentData((prevState) => ({
        ...prevState,
        ['document_type_id']: data?.id
      }));
    } else {
      setDocumentData((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  useEffect(() => {
    let validated = false;

    if (selectedDocument !== null) {
      validated = true;
    }

    setDataComplete(validated);
  }, [selectedDocument]);

  const handleOnSave = () => {
    documentData.id ? handleOnEdit() : handleOnCreate();
  };

  const handleOnCreate = () => {
    const data = {
      client_id: clientId,
      document_type_id: selectedDocument,
      document_date: documentData.document_date,
      url: documentData.url,
      validity_date: documentData.validity_date,
      status_id: documentData.status_id,
      folio: documentData.folio,
      comments: documentData.comments
    };

    addClientDocumentService(data)
      .then((response) => {
        toast.success('Documento agregado');
        onUpdateList(response);
      })
      .catch((error) => {
        toast.error('No se pudo agregar el documento');
      });
  };
  const handleOnEdit = () => {
    const data = {
      id: documentData.id,
      client_id: clientId,
      document_type_id: selectedDocument || null,
      document_date: documentData.document_date,
      url: documentData.url,
      validity_date: documentData.validity_date,
      status_id: documentData.status_id,
      folio: documentData.folio,
      comments: documentData.comments
    };
    editClientDocumentService(data)
      .then((response) => {
        toast.success('Documento editado');
        onUpdateList(response);
      })
      .catch((error) => {
        toast.error('No se pudo editar el documento');
      });
  };

  const handleOnDocumentChange = (data) => {
    setSelectedDocument(data?.id);
  };

  return (
    <ClientDocumentModalView
      isClientAccess={isClientAccess}
      document={documentData}
      documentTypes={documentTypes}
      onInputChange={handleOnInputChange}
      onClose={onClose}
      onSave={handleOnSave}
      disabled={!dataComplete}
      documentStatus={documentStatus}
      selectedDocument={selectedDocument}
      onDocumentChange={handleOnDocumentChange}
    />
  );
};

export default ClientDocumentModalComponent;
