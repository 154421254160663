/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

/** Import helpers section **/
import './UsersStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/FilterBy';
import DataTable from '../../components/DataTable';

const ClientUsersView = ({
  loading,
  users,
  onFilterUsers,
  onAddUser,
  onChangePassword,
  onEditUser,
  onDeleteUser,
  onActiveUser
}) => {
  const ActiveCell = ({ row }) => {
    if (row.active)
      return (
        <CheckCircleOutlineIcon
          className="g-activeicon active users_active"
          onClick={() => onActiveUser(row)}
        />
      );
    return (
      <NotInterestedIcon
        className="g-activeicon users_active"
        onClick={() => onActiveUser(row)}
      />
    );
  };

  const ActionsCell = ({ row }) => {
    return (
      <div className="g-actions-cell">
        <LockIcon
          className="g-row-action-button secondary"
          onClick={() => onChangePassword(row)}
        />
        <EditIcon
          className="g-row-action-button"
          onClick={() => onEditUser(row)}
        />
        <Divider orientation="vertical" flexItem />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => onDeleteUser(row)}
        />
      </div>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Nombre',
        selector: 'first_name',
        sortable: true,
        center: true
      },
      {
        name: 'Apellido',
        selector: 'last_name',
        sortable: true,
        center: true
      },
      {
        name: 'Email',
        selector: 'email',
        sortable: true,
        center: true
      },
      {
        name: 'Celular',
        selector: 'cell_phone',
        sortable: true,
        center: true
      },
      {
        name: 'Telefono',
        selector: 'phone',
        sortable: true,
        center: true
      },
      {
        name: 'Cliente',
        selector: 'title',
        cell: (row) => row?.client?.title,
        sortable: true,
        center: true
      },
      {
        name: 'Rol',
        selector: 'role_title',
        sortable: true,
        center: true
      },
      {
        name: 'Activo',
        selector: 'active',
        cell: (row) => <ActiveCell row={row} />,
        sortable: true,
        center: true,
        width: '120px'
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        width: '120px',
        right: true
      }
    ],
    []
  );

  return (
    <>
      <PageTitle title="Usuarios clientes" />
      <Paper className="g-page-header users_header">
        <div className="users_header_filterby">
          <FilterBy onChange={onFilterUsers} />
        </div>
      </Paper>

      <DataTable data={users} columns={tableColumns} loading={loading} />
    </>
  );
};

export default ClientUsersView;
