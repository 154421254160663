/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import helpers section **/
import { toast } from 'react-toastify';
import {
  getActivitiesService,
  deleteActivityService
} from '../../api/services/activities-services';

/** Import resources section **/

/** Import component section **/
import Modal from '../../components/Modal';
import ActivityModal from './ActivityModal';
import ActivitiesView from './ActivitiesView';

const ActivityModel = {
  id: null,
  name: ''
};

const ActivitiesComponent = () => {
  const [originalData, setOriginalData] = useState([]);
  const [activities, setActivities] = useState([]);
  const [modalMode, setModalMode] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [loading, setLoading] = useState(false);

  const getActivities = useCallback(() => {
    setLoading(true);
    getActivitiesService()
      .then((response) => {
        setActivities(response);
        setOriginalData(response);
      })
      .catch((error) => {
        console.error(
          'activities component - Error requesting activities list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getActivities();
  }, [getActivities]);

  const handleOnUpdateList = () => {
    getActivities();
    handleOnModalClose();
  };

  const handleOnFilterBy = (filterVal) => {
    let filteredItems;

    if (filterVal && filterVal.length > 0) {
      const filteringText = filterVal?.toLowerCase();

      filteredItems = originalData.filter((item) => {
        const field1 = item.name?.toString().toLowerCase();

        return field1.match(filteringText);
      });
    } else {
      filteredItems = [...originalData];
    }

    setActivities(filteredItems);
  };

  const handleOnAddActivity = () => {
    setSelectedActivity(Object.assign({}, ActivityModel));
    setModalMode('activity');
  };

  const handleOnEditActivity = (row) => {
    let item = Object.assign({}, row);
    setSelectedActivity(item);
    setModalMode('activity');
  };

  const handleOnModalClose = () => {
    setSelectedActivity(null);
    setModalMode(null);
  };

  const handleOnDeleteActivity = (row) => {
    setSelectedActivity(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteActivityService({ id: selectedActivity.id })
      .then(() => {
        getActivities();
        toast.success('Actividad borrada');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar al actividad.');
      })
      .finally(() => {
        setSelectedActivity(null);
        setModalMode(null);
      });
  };

  return (
    <>
      <ActivitiesView
        loading={loading}
        activities={activities}
        onFilterBy={handleOnFilterBy}
        onAddActivity={handleOnAddActivity}
        onEditActivity={handleOnEditActivity}
        onDeleteActivity={handleOnDeleteActivity}
      />
      {selectedActivity && modalMode === 'activity' && (
        <ActivityModal
          activityData={selectedActivity}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedActivity && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar actividad"
        >
          {'¿Estas seguro que quieres borrar la actividad ' +
            selectedActivity?.name +
            '?'}
        </Modal>
      )}
    </>
  );
};

export default ActivitiesComponent;
