/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './ClientDocumentModalStyles.scss';

/** Import component section **/
import MUIDateField from '../../../components/inputs/MUIDateField';
// import DropFile   from '../../../components/DropFile';
import MUITextField from '../../../components/inputs/MUITextField';
import ModalComponent from '../../../components/Modal/ModalComponent';
import MUITextArea from '../../../components/inputs/MUITextArea';
import MUIAutocomplete from '../../../components/inputs/MUIAutocomplete';

const ClientDocumentModalView = ({
  isClientAccess,
  document,
  documentTypes,
  onInputChange,
  onClose,
  onSave,
  selectedDocument,
  onDocumentChange,
  disabled
}) => {
  return (
    <ModalComponent
      open={true}
      title={
        document?.id
          ? 'Editar documento del cliente'
          : 'Agregar documento del cliente'
      }
      element={document}
      onClose={onClose}
      onConfirm={onSave}
      disabled={disabled}
    >
      <div className="clientDocumentModal">
        <MUIDateField
          id="validity_date"
          name="validity_date"
          label="Fecha de caducidad"
          value={document?.validity_date}
          onChange={onInputChange}
        />
      </div>

      <div className="space-between">
        {!isClientAccess && (
          <MUITextField
            id="folio"
            name="folio"
            label="Folio"
            value={document?.folio}
            onChange={onInputChange}
          />
        )}

        <MUIAutocomplete
          id="document"
          name="document_type_id"
          options={documentTypes}
          label="Tipo de documento"
          selector="name"
          value={document?.document_type_id || selectedDocument}
          onChange={onInputChange}
        />

        <MUITextField
          id="url"
          name="url"
          label="Url drive"
          value={document?.url}
          onChange={onInputChange}
        />

        <MUITextArea
          label="Notas"
          value={document?.comments}
          type="text"
          name="comments"
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default ClientDocumentModalView;
