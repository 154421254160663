/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';

/** Import syles section **/
import './DocsModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal';
import DataTable from '../../../components/DataTable';

const DocsModalView = ({ modalMode, docs, onDownLoad, onClose }) => {
  const title =
    'Documentos ' + (modalMode === 'docs' ? 'requeridos' : 'entregables');

  const ActionsCell = ({ row }) => {
    if (!row?.document) return null;
    return (
      <div className="g-actions-cell">
        <Tooltip
          title="Descarga del documento"
          aria-label="Descarga del documento"
        >
          <GetAppIcon
            className="g-row-action-button complementary"
            onClick={() => onDownLoad(row)}
          />
        </Tooltip>
      </div>
    );
  };

  const tableColumns = [
    {
      name: 'Documento',
      selector: (row) => row?.document_type?.name,
      sortable: true,
      wrap: true,
      grow: 1.5
    },
    {
      name: '',
      cell: (row) => <ActionsCell row={row} />,
      sortable: true,
      center: true,
      width: '60px'
    }
  ];
  return (
    <ModalComponent
      open={true}
      title={title}
      onClose={onClose}
      cancelText="Cerrar"
    >
      <div className="woServiceDocsModal">
        <DataTable data={docs} columns={tableColumns} pagination={false} />
      </div>
    </ModalComponent>
  );
};

export default DocsModalView;
