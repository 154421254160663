/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/
import {
  addUserService,
  updateUserService,
  changePasswordService
} from '../../../api/services/users-services';

import { isValidEmail } from '../../../utils/validations';

/** Import component section **/
import UserModalView from './UserModalView';

const UserModalComponent = ({
  userData,
  modalMode,
  roles,
  onClose,
  onUpdateList
}) => {
  const [user, setUser] = useState(null);
  const [dataComplete, setDataComplete] = useState(null);

  useEffect(() => {
    const newUSer = Object.assign(
      { password: null, password_conf: null },
      userData
    );
    setUser(newUSer);
  }, [userData]);

  const onInputChange = (data, field) => {
    const { name, value } = data;
    setUser((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const onSwitchActiveChange = (event) => {
    let value = event.target.checked === false ? 0 : 1;
    setUser((prevState) => ({
      ...prevState,
      active: value
    }));
  };

  const onSwitchResponsibleChange = (event) => {
    let value = event.target.checked === false ? 0 : 1;
    setUser((prevState) => ({
      ...prevState,
      responsible: value
    }));
  };

  useEffect(() => {
    if (!user) return;
    let validated = true;
    if (modalMode === 'user') {
      const email = user?.email?.toString().trim();
      const first_name = user?.first_name?.toString().trim();
      const last_name = user?.last_name?.toString().trim();
      const role_id = user?.role_id;
      if (
        first_name === '' ||
        last_name === '' ||
        !role_id ||
        !isValidEmail(email)
      ) {
        validated = false;
      }
    }

    if (modalMode === 'password' || !user?.id) {
      if (user?.password && user?.password_conf) {
        const password = user?.password.trim();
        const password_conf = user?.password_conf.trim();
        if (
          password === '' ||
          password_conf === '' ||
          password !== password_conf
        ) {
          validated = false;
        }
      } else {
        validated = false;
      }
    }
    setDataComplete(validated);
  }, [user, modalMode]);

  const handleOnSave = () => {
    if (modalMode === 'password') {
      changePassword();
    } else if (user.id) {
      updateUser();
    } else {
      createUser();
    }
  };

  const createUser = () => {
    addUserService(user)
      .then((response) => {
        toast.success('Usuario creado');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('No se pudo crear el usuario');
      });
  };

  const updateUser = () => {
    updateUserService(user)
      .then(() => {
        toast.success('Usuario actualizado ');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('No se pudo actualizar el usuario');
      });
  };

  const changePassword = () => {
    changePasswordService(user)
      .then(() => {
        toast.success('Contraseña actalizada');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('No se pudo cambiar la contraseña');
      });
  };

  return (
    <UserModalView
      user={user}
      modalMode={modalMode}
      roles={roles}
      onInputChange={onInputChange}
      onSwitchActiveChange={onSwitchActiveChange}
      onSwitchResponsibleChange={onSwitchResponsibleChange}
      onClose={onClose}
      onSave={handleOnSave}
      disabled={!dataComplete}
    />
  );
};

export default UserModalComponent;
