/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import Tooltip from '@material-ui/core/Tooltip';

/** Import styles section **/
import './MenuItemStyles.scss';

/** Import helpers section **/

/** Import component section **/

export const MenuItemView = ({ Icon, label, menuOpen, onClick }) => {
  return (
    <div className="menuitem" onClick={onClick}>
      {menuOpen ? (
        <>
          <Icon className="menuitem_icon" />
          <span className="menuitem_label">{label}</span>
        </>
      ) : (
        <>
          <Tooltip title={label} aria-label={label} placement="right">
            <Icon className="menuitem_icon" />
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default MenuItemView;
