/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

/** Import helpers section **/
import './CompaniesStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/FilterBy';
import DataTable from '../../components/DataTable';
const CompaniesView = ({
  loading,
  companies,
  onFilterBy,
  onAddCompany,
  onEditCompany,
  onDeleteCompany,
  onActiveCompany
}) => {
  const ActionsCell = ({ row }) => {
    return (
      <div className="g-actions-cell">
        <EditIcon
          className="g-row-action-button"
          onClick={() => onEditCompany(row)}
        />
        <Divider orientation="vertical" flexItem />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => onDeleteCompany(row)}
        />
      </div>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Nombre comercial',
        selector: 'name',
        sortable: true,
        center: true
      },
      {
        name: 'Razón social',
        selector: 'legal_name',
        sortable: true,
        center: true
      },
      {
        name: 'RFC',
        selector: 'federal_register',
        sortable: true,
        center: true
      },
      {
        name: 'Representante legal',
        selector: 'legal_representative',
        sortable: true,
        center: true
      },
      {
        name: 'Contacto',
        selector: 'contact_name',
        sortable: true,
        center: true
      },
      {
        name: 'Email',
        selector: 'email',
        sortable: true,
        center: true
      },
      {
        name: 'telefono',
        selector: 'phone',
        sortable: true,
        center: true
      },
      {
        name: 'Celular',
        selector: 'cell_phone',
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        grow: 0
      }
    ],
    []
  );

  return (
    <>
      <PageTitle title="Compañías" />
      <Paper className="g-page-header companies_header">
        <div className="companies_header_filterby">
          <FilterBy onChange={onFilterBy} />
        </div>
        <Button onClick={onAddCompany} startIcon={<AddCircleIcon />}>
          Agregar nueva compañía
        </Button>
      </Paper>

      <DataTable data={companies} columns={tableColumns} loading={loading} />
    </>
  );
};

export default CompaniesView;
