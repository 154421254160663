/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';

/** Import helpers section **/
import { toast } from 'react-toastify';
import {
  ADD_DELIVERABLE_DOCUMENTS,
  CLIENT_DOCUMENTS_STORAGE_ROUTE
} from '../../config/URL_ROUTES';

/** Import services section **/

import { getDocumentsService } from '../../api/services/documents-catalogue-services';

import {
  getClientDocumentsService,
  deleteClientDocumentService,
  getClientDocumentsStorageService,
  generateReportService
} from '../../api/services/client-documents-services';

import { getClientByIdService } from '../../api/services/clients-services';

/** Import resources section **/

/** Import component section **/
import Modal from '../../components/Modal';
import ClientDocumentModal from './ClientDocumentModal';
import ClientDocumentsView from './ClientDocumentsView';

// const FILES_URL_BASE = process.env.REACT_APP_BACK_URL + 'storage/documents/';

const documentModel = {
  id: null,
  client_id: null,
  file: null,
  url: '',
  document_type_id: null,
  document_date: null,
  validity_date: null,
  folio: '',
  status_id: null,
  comments: ''
};

const ClientDocumentsComponent = () => {
  let history = useHistory();

  const [originalData, setOriginalData] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [clientId, setClientId] = useState(null);
  const [clientTitle, setClientTitle] = useState('');
  const [modalMode, setModalMode] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [loading, setLoading] = useState(false);

  const params = useParams();
  const clientIdParam = params['id'];
  const fromRoute = useLocation().state?.from;
  const workOrderId = useLocation().state?.orderId;
  const pathname = useLocation().pathname;
  const isClientAccess = pathname.includes(CLIENT_DOCUMENTS_STORAGE_ROUTE);

  const getDocumentTypes = useCallback(() => {
    getDocumentsService()
      .then((response) => {
        if (response?.document_types) setDocumentTypes(response.document_types);
      })
      .catch((error) => {
        console.error(
          'Client Documents component - Error requesting document types list: ',
          error
        );
      });
  }, []);

  const readClient = useCallback(() => {
    getClientByIdService({ id: clientIdParam })
      .then((response) => {
        if (response?.client) {
          let clientObj = response.client;
          setClientId(clientObj?.id);
          setClientTitle(
            clientObj?.title + ' ' + clientObj?.client_company?.name
          );
        }
      })
      .catch((error) => {
        toast.error('No se pudo leer el cliente');
      });
  }, [clientIdParam]);

  const getDocumentsByClientId = useCallback(() => {
    setLoading(true);
    getClientDocumentsService({ client_id: clientIdParam })
      .then((response) => {
        const docs = response?.client_documents;
        if (docs) {
          setDocuments(docs);
          setOriginalData(docs);
        }
      })
      .catch((error) => {
        toast.error('No se pudieron leer los documentos');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [clientIdParam]);

  const getDocumentsStorage = useCallback(() => {
    setLoading(true);
    getClientDocumentsStorageService()
      .then((response) => {
        const docs = response?.client_documents;
        const clientName = response?.client_name;
        if (docs) {
          setClientId(clientName);
          setClientTitle(clientName);
          setDocuments(docs);
          setOriginalData(docs);
        }
      })
      .catch((error) => {
        toast.error('No se pudieron leer los documentos');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getDocuments = useCallback(() => {
    if (isClientAccess) {
      getDocumentsStorage();
    } else {
      getDocumentsByClientId();
    }
  }, [isClientAccess, getDocumentsByClientId, getDocumentsStorage]);

  useEffect(() => {
    getDocumentTypes();
    if (isClientAccess) {
      getDocumentsStorage();
    } else {
      getDocuments();
      readClient();
    }
  }, [
    getDocumentTypes,
    getDocuments,
    readClient,
    getDocumentsStorage,
    isClientAccess
  ]);

  const handleOnUpdateList = (selectedDocument) => {
    getDocuments();
    handleOnModalClose();
  };

  const handleOnFilterBy = (filterVal) => {
    let filteredItems;
    if (!filterVal || filterVal.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterVal?.toLowerCase();
      filteredItems = originalData.filter((item) => {
        const field1 = item?.client_document_type?.name
          .toString()
          .toLowerCase();

        return field1.match(filteringText) && true;
      });
    }
    setDocuments(filteredItems);
  };

  const handleOnAddDocument = () => {
    setSelectedDocument(Object.assign({}, documentModel));
    setModalMode('document');
  };

  const handleOnEditDocument = (row) => {
    let item = Object.assign({}, row);
    setSelectedDocument(item);
    setModalMode('document');
  };

  const handleOnModalClose = () => {
    setSelectedDocument(null);
    setModalMode(null);
  };

  const handleOnDeleteDocument = (row) => {
    setSelectedDocument(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteClientDocumentService({ id: selectedDocument.id })
      .then(() => {
        getDocuments();
        toast.success('Documento borrado');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar el documento');
      })
      .finally(() => {
        setSelectedDocument(null);
        setModalMode(null);
      });
  };

  const handleOnDriveRedirect = (row) => {
    const url = row?.url;
    if (url) window.open(url, '_blank');
  };

  const handleOnGenerateReport = () => {
    let params = {
      clientId: clientIdParam
    };
    generateReportService(params)
      .then((response) => {
        const url = response?.url;
        if (url) window.open(url, '_blank');
      })
      .catch((error) => {
        toast.error('No se pudieron leer los documentos');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOnAddRequiredDocs = () => {
    history.push(ADD_DELIVERABLE_DOCUMENTS + '/' + clientIdParam);
  };

  return (
    <>
      <ClientDocumentsView
        isClientAccess={isClientAccess}
        loading={loading}
        title={clientTitle}
        documents={documents}
        fromRoute={fromRoute}
        workOrderId={workOrderId}
        onFilterBy={handleOnFilterBy}
        onAddDocument={handleOnAddDocument}
        // onDownLoad={handleOnDownLoad}
        onEditDocument={handleOnEditDocument}
        onDeleteDocument={handleOnDeleteDocument}
        driveRedirect={handleOnDriveRedirect}
        onGenerateReport={handleOnGenerateReport}
        onAddRequiredDocs={handleOnAddRequiredDocs}
      />
      {selectedDocument && modalMode === 'document' && (
        <ClientDocumentModal
          isClientAccess={isClientAccess}
          clientId={clientId}
          document={selectedDocument}
          documentTypes={documentTypes}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedDocument && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar documento"
        >
          {'¿Estas seguro que quieres borrar el documento?'}
        </Modal>
      )}
    </>
  );
};

export default ClientDocumentsComponent;
