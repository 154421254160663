/** Import react/libraries section **/
import React from 'react';
import { useSelector } from 'react-redux';

/** Import resources section **/

/** Import helpers section **/

/** Import component section **/

export const HasPermission = (permission) => {
  let actionsPermissions = useSelector((store) => store.permissions?.actions);
  return actionsPermissions.includes(permission);
};

export const RouteAccess = (props) => {
  let permissions = useSelector((store) => store.permissions?.routes);
  return AccessControl({ ...props, permissions });
};

export const MenuAccess = (props) => {
  let permissions = useSelector((store) => store.permissions?.menus);
  return AccessControl({ ...props, permissions });
};

export const ActionAccess = (props) => {
  let permissions = useSelector((store) => store.permissions?.actions);
  return AccessControl({ ...props, permissions });
};

const AccessControl = ({
  permissionsRequest,
  permissions = [],
  children,
  renderNoAccess = () => {}
}) => {
  if (!Array.isArray(permissionsRequest))
    permissionsRequest = [permissionsRequest];
  const permitted = permissionsRequest.every((item) =>
    permissions.includes(item)
  );
  // console.log(permitted, permissionsRequest);
  if (permitted && children) {
    return children;
  }
  renderNoAccess();
  return <></>;
};

export default AccessControl;
