/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/
import {
  addDocumentService,
  updateDocumentService
} from '../../../api/services/documents-catalogue-services';

/** Import component section **/
import DocumentModalView from './DocumentModalView';

const DocumentModalComponent = ({
  documentData,
  categories,
  validity,
  onClose,
  onUpdateList
}) => {
  const [document, setDocument] = useState(null);
  const [dataComplete, setDataComplete] = useState(null);

  useEffect(() => {
    const newDocument = Object.assign(documentData);
    setDocument(newDocument);
  }, [documentData]);

  const onInputChange = (data, field) => {
    const { name, value } = data;
    setDocument((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const onSwitchChange = (event) => {
    let value = event.target.checked ? 1 : 0;
    setDocument((prevState) => ({
      ...prevState,
      active: value
    }));
  };

  useEffect(() => {
    if (!document) return;
    let validated = true;

    const name = document?.first_name?.trim();
    const description = document?.description?.trim();
    const categoryId = document?.document_category_id;
    if (name === '' || description === '' || !categoryId) {
      validated = false;
    }

    setDataComplete(validated);
  }, [document]);

  const handleOnSave = () => {
    if (document.id) {
      updateDocument();
    } else {
      createDocument();
    }
  };

  const createDocument = () => {
    addDocumentService(document)
      .then((response) => {
        toast.success('Documento creado');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('Error al crear documento');
      });
  };

  const updateDocument = () => {
    updateDocumentService(document)
      .then(() => {
        toast.success('Documento actualizado');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('Error al actualizar documento');
      });
  };

  return (
    <DocumentModalView
      document={document}
      categories={categories}
      validity={validity}
      onInputChange={onInputChange}
      onSwitchChange={onSwitchChange}
      onClose={onClose}
      onSave={handleOnSave}
      disabled={!dataComplete}
    />
  );
};

export default DocumentModalComponent;
