import request from '../api-connector';

export const getCompaniesService = () => {
  const serviceDef = {
    url: 'company/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const addCompanyService = ({
  name,
  legal_name,
  federal_register,
  legal_representative,
  contact_name,
  phone,
  email,
  cell_phone
}) => {
  const serviceDef = {
    url: 'company/create',
    method: 'post',
    params: {
      name,
      legal_name,
      federal_register,
      legal_representative,
      contact_name,
      phone,
      email,
      cell_phone
    }
  };
  return request(serviceDef);
};

export const updateCompanyService = ({
  id,
  name,
  legal_name,
  federal_register,
  legal_representative,
  contact_name,
  phone,
  email,
  cell_phone
}) => {
  const serviceDef = {
    url: 'company/update',
    method: 'post',
    params: {
      id,
      name,
      legal_name,
      federal_register,
      legal_representative,
      contact_name,
      phone,
      email,
      cell_phone
    }
  };
  return request(serviceDef);
};

export const deleteCompanyService = ({ id }) => {
  const serviceDef = {
    url: 'company/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};
