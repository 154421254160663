import request from '../api-connector';

export const getWorkOrderServicesService = ({ work_order_id }) => {
  const serviceDef = {
    url: 'work-order-service/list',
    method: 'get',
    params: { work_order_id }
  };
  return request(serviceDef);
};

export const updateWorkOrderServicesService = ({
  work_order_id,
  service_id
}) => {
  const serviceDef = {
    url: 'work-order-service/update',
    method: 'post',
    params: { work_order_id, service_id }
  };
  return request(serviceDef);
};

export const assignWorkOrderService = ({
  service_id,
  work_order_id,
  work_order_service_id,
  user_id,
  comments
}) => {
  const serviceDef = {
    url: 'assignation/assign',
    method: 'post',
    params: {
      service_id,
      work_order_id,
      work_order_service_id,
      user_id,
      comments
    }
  };
  return request(serviceDef);
};

export const autorizedDocumentService = ({ work_order_service_id }) => {
  const serviceDef = {
    url: 'work-order-service/authorized',
    method: 'post',
    params: { id: work_order_service_id }
  };
  return request(serviceDef);
};

export const updateUrlDriveService = ({ id, url, folio }) => {
  const serviceDef = {
    url: 'work-order-service/update-url-document',
    method: 'post',
    params: { id, url, folio }
  };
  return request(serviceDef);
};
