/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/
import {
  addCompanyService,
  updateCompanyService
} from '../../../api/services/companies-services';

/** Import component section **/
import CompanyModalView from './CompanyModalView';

const CompanyModalComponent = ({
  companyData,
  risks,
  states,
  onClose,
  onUpdateList
}) => {
  const [company, setCompany] = useState(companyData);
  const [dataComplete, setDataComplete] = useState(null);

  useEffect(() => {
    const newCompany = Object.assign({}, companyData);
    setCompany(newCompany);
  }, [companyData]);

  const onInputChange = (data, field) => {
    const { name, value } = data;
    setCompany((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    if (!company) return;
    let validated = false;

    const name = company?.name?.trim();
    const legal_name = company?.legal_name;
    const federal_register = company?.federal_register?.trim();
    const legal_representative = company?.legal_representative?.trim();

    if (
      name &&
      name?.length > 0 &&
      legal_name &&
      legal_name?.length > 0 &&
      federal_register &&
      federal_register?.length > 0 &&
      legal_representative &&
      legal_representative?.length > 0
    ) {
      validated = true;
    }

    setDataComplete(validated);
  }, [company]);

  const handleOnSave = () => {
    if (company.id) {
      updateCompany();
    } else {
      createCompany();
    }
  };

  const createCompany = () => {
    addCompanyService(company)
      .then((response) => {
        toast.success('Compañía creada');
        onUpdateList(response);
      })
      .catch((error) => {
        toast.error('No se pudo crear la compañía');
      });
  };

  const updateCompany = () => {
    updateCompanyService(company)
      .then((response) => {
        toast.success('Compañía actualizada');
        onUpdateList(response);
      })
      .catch((error) => {
        toast.error('No se pudo actualizar la compañía');
      });
  };

  return (
    <CompanyModalView
      company={company}
      onInputChange={onInputChange}
      onClose={onClose}
      onSave={handleOnSave}
      disabled={!dataComplete}
    />
  );
};

export default CompanyModalComponent;
