/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/
import {
  addActivityService,
  updateActivityService
} from '../../../api/services/activities-services';

/** Import component section **/
import ActivityModalView from './ActivityModalView';

const ActivityModalComponent = ({ activityData, onClose, onUpdateList }) => {
  const [activity, setActivity] = useState(activityData);
  const [dataComplete, setDataComplete] = useState(null);

  useEffect(() => {
    const newCompany = Object.assign({}, activityData);
    setActivity(newCompany);
  }, [activityData]);

  const onInputChange = (data) => {
    const { name, value } = data;
    setActivity((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    if (!activity) return;
    let validated = false;

    const name = activity?.name?.trim();

    if (name && name?.length > 0) {
      validated = true;
    }

    setDataComplete(validated);
  }, [activity]);

  const handleOnSave = () => {
    if (activity?.id) {
      updateActivity();
    } else {
      createActivity();
    }
  };

  const createActivity = () => {
    addActivityService(activity)
      .then((response) => {
        toast.success('Actividad creada');
        onUpdateList(response);
      })
      .catch((error) => {
        toast.error('No se pudo crear la actividad');
      });
  };

  const updateActivity = () => {
    updateActivityService(activity)
      .then((response) => {
        toast.success('Actividad actualizada');
        onUpdateList(response);
      })
      .catch((error) => {
        toast.error('No se pudo actualizar la actividad');
      });
  };

  return (
    <ActivityModalView
      activity={activity}
      onInputChange={onInputChange}
      onClose={onClose}
      onSave={handleOnSave}
      disabled={!dataComplete}
    />
  );
};

export default ActivityModalComponent;
