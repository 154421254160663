/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { ReactComponent as DriveIcon } from '../../assets/drive_icon.svg';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

/** Import helpers section **/
import './ClientDocumentsStyles.scss';
import { CLIENTS_ROUTE, ASSIGNATIONS_ROUTE } from '../../config/URL_ROUTES';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/FilterBy';
import DataTable from '../../components/DataTable';

const ClientDocumentsView = ({
  isClientAccess,
  loading,
  title,
  documents,
  fromRoute,
  onFilterBy,
  onAddDocument,
  workOrderId,
  //onDownLoad,
  onEditDocument,
  onDeleteDocument,
  driveRedirect,
  onGenerateReport,
  onAddRequiredDocs
}) => {
  const ActionsCell = ({ row }) => {
    return (
      <>
        {!!row?.url && (
          <Tooltip
            title="Ir al documento en Google Drive"
            aria-label="Ir al documento en Google Drive"
          >
            <DriveIcon
              className="g-row-action-button complementary"
              onClick={() => driveRedirect(row)}
            />
          </Tooltip>
        )}

        <EditIcon
          className="g-row-action-button"
          onClick={() => onEditDocument(row)}
        />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => onDeleteDocument(row)}
        />
      </>
    );
  };

  const tableColumns = [
    {
      name: 'Id',
      selector: 'id',
      sortable: true,
      center: true,
      omit: true
    },
    {
      name: 'Documento',
      selector: (row) => row?.client_document_type?.name,
      sortable: true,
      center: true,
      grow: 2
    },
    {
      name: 'Folio',
      selector: 'folio',
      sortable: true,
      center: true
    },
    {
      name: 'Vigencia hasta',
      selector: 'validity_date',
      sortable: true,
      center: true
    },
    // {
    //   name: 'Fecha del documento',
    //   selector: (row) => (row?.document_date ? row?.document_date : ''),
    //   sortable: true,
    //   center: true
    // },
    {
      name: 'Estatus',
      selector: 'status_name',
      sortable: true,
      center: true,
      width: '100px'
    },
    {
      name: 'Notas',
      selector: 'comments',
      sortable: true,
      center: true,
      width: '400px'
    },
    {
      name: '',
      cell: (row) => <ActionsCell row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      center: true
    }
  ];
  return (
    <>
      <PageTitle
        title={'Expediente Cliente ' + (title ? title : '')}
        backUrl={
          isClientAccess
            ? null
            : fromRoute === 'assignations'
            ? ASSIGNATIONS_ROUTE + '/' + workOrderId
            : CLIENTS_ROUTE
        }
      />
      {!isClientAccess && (
        <Paper className="g-page-header clientDocuments_header">
          <div className="clientDocuments_header_filterby">
            <FilterBy onChange={onFilterBy} />
          </div>
          <div className="clientDocuments_header_buttons">
            <Button onClick={onGenerateReport} startIcon={<AddCircleIcon />}>
              Generar informe
            </Button>
            <Button onClick={onAddDocument} startIcon={<AddCircleIcon />}>
              Agregar nuevo documento
            </Button>
            <Button
              className="g-row-action-button_check"
              onClick={onAddRequiredDocs}
              startIcon={<AssignmentTurnedInIcon />}
            >
              Seleccionar documentos requeridos
            </Button>
          </div>
        </Paper>
      )}

      <DataTable data={documents} columns={tableColumns} loading={loading} />
    </>
  );
};

export default ClientDocumentsView;
