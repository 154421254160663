import request from '../api-connector';

export const getActivitiesService = () => {
  const serviceDef = {
    url: 'activity',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const deleteActivityService = ({ id }) => {
  const serviceDef = {
    url: 'activity/delete',
    method: 'get',
    params: { id }
  };
  return request(serviceDef);
};
export const addActivityService = ({ name }) => {
  const serviceDef = {
    url: 'activity/create',
    method: 'get',
    params: { name }
  };
  return request(serviceDef);
};

export const updateActivityService = ({ id, name }) => {
  const serviceDef = {
    url: 'activity/update',
    method: 'get',
    params: { id, name }
  };
  return request(serviceDef);
};
