import request from '../api-connector';

export const getStatesService = () => {
  const serviceDef = {
    url: 'state',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const getRisksService = () => {
  const serviceDef = {
    url: 'risks',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const getActivitiesService = () => {
  const serviceDef = {
    url: 'activity',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const getClientsService = () => {
  const serviceDef = {
    url: 'client/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const getSelectClientsService = (clientsOperator = null) => {
  const serviceDef = {
    url: 'client/list/select',
    method: 'post',
    params: { clients: clientsOperator }
  };
  return request(serviceDef);
};

export const getClientByIdService = ({ id }) => {
  const serviceDef = {
    url: 'client/read',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const addClientService = ({
  company_id,
  risk_id,
  activity_id,
  title,
  contact_name,
  phone,
  email,
  cell_phone,
  state_id,
  municipality,
  city,
  ext_number,
  reference,
  int_number,
  neighborhood,
  postal_code,
  area_square_meters,
  structure_square_meters,
  employees_number,
  street
}) => {
  const serviceDef = {
    url: 'client/create',
    method: 'post',
    params: {
      company_id,
      risk_id,
      activity_id,
      title,
      contact_name,
      phone,
      email,
      cell_phone,
      state_id,
      municipality,
      city,
      ext_number,
      reference,
      int_number,
      neighborhood,
      postal_code,
      area_square_meters,
      structure_square_meters,
      employees_number,
      street
    }
  };
  return request(serviceDef);
};

export const updateClientService = ({
  id,
  company_id,
  risk_id,
  activity_id,
  title,
  contact_name,
  phone,
  email,
  cell_phone,
  state_id,
  municipality,
  city,
  ext_number,
  reference,
  int_number,
  neighborhood,
  postal_code,
  area_square_meters,
  structure_square_meters,
  employees_number,
  street,
  drive_client_folder_url
}) => {
  const serviceDef = {
    url: 'client/update',
    method: 'post',
    params: {
      id,
      company_id,
      risk_id,
      activity_id,
      title,
      contact_name,
      phone,
      email,
      cell_phone,
      state_id,
      municipality,
      city,
      ext_number,
      reference,
      int_number,
      neighborhood,
      postal_code,
      area_square_meters,
      structure_square_meters,
      employees_number,
      street,
      drive_client_folder_url
    }
  };
  return request(serviceDef);
};

export const updateClientAddressService = ({
  id,
  state_id,
  municipality,
  city,
  postal_code,
  street,
  ext_number,
  int_number,
  reference,
  neighborhood,
  area_square_meters,
  structure_square_meters,
  employees_number
}) => {
  const serviceDef = {
    url: 'client/update',
    method: 'post',
    params: {
      id,
      state_id,
      municipality,
      city,
      postal_code,
      street,
      ext_number,
      int_number,
      reference,
      neighborhood,
      area_square_meters,
      structure_square_meters,
      employees_number
    }
  };
  return request(serviceDef);
};

export const updateClientPaymentService = ({
  id,
  billing_contact_name,
  billing_contact_phone,
  billing_contact_email,
  biling_contact_cell_phone,
  observations
}) => {
  const serviceDef = {
    url: 'client/update',
    method: 'post',
    params: {
      id,
      billing_contact_name,
      billing_contact_phone,
      billing_contact_email,
      biling_contact_cell_phone,
      observations
    }
  };
  return request(serviceDef);
};

export const changeActiveService = ({ id }) => {
  const serviceDef = {
    url: 'client/update/active',
    method: 'post',
    params: {
      id
    }
  };
  return request(serviceDef);
};

export const deleteClientService = ({ id }) => {
  const serviceDef = {
    url: 'client/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};
