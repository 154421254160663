import * as routes from '../../config/URL_ROUTES';

/** Import resources section **/
import PersonIcon from '@material-ui/icons/Person';

import ApartmentIcon from '@material-ui/icons/Apartment';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import BallotIcon from '@material-ui/icons/Ballot';
import AssignmentIcon from '@material-ui/icons/Assignment';
// import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PollIcon from '@material-ui/icons/Poll';

import SettingsIcon from '@material-ui/icons/Settings';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import LockIcon from '@material-ui/icons/Lock';
import WorkIcon from '@material-ui/icons/Work';
/*********************************************************************
 ************************  MENU ITEMS IDS  ***************************
 *********************************************************************/

const PERMISSIONS_MENU = 'permissions';
const PERMISSIONS_CATALOGUE_MENU = 'permissions-catalogue';
const CONFIGURATION_MENU = 'configuration';

const COMPANIES_MENU = 'companies';
const CLIENTS_MENU = 'clients';
const CATALOGUES_MENU = 'catalogues';
const DOCUMENTS_CATALOGUE_MENU = 'document-catalogue';
const ACTIVITIES_MENU = 'activities';
const SERVICES_MENU = 'services';
const USERS_MENU = 'users';

const WORK_ORDERS_MENU = 'workorders';
const DASHBOARD_MENU = 'dashboard';

const CLIENT_WORKORDERS_STATUS_MENU = 'client-workorders-status';
const CLIENT_DOCUMENTS_STORAGE_MENU = 'client-documents-storage';

/*********************************************************************
 **************************  MENU ITEMS  *****************************
 *********************************************************************/

const MENU_ITEMS = [
  {
    id: DASHBOARD_MENU,
    label: 'Tablero',
    url: routes.DASHBOARD_ROUTE,
    icon: PollIcon
  },
  {
    id: CLIENT_WORKORDERS_STATUS_MENU,
    label: 'Estatus de órdenes',
    url: routes.CLIENT_WORKORDERS_STATUS_ROUTE,
    icon: BusinessCenterIcon
  },
  // {
  //   id: ASSIGNATIONS_MENU,
  //   label: 'Assignaciones',
  //   url: routes.ASSIGNATIONS_ROUTE,
  //   icon: AssignmentIndIcon
  // },
  // {
  //   id: CLIENT_ASSIGNATIONS_MENU,
  //   label: 'Assignaciones',
  //   url: routes.CLIENT_ASSIGNATIONS_ROUTE,
  //   icon: AssignmentIndIcon
  // },
  {
    id: WORK_ORDERS_MENU,
    label: 'Órdenes de trabajo',
    url: routes.WORK_ORDERS_ROUTE,
    icon: BusinessCenterIcon
  },
  {
    id: CLIENTS_MENU,
    label: 'Clientes',
    url: routes.CLIENTS_ROUTE,
    icon: PersonPinCircleIcon
  },
  {
    id: COMPANIES_MENU,
    label: 'Compañías',
    url: routes.COMPANIES_ROUTE,
    icon: ApartmentIcon
  },
  {
    id: CLIENT_DOCUMENTS_STORAGE_MENU,
    label: 'Documentos requeridos',
    url: routes.CLIENT_DOCUMENTS_STORAGE_ROUTE,
    icon: FileCopyIcon
  },
  {
    id: CATALOGUES_MENU,
    label: 'Catálogos',
    icon: BallotIcon,
    items: [
      {
        id: USERS_MENU,
        label: 'Usuarios',
        url: routes.USERS_ROUTE,
        icon: PersonIcon
      },
      {
        id: USERS_MENU,
        label: 'Usuarios cliente',
        url: routes.CLIENT_USERS_ROUTE,
        icon: PersonIcon
      },
      {
        id: SERVICES_MENU,
        label: 'Servicios',
        url: routes.SERVICES_ROUTE,
        icon: AssignmentIcon
      },
      {
        id: DOCUMENTS_CATALOGUE_MENU,
        label: 'Tipo de documentos',
        url: routes.DOCUMENTS_CATALOGUE_ROUTE,
        icon: FileCopyIcon
      },
      {
        id: ACTIVITIES_MENU,
        label: 'Actividades',
        url: routes.ACTIVITIES_ROUTE,
        icon: WorkIcon
      }
    ]
  },
  {
    id: CONFIGURATION_MENU,
    label: 'Configuración',
    icon: SettingsIcon,
    items: [
      {
        id: PERMISSIONS_MENU,
        label: 'Permisos por rol',
        url: routes.ROLE_PERMISSIONS_ROUTE,
        icon: AccountTreeIcon
      },
      {
        id: PERMISSIONS_CATALOGUE_MENU,
        label: 'Permisos',
        url: routes.PERMISSIONS_ROUTE,
        icon: LockIcon
      }
    ]
  }
];
export default MENU_ITEMS;
