/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

/** Import syles section **/
import './UserModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal';
import MUITextField from '../../../components/inputs/MUITextField';
import MUISelect from '../../../components/inputs/MUISelect';

const ClientUserModalView = ({
  user,
  modalMode,
  roles,
  onInputChange,
  onSwitchActiveChange,
  onClose,
  onSave,
  disabled
}) => {
  const title =
    modalMode === 'password' ? 'Cambiar contraseña' : 'Editar usuario';
  return (
    <ModalComponent
      open={true}
      title={title}
      element={user}
      onClose={onClose}
      onConfirm={onSave}
      disabled={disabled}
    >
      <div className="usermodal">
        {modalMode === 'user' && (
          <>
            <div className="usermodal_active">
              <FormControlLabel
                control={
                  <Switch
                    label="Activo"
                    checked={user?.active === 1}
                    onChange={(event) => onSwitchActiveChange(event)}
                    name="active"
                    color="primary"
                    inputProps={{
                      'aria-label': 'secondary checkbox'
                    }}
                  />
                }
                label="Activo"
              />
            </div>

            <div className="usermodal_row">
              <MUITextField
                label="Nombre"
                defaultValue={user?.first_name}
                type="text"
                name="first_name"
                onChange={onInputChange}
              />
              <MUITextField
                label="Apellido"
                defaultValue={user?.last_name}
                type="text"
                name="last_name"
                onChange={onInputChange}
              />
            </div>
            <div className="usermodal_row">
              <MUITextField
                label="Email"
                defaultValue={user?.email}
                type="text"
                name="email"
                onChange={onInputChange}
              />

              <MUISelect
                label="Rol"
                defaultValue={user?.role_id}
                items={roles}
                idSelector="id"
                selector="title"
                noSelectedLabel="Select"
                name="role_id"
                onChange={onInputChange}
                className="usermodal_rolTitle"
              />
            </div>
            <div className="usermodal_row">
              <MUITextField
                label="Celular"
                defaultValue={user?.cell_phone}
                type="text"
                name="cell_phone"
                onChange={onInputChange}
              />

              <MUITextField
                label="Teléfono"
                defaultValue={user?.phone}
                type="text"
                name="phone"
                onChange={onInputChange}
              />
            </div>
          </>
        )}

        {(modalMode === 'password' || (modalMode === 'user' && !user?.id)) && (
          <div className="usermodal_row">
            <MUITextField
              label="Contraseña"
              defaultValue={user?.password}
              type="password"
              name="password"
              error={user?.password !== user?.password_conf}
              onChange={onInputChange}
            />

            <MUITextField
              label="Confirmar contraseña"
              defaultValue={user?.password_conf}
              type="password"
              name="password_conf"
              error={user?.password !== user?.password_conf}
              onChange={onInputChange}
            />
          </div>
        )}
      </div>
    </ModalComponent>
  );
};

export default ClientUserModalView;
