/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/
import {
  addClientService,
  updateClientService,
  getRisksService,
  getActivitiesService
} from '../../../api/services/clients-services';

import { getStatesService } from '../../../api/services/clients-services';

import { getCompaniesService } from '../../../api/services/companies-services';

import CompanyModel from '../../Companies/CompanyModel';

import { isValidEmail } from '../../../utils/validations';

/** Import component section **/
import ClientModalView from './ClientModalView';
import CompanyModal from '../../Companies/CompanyModal';

const ClientModalComponent = ({
  clientData,
  onClose,
  onUpdateList,
  view = 'client'
}) => {
  const [risks, setRisks] = useState([]);
  const [activities, setActivities] = useState([]);
  const [client, setClient] = useState(clientData);
  const [companies, setCompanies] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [dataComplete, setDataComplete] = useState(null);
  const [loading, setLoading] = useState(false);

  const getRisks = useCallback(() => {
    getRisksService()
      .then((response) => {
        setRisks(response);
      })
      .catch((error) => {
        console.error('Get risks - Error getting: ', error);
      });
  }, []);

  const getActivities = useCallback(() => {
    getActivitiesService()
      .then((response) => {
        setActivities(response);
      })
      .catch((error) => {
        console.error('Get risks - Error getting: ', error);
      });
  }, []);

  const getCompanies = useCallback(() => {
    getCompaniesService()
      .then((response) => {
        setCompanies(response);
        if (client?.company_id) {
          let companyFound = response.find((c) => c.id === client.company_id);
          if (companyFound) setSelectedCompany(companyFound);
        }
      })
      .catch((error) => {
        console.error('Get risks - Error getting: ', error);
      });
  }, [client.company_id]);

  const getStates = useCallback(() => {
    getStatesService()
      .then((response) => {
        if (response) {
          setStates(response);
        }
      })
      .catch((error) => {
        toast('Error getting states');
      });
  }, []);

  useEffect(() => {
    getCompanies();
    getStates();
    getRisks();
    getActivities();
  }, [getCompanies, getStates, getRisks, getActivities]);

  useEffect(() => {
    const newClient = Object.assign({}, clientData);
    setClient(newClient);
  }, [clientData]);

  const handleOnInputChange = (data) => {
    const { name, value } = data;
    setClient((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnCompanyChange = (data) => {
    setSelectedCompany(data?.id);
    let companyId = data?.id || null;
    let contactName = data?.contact_name || '';
    let contactEmail = data?.email || '';
    let cellPhone = data?.cell_phone || '';
    let phoneContact = data?.phone || '';
    setClient((prevState) => ({
      ...prevState,
      company_id: companyId,
      contact_name: contactName,
      email: contactEmail,
      cell_phone: cellPhone,
      phone: phoneContact
    }));
  };

  const handleOnAddCompany = () => {
    let blankComapny = Object.assign({}, CompanyModel);
    setSelectedCompany(blankComapny);
  };

  useEffect(() => {
    if (!client) return;
    let validated = false;

    const companyId = client?.company_id?.toString().trim();
    const contactName = client?.contact_name?.toString().trim();
    const activity = client?.activity_id;
    const email = client?.email?.toString().trim();
    const phone = client?.phone?.toString().trim();
    const cellPhone = client?.cell_phone?.toString().trim();
    const risk = client?.risk_id;

    if (
      companyId &&
      contactName &&
      contactName?.length > 0 &&
      activity &&
      isValidEmail(email) &&
      phone &&
      phone?.length > 0 &&
      cellPhone &&
      cellPhone?.length > 0 &&
      risk !== null
    ) {
      validated = true;
    }
    setDataComplete(validated);
  }, [client]);

  const handleOnSave = () => {
    if (client.id) {
      updateClient();
    } else {
      setLoading(true);
      createClient();
    }
  };

  const createClient = () => {
    addClientService(client)
      .then((response) => {
        toast.success('Cliente creado');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('El cliente no se pudo guardar');
      });
  };

  const updateClient = () => {
    updateClientService(client)
      .then(() => {
        toast.success('Cliente actualizado');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('El cliente no se pudo actualizar');
      });
  };

  const handleOnModalClose = () => {
    setSelectedCompany(null);
  };

  const handleOnUpdateList = (companyCreated) => {
    setSelectedCompany(companyCreated);
    handleOnInputChange({ name: 'company_id', value: companyCreated?.id });
    getCompanies();
  };

  const disabledButton = !dataComplete || loading ? true : false;

  return (
    <>
      <ClientModalView
        client={client}
        states={states}
        companies={companies}
        selectedCompany={selectedCompany}
        onAddCompany={handleOnAddCompany}
        risks={risks}
        activities={activities}
        onInputChange={handleOnInputChange}
        onCompanyChange={handleOnCompanyChange}
        onClose={onClose}
        onSave={handleOnSave}
        disabled={disabledButton}
        view={view}
      />
      {selectedCompany?.id === null && (
        <CompanyModal
          companyData={selectedCompany}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
    </>
  );
};

export default ClientModalComponent;
