/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

/** Import helpers section **/
import { toast } from 'react-toastify';
import {
  getCategoriesService,
  getServicesService,
  deleteServiceService,
  changeActiveService
} from '../../api/services/services-services';

import { getDeliverableDocumentsService } from '../../api/services/service-documents-services';
import { createDeliverableDocumentsService } from '../../api/services/service-documents-services';

import { SERVICE_REQUIREMENTS_ROUTE } from '../../config/URL_ROUTES';

/** Import resources section **/

/** Import component section **/
import Modal from '../../components/Modal';
import ServicesView from './ServicesView';
import ServiceModal from './ServiceModal';
import MUISelect from '../../components/inputs/MUISelect';

const serviceModel = {
  id: null,
  title: '',
  service_category_id: null,
  max_time: 1,
  active: 1,
  document_type_id: null
};

const ServicesComponent = () => {
  const [categories, setCategories] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [services, setServices] = useState([]);
  const [deliverableDocs, seDeliverableDocs] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [modalMode, setModalMode] = useState(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const getCategories = useCallback(() => {
    getCategoriesService()
      .then((response) => {
        setCategories(response);
      })
      .catch((error) => {
        console.error('getCategoriesService - Error getting: ', error);
      });
  }, []);

  const getServices = useCallback(() => {
    setLoading(true);
    getServicesService()
      .then((response) => {
        setServices(response);
        setOriginalData(response);
      })
      .catch((error) => {
        console.error(
          'Services Component - Error requesting services list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getCategories();
    getServices();
  }, [getCategories, getServices]);

  const getCategoryTitle = (item) => {
    const catFound = categories.find(
      (category) => category.id === item?.service_category_id
    );
    return catFound ? catFound.title : '';
  };

  const handleOnUpdateList = (selectedService) => {
    getServices();
  };

  const handleOnFilterServices = (filterVal) => {
    let filteredItems;

    if (!filterVal || filterVal.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterVal
        ?.toLowerCase()
        ?.normalize('NFD')
        ?.replace(/[\u0300-\u036f]/g, '');

      filteredItems = originalData.filter((item) => {
        const field1 = item.title
          ?.toString()
          .toLowerCase()
          ?.normalize('NFD')
          ?.replace(/[\u0300-\u036f]/g, '');
        const field2 = item?.description
          ? item.description
              ?.toLowerCase()
              ?.normalize('NFD')
              ?.replace(/[\u0300-\u036f]/g, '')
          : '';
        const field3 = item?.service_category_id
          ? getCategoryTitle(item)
              ?.toLowerCase()
              ?.normalize('NFD')
              ?.replace(/[\u0300-\u036f]/g, '')
          : '';

        return (
          field1.match(filteringText) ||
          field2.match(filteringText) ||
          (field3.match(filteringText) && true)
        );
      });
    }

    setServices(filteredItems);
  };

  const handleOnAddService = () => {
    const serviceItem = Object.assign({}, serviceModel);
    setSelectedService(serviceItem);
    setModalMode('edit');
  };

  const handleOnEditService = (row) => {
    const serviceItem = Object.assign({}, row);
    setSelectedService(serviceItem);
    setModalMode('edit');
  };

  const handleOnModalClose = () => {
    setSelectedService(null);
    setModalMode(null);
  };

  const handleOnDeleteService = (row) => {
    setSelectedService(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteServiceService({ id: selectedService.id })
      .then(() => {
        getServices();
        toast.success('Serviceo borrado');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar el servicio.');
      })
      .finally(() => {
        setSelectedService(null);
        setModalMode(null);
      });
  };

  const handleOnActiveChange = (row) => {
    setSelectedService(row);
    setModalMode('active');
  };

  const handleOnActiveConfirm = () => {
    changeActiveService({
      id: selectedService.id,
      active: selectedService.active ? 0 : 1
    })
      .then(() => {
        getServices();
        toast.success('Estatus cambiado');
      })
      .catch((error) => {
        toast.error('Error, no se pudo cambiar el estatus');
      })
      .finally(() => {
        setSelectedService(null);
        setModalMode(null);
      });
  };

  const handleOnInputDocs = (row) => {
    history.push(SERVICE_REQUIREMENTS_ROUTE + '/' + row?.id);
  };

  const getDeliverableDocuments = useCallback((serviceId) => {
    setLoading(true);
    getDeliverableDocumentsService({ service_id: serviceId })
      .then((response) => {
        seDeliverableDocs(response);
      })
      .catch((error) => {
        toast.error('No se pudieron leer los documentos');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleOnOutputDocs = (row) => {
    let serviceId = row?.id;
    if (!serviceId) return;
    setModalMode('deliverables');
    setSelectedService(row);
    getDeliverableDocuments(serviceId);
  };

  const handleOnInputChange = (data) => {
    console.log(data);
    const { name, value } = data;
    setSelectedService((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnAddServiceDeliverable = () => {
    let serviceId = selectedService.id;
    let documentTypeId = selectedService.document_type_id;
    createDeliverableDocumentsService({
      service_id: serviceId,
      document_type_id: documentTypeId
    })
      .then(() => {
        getServices();
        toast.success('Entregable agregado');
      })
      .catch((error) => {
        toast.error('Error, no se pudo agreggar el entregable .');
      })
      .finally(() => {
        handleOnModalClose();
      });
  };

  return (
    <>
      <ServicesView
        loading={loading}
        categories={categories}
        services={services}
        onFilterServices={handleOnFilterServices}
        onAddService={handleOnAddService}
        onEditService={handleOnEditService}
        onInputDocs={handleOnInputDocs}
        onOutputDocs={handleOnOutputDocs}
        onDeleteService={handleOnDeleteService}
        onActiveService={handleOnActiveChange}
      />
      {selectedService && modalMode === 'edit' && (
        <ServiceModal
          serviceData={selectedService}
          categories={categories}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}

      {selectedService && modalMode === 'deliverables' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnAddServiceDeliverable}
          title={selectedService.title}
        >
          <div className="space-between">Seleccione el entregable:</div>

          <MUISelect
            label="Tipo de documento"
            items={deliverableDocs}
            idSelector="id"
            selector="name"
            defaultValue={selectedService?.document_type_id}
            noSelectedLabel="Select"
            name="document_type_id"
            onChange={handleOnInputChange}
          />
        </Modal>
      )}

      {selectedService && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar servicio"
        >
          {'¿Estas seguro que quieres borrar el servicio ' +
            selectedService.title +
            '?'}
        </Modal>
      )}

      {selectedService && modalMode === 'active' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnActiveConfirm}
          title={
            selectedService?.active === 0
              ? 'Activar servicio?'
              : 'Desactivar servicio'
          }
        >
          {'¿Estas seguro que quieres ' +
            (selectedService?.active === 0 ? 'activar' : 'desactivar') +
            ' el servicio ' +
            selectedService.title +
            '?'}
        </Modal>
      )}
    </>
  );
};

export default ServicesComponent;
