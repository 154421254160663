/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './AssignationDeliverableModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal';
import MUIDateField from '../../../components/inputs/MUIDateField';
import MUITextField from '../../../components/inputs/MUITextField';

const AssignationDeliverableModalView = ({
  document,
  disabled,
  onInputChange,
  onClose,
  onSave
}) => {
  const fieldsComp = (
    <div className="assignationDeliverableModal">
      <MUITextField
        label="Tipo de documento"
        id="document_type_name"
        name="document_type_name"
        type="text"
        value={document?.document_type_name}
        disabled
      />
      <MUITextField
        id="url"
        label="Url drive"
        defaultValue={document?.url}
        type="text"
        name="url"
        onChange={onInputChange}
      />
      {/* <MUIDateField
        id="document_date"
        name="document_date"
        label="Fecha del documento"
        value={document?.document_date}
        onChange={onInputChange}
      /> */}
      <MUIDateField
        id="deadline_date"
        name="deadline_date"
        label="Fecha de caducidad"
        value={document?.deadline_date}
        onChange={onInputChange}
      />

      <MUITextField
        id="folio"
        label="Folio"
        defaultValue={document?.folio}
        type="text"
        name="folio"
        onChange={onInputChange}
      />
    </div>
  );

  return (
    <ModalComponent
      open={true}
      title={document?.id ? 'Editar documento' : 'Crear documento'}
      element={document}
      onClose={onClose}
      onConfirm={onSave}
      disabled={disabled}
      width={700}
    >
      {fieldsComp}
    </ModalComponent>
  );
};

export default AssignationDeliverableModalView;
