import request from '../api-connector';

export const getOperatorsAssignService = () => {
  const serviceDef = {
    url: 'assignation/select',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const readAssignationService = ({ work_order_service_id }) => {
  const serviceDef = {
    url: 'assignation/read',
    method: 'post',
    params: { work_order_service_id }
  };
  return request(serviceDef);
};

export const getAssignationsService = ({
  id,
  work_order_status_id,
  completed
}) => {
  const serviceDef = {
    url: 'assignation/list',
    method: 'get',
    params: { id, work_order_status_id, completed }
  };
  return request(serviceDef);
};

export const getAssignationDeliverablesService = ({
  work_order_service_id
}) => {
  const serviceDef = {
    url: 'work-order-service-deliverable/list',
    method: 'get',
    params: { work_order_service_id }
  };
  return request(serviceDef);
};

export const addAssignationDeliverableService = ({
  url,
  work_order_service_id,
  service_deliverable_id,
  document_date,
  validity_date,
  folio,
  document_type_id
}) => {
  const serviceDef = {
    url: 'work-order-service-deliverable/create',
    method: 'post',
    params: {
      url,
      work_order_service_id,
      service_deliverable_id,
      document_date,
      validity_date,
      folio,
      document_type_id
    }
  };
  return request(serviceDef);
};
export const editAssignationDeliverableService = ({
  id,
  document_date,
  validity_date,
  folio,
  url
}) => {
  const serviceDef = {
    url: 'work-order-service-deliverable/update',
    method: 'post',
    params: { id, document_date, validity_date, folio, url }
  };
  return request(serviceDef);
};

export const deleteAssignationDeliverableService = ({ id }) => {
  const serviceDef = {
    url: 'work-order-service-deliverable/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const sendAssignationClientNotificationService = ({
  work_order_id,
  need_training,
  comments
}) => {
  const serviceDef = {
    url: 'assignation/notification-client',
    method: 'post',
    params: {
      work_order_id,
      need_training,
      comments
    }
  };
  return request(serviceDef);
};

export const sendAssignationAdminsNotificationService = ({
  work_order_id,
  need_training,
  comments
}) => {
  const serviceDef = {
    url: 'assignation/notification-admins',
    method: 'post',
    params: {
      work_order_id,
      need_training,
      comments
    }
  };
  return request(serviceDef);
};
