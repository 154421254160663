/** Import react/libraries section **/
import React, { useState, useLayoutEffect } from 'react';

/** Import resources section **/
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

/** Styles **/
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  '&:hover': {
    backgroundColor: theme.palette.normal.main
  },
  focused: { backgroundColor: '#fff' }
}));

const styles = () => ({
  inputLabel: {
    fontSize: '11px',
    height: '12px',
    paddingBottom: '8px'
  },
  input: {
    margin: 0,
    verticalAlign: 'center',
    backgroundColor: 'white',
    placeholderTextColor: '#ced4da',
    fontSize: '12px'
  }
});

export const MUIAutocomplete = ({
  name,
  id,
  labelId,
  label,
  type = 'text',
  selector,
  value,
  onChange,
  style,
  disabled,
  placeholder,
  color = 'secondary',
  styleText,
  disabledText,
  options,
  ...props
}) => {
  const classes = useStyles();
  const customClasses = styles();

  const [isPresent, setIsPresent] = useState(false);

  useLayoutEffect(() => {
    if (value) {
      let found = options.find((element) => element?.id === value?.id);
      if (found) {
        setIsPresent(true);
      } else {
        setIsPresent(false);
      }
    }
  }, [value, selector, options]);

  return (
    <div id={'MUI_' + id} className={classes.root}>
      {label && (
        <InputLabel
          id={labelId ? labelId : label + '__InputLabel'}
          style={customClasses.inputLabel}
        >
          {label}
        </InputLabel>
      )}

      <Autocomplete
        id={id}
        options={options}
        value={isPresent ? value : null}
        getOptionSelected={(option) => option[selector]}
        getOptionLabel={(option) => option[selector]}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} style={customClasses.input} />
        )}
        disabled={disabled}
        {...props}
      />
    </div>
  );
};

export default MUIAutocomplete;
