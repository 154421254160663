/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

/** Import helpers section **/
import './PermissionsStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import DataTable from '../../components/DataTable';

const PermissionsView = ({
	loading,
	permissions,
	onAddPermission,
	onEditPermission,
	onDeletePermission
}) => {
	const ActionsCell = ({ row }) => {
		return (
			<>
				<EditIcon
					className="g-row-action-button"
					onClick={() => onEditPermission(row)}
				/>
				<DeleteIcon
					className="g-row-action-button delete"
					onClick={() => onDeletePermission(row)}
				/>
			</>
		);
	};

	const tableColumns = useMemo(
		() => [
			{
				name: 'Id',
				selector: 'id',
				sortable: true,
				center: true,
				omit: true
			},
			{
				name: 'Código',
				selector: 'code',
				sortable: true,
				center: true
			},
			{
				name: 'Tipo',
				selector: 'type_name',
				sortable: true,
				center: true
			},
			{
				name: '',
				cell: (row) => <ActionsCell row={row} />,
				ignoreRowClick: true,
				allowOverflow: true,
				center: true
			}
		],
		[]
	);

	return (
		<>
			<div className="permissions">
				<PageTitle title="Permisos" />
				<Paper className="g-page-header permissions_header">
					<Button
						className="permissions_header_button"
						onClick={onAddPermission}
						startIcon={<AddCircleIcon />}
					>
						Agregar nuevo permiso
					</Button>
				</Paper>

				<DataTable
					data={permissions}
					columns={tableColumns}
					loading={loading}
				/>
			</div>
		</>
	);
};

export default PermissionsView;
