/** Import react/libraries section **/
import React, { useState } from 'react';

/** Import resources section **/

/** Import helpers section **/
import MENU_ITEMS from './MENU_ITEMS';

/** Import component section **/
import MenuView from './MenuView';

export const MenuComponent = () => {
  const [expanded, setExpanded] = useState(null);

  const handleOnChange = (id) => (event, newExpanded) => {
    setExpanded(newExpanded ? id : null);
  };

  return (
    <MenuView
      items={MENU_ITEMS}
      expanded={expanded}
      onChange={handleOnChange}
    />
  );
};

export default MenuComponent;
