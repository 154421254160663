/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './WorkOrderModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal';
import MUITextField from '../../../components/inputs/MUITextField';
import MUIAutocomplete from '../../../components/inputs/MUIAutocomplete';

const WorkOrderModalView = ({
  workOrder,
  clients,
  responsibles,
  selectedClient,
  selectedResponsible,
  onClientChange,
  onResponsibleChange,
  onInputChange,
  onClose,
  onSave,
  disabled
}) => {
  return (
    <ModalComponent
      open={true}
      title={
        workOrder?.id ? 'Editar órden de trabajo' : 'Agregar órden de trabajo'
      }
      element={workOrder}
      onClose={onClose}
      onConfirm={onSave}
      disabled={disabled}
    >
      <div className="workordermodal">
        <MUIAutocomplete
          id="client"
          name="client_id"
          options={clients}
          label="Cliente"
          selector="name"
          value={selectedClient}
          onChange={onClientChange}
          disabled={workOrder?.total > 0}
        />
        <MUIAutocomplete
          id="responsible"
          name="responsible_id"
          options={responsibles}
          label="Responsable"
          selector="name"
          value={selectedResponsible}
          onChange={onResponsibleChange}
        />
        <MUITextField
          label="Proposal"
          defaultValue={workOrder?.proposal}
          type="text"
          name="proposal"
          onChange={onInputChange}
        />

        {/* <MUISelect
          label="Estatus"
          defaultValue={workOrder?.work_order_status_id}
          items={status}
          idSelector="id"
          selector="name"
          noSelectedLabel="Select"
          disabled={true}
          name="work_order_status_id"
          onChange={onInputChange}
        /> */}
        {/* <MUITextArea
          label="Comentarios"
          value={workOrder?.comments}
          type="text"
          name="comments"
          onChange={onInputChange}
        /> */}
      </div>
    </ModalComponent>
  );
};

export default WorkOrderModalView;
