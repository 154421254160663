/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FileCopyIcon from '@material-ui/icons/FileCopy';

/** Import helpers section **/
import './WorkOrdersStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/FilterBy';
import DataTable from '../../components/DataTable';
import MUISelect from '../../components/inputs/MUISelect';
import { useSelector } from 'react-redux';
import { MANAGER_ROLE_ID } from '../../config/APP_CONFIGS';

const WorkOrdersView = ({
  loading,
  workOrders,
  onFilterWorkOrders,
  onAddWorkOrder,
  onEditWorkOrder,
  onDownloadPdf,
  onWorkOrderServices,
  onDeleteWorkOrder,
  onAssignationView,
  onTabChange,
  selectedTab,
  years,
  selectedYear,
  onInputChangeYear,
  onRequiredDocs
}) => {
  const profile = useSelector((store) => store.session.profile);

  const ActionsCell = ({ row }) => {
    return (
      <div className="g-actions-cell">
        {row?.total > 0 && (
          <>
            <Tooltip
              title="Detalles de la orden de trabajo"
              aria-label="Client docs"
            >
              <AssignmentIcon
                className="g-row-action-button normal"
                onClick={() => onAssignationView(row)}
              />
            </Tooltip>
            <Tooltip title="Descargar pdf" aria-label="Descargar pdf">
              <GetAppIcon
                className="g-row-action-button complementary"
                onClick={() => onDownloadPdf(row)}
              />
            </Tooltip>
          </>
        )}
        {profile.role_id === MANAGER_ROLE_ID && (
          <Tooltip title="Servicios" aria-label="Servicios">
            <BusinessCenterIcon
              className="g-row-action-button secondary"
              onClick={() => onWorkOrderServices(row)}
            />
          </Tooltip>
        )}
        {profile.role_id === MANAGER_ROLE_ID && (
          <EditIcon
            className="g-row-action-button"
            onClick={() => onEditWorkOrder(row)}
          />
        )}
        <Tooltip
          title="Documentos requeridos"
          aria-label="Documentos requeridos"
        >
          <FileCopyIcon
            className="g-row-action-button delete complementary"
            onClick={() => onRequiredDocs(row)}
          />
        </Tooltip>
        <Divider orientation="vertical" flexItem />
        {profile.role_id === MANAGER_ROLE_ID && (
          <DeleteIcon
            className="g-row-action-button delete"
            onClick={() => onDeleteWorkOrder(row)}
          />
        )}
      </div>
    );
  };

  const tableColumns = [
    {
      name: 'id',
      selector: 'id',
      sortable: true,
      center: true,
      omit: true
    },
    {
      name: 'Número de orden',
      selector: (row) => String(row?.order_num).padStart(4, '0'),
      center: true,
      width: '100px'
    },
    {
      name: 'Fecha creación',
      selector: 'date',
      wrap: true,
      center: true,
      width: '100px'
    },
    {
      name: 'Cliente',
      row: (row) => row?.client?.title,
      selector: (row) => row?.client?.id + '-' + row?.client?.title,
      wrap: true,
      center: true
    },
    {
      name: 'Propuesta',
      selector: 'proposal',
      center: true
    },
    {
      name: 'Responsable',
      selector: 'responsible_name',
      center: true
    },
    {
      name: 'Servicios',
      selector: 'total',
      sortable: true,
      center: true,
      width: '30px'
    },
    {
      name: 'Status',
      selector: 'status',
      center: true
    },
    {
      name: '',
      cell: (row) => <ActionsCell row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      right: true,
      width: '240px'
    }
  ];

  return (
    <>
      <PageTitle title="Órdenes de trabajo" />
      <Paper className="g-page-header workorders_header">
        <div className="workorders_header_filterby">
          <FilterBy onChange={onFilterWorkOrders} />
        </div>
        <Tabs
          value={selectedTab}
          onChange={onTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="En proceso" disabled={loading} />
          <Tab label="Concluidas" disabled={loading} />
        </Tabs>
        <div className="workorders_header_rightButton">
          <MUISelect
            id="year"
            label="Año de órdenes"
            defaultValue={selectedYear?.year}
            items={years}
            selector="year"
            idSelector="year"
            noSelectedLabel="Select"
            name="year"
            onChange={onInputChangeYear}
          />
          {profile.role_id === MANAGER_ROLE_ID && (
            <Button onClick={onAddWorkOrder} startIcon={<AddCircleIcon />}>
              Agregar nueva orden
            </Button>
          )}
        </div>
      </Paper>

      <DataTable data={workOrders} columns={tableColumns} loading={loading} />
    </>
  );
};

export default WorkOrdersView;
