/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/
import { updateUrlDriveService } from '../../../api/services/work-order-services-services';
/** Import component section **/
import AssignationDeliverableModalView from './AssignationDeliverableModalView';

const AssignationDeliverableModalComponent = ({
  document,
  onClose,
  onUpdateList
}) => {
  const [documentData, setDocumentData] = useState(null);
  const [dataComplete, setDataComplete] = useState(false);
  console.log(document);
  useEffect(() => {
    const docInfo = {
      document_type_name: document?.service_name || '',
      folio: document?.folio || null,
      url: document?.url || null,
      id: document?.id || null,
      deadline_date: document?.deadline_date || null
    };
    setDocumentData(docInfo);
  }, [document]);

  const handleOnInputChange = (data) => {
    const { name, value } = data;
    setDocumentData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    let validated = false;

    const folio = documentData?.folio;
    const urlDrive = documentData?.url;

    if (urlDrive && folio) {
      validated = true;
    }
    console.log(documentData);
    setDataComplete(validated);
  }, [documentData]);

  const handleOnSave = () => {
    let params = {
      id: documentData.id,
      url: documentData.url,
      folio: documentData?.folio
    };
    updateUrlDriveService(params)
      .then(() => {
        onUpdateList();
        toast.success('Url de drive actualizado');
      })
      .catch((error) => {
        toast.error('Error, no se puede actualizar el url de drive.');
      })
      .finally(() => {
        onClose(null);
      });
  };
  return (
    <AssignationDeliverableModalView
      document={documentData}
      onInputChange={handleOnInputChange}
      onClose={onClose}
      onSave={handleOnSave}
      disabled={!dataComplete}
    />
  );
};

export default AssignationDeliverableModalComponent;
