/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './AssignationModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal';
import MUITextArea from '../../../components/inputs/MUITextArea';
import MUIAutocomplete from '../../../components/inputs/MUIAutocomplete';

const AssignationModalView = ({
  assignation,
  operators,
  services,
  onInputChange,
  handleOnOperatorChange,
  selectedColaborator,
  onServiceChange,
  selectedService,
  onClose,
  onSave,
  disabled
}) => {
  const title = assignation?.work_order_service_id
    ? 'Editar servicio y operador'
    : 'Agregar servicio y operador';

  const assignationTitle = assignation?.title;

  return (
    <ModalComponent
      open={true}
      title={title}
      onClose={onClose}
      onConfirm={onSave}
      disabled={disabled}
    >
      <div className="assignationModal">
        <div className="assignationModal_title">
          El servicio de{' '}
          <span className="assignationModal_title_service">
            {assignationTitle}{' '}
          </span>
          se asignará a{' '}
        </div>
        <div className="assignationModal_select">
          <MUIAutocomplete
            id="id"
            label="Operador asignado"
            value={selectedColaborator}
            options={operators}
            selector="name"
            name="user_id"
            onChange={handleOnOperatorChange}
          />
          <MUIAutocomplete
            id="id"
            label="Servicio seleccionado"
            value={selectedService}
            options={services}
            selector="title"
            name="service_id"
            onChange={onServiceChange}
          />
          <div className="assignationModal_select_list">
            <div className="assignationModal_select_list_title">
              Servicios asignados
            </div>
            {operators.map((item, index) => (
              <div className="assignationModal_select_list_item" key={index}>
                <div>{item.name}</div>
                <div>{item.services}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="assignationModal_comments">
        <MUITextArea
          label="Comentarios"
          value={assignation?.comments}
          type="text"
          name="comments"
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default AssignationModalView;
