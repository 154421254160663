/** Import react/libraries section **/
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

/** Import resources section **/
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

/** Import component section **/
import ModalTitle from './ModalTitle';
import ModalActions from './ModalActions';

const ModalComponent = ({
  open,
  title,
  width = 600,
  children,
  onClose,
  onConfirm,
  cancelText,
  confirmText,
  disabled
}) => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      width: width + 'px'
    },
    content: {}
  }));

  const classes = useStyles();
  let refWrapper = createRef();

  return (
    <div ref={refWrapper}>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'md'}
        classes={{ paper: classes.paper }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onConfirm();
          }}
          noValidate
        >
          <ModalTitle onClose={onClose}>{title}</ModalTitle>

          <DialogContent classes={{ root: classes.content }}>
            {children}
          </DialogContent>

          <ModalActions
            onClose={onClose}
            onConfirm={onConfirm}
            cancelText={cancelText ? cancelText : 'Cancelar'}
            confirmText={confirmText ? confirmText : 'Aceptar'}
            disabled={disabled}
          />
        </form>
      </Dialog>
    </div>
  );
};

ModalComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  disabled: PropTypes.bool
};

export default ModalComponent;
