/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/
import { SERVICES_ROUTE } from '../../config/URL_ROUTES';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';

/** Import syles section **/
import './ServiceDocumentsStyles.scss';

/** Import component section **/
import FilterBy from '../../components/FilterBy';
import MUICheckBox from '../../components/inputs/MUICheckBox';
import DataTable from '../../components/DataTable';
import PageTitle from '../../components/PageTitle';

const ServiceDocumentsView = ({
  title,
  docs,
  mode,
  loading,
  showChecked,
  onFilterBy,
  onSwitchChange,
  onSelectedChange,
  onSave
}) => {
  const tableColumns = [
    {
      name: 'Documento',
      selector: 'name',
      sortable: true,
      wrap: true,
      grow: 1.5
    },
    {
      name: 'Descripción',
      selector: 'description',
      sortable: true,
      wrap: true,
      grow: 1.5
    },
    {
      name: 'Categoría',
      selector: (row) => row.document_category?.name,
      sortable: true,
      center: true
    }
  ];

  return (
    <>
      <PageTitle title={title} backUrl={SERVICES_ROUTE} />
      <Paper className="g-page-header serviceDocs_header">
        <div className="serviceDocs_header_filters">
          <FormControlLabel
            control={
              <Switch
                checked={showChecked}
                onChange={onSwitchChange}
                name="show_switch"
                color="primary"
              />
            }
            label="Mostrar seleccionados"
          />
          <FilterBy onChange={onFilterBy} />
        </div>
        <Button onClick={onSave} startIcon={<SaveIcon />}>
          Actualizar documentos
        </Button>
      </Paper>
      <DataTable
        data={docs}
        columns={tableColumns}
        loading={loading}
        selectableRows={true}
        selectableRowsComponent={MUICheckBox}
        selectableRowsNoSelectAll={false}
        selectableRowsHighlight={true}
        onSelectedRowsChange={onSelectedChange}
        selectableRowSelected={(row) => row.added}
      />
    </>
  );
};

export default ServiceDocumentsView;
