/** Import react/libraries section **/
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

/** Import resources section **/

/** Import helpers section **/

/** Import component section **/
import MenuItemView from './MenuItemView';

export const MenuItemComponent = ({ item }) => {
  let history = useHistory();
  let menuOpen = useSelector((store) => store.globals.menuOpen);

  const handleOnClick = (e) => {
    history.push(item?.url);
  };

  return (
    <MenuItemView
      Icon={item?.icon}
      label={item?.label}
      onClick={item?.url ? handleOnClick : null}
      menuOpen={menuOpen}
    />
  );
};

export default MenuItemComponent;
