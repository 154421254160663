/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import hepers section **/
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

/** Import resources section **/
import { ReactComponent as IconPDF } from '../../assets/pdf.svg';
/** Import styles section **/
import './DropFile.scss';

/** Import components section **/
import Modal from '../Modal';

function DropFile({
  open = false,
  title = '',
  url,
  maxFiles = 1,
  onFileUploaded,
  onClose,
  onConfirm,
  children,
  ...props
}) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: maxFiles,
    accept: ['image/*', 'application/pdf'],
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });

  const downloadImage = (src) => {
    window.open(src, 'Image');
  };

  const FileItem = ({ src, name }) => {
    var fileExtension = name.split('.').pop();
    return (
      <div className="drop-file_items_item" onClick={(e) => downloadImage(src)}>
        {fileExtension === 'pdf' ? (
          <div className="drop-file_items_item_image">
            <IconPDF title={name} className="drop-file_items_item_image_img" />
          </div>
        ) : (
          <div className="drop-file_items_item_image">
            <img
              src={src}
              alt={name}
              className="drop-file_items_item_image_img"
            />
          </div>
        )}
      </div>
    );
  };

  const previews = files.map((file) => (
    <div className="drop-file_items_item" key={file.name}>
      <FileItem src={file.preview} name={file.name} />
      <div className="drop-file_items_item_file">
        <div className="drop-file_items_item_file_path"> {file.path} </div>
        <div className="drop-file_items_item_file_size">
          {new Intl.NumberFormat().format(file.size)} bytes
        </div>
      </div>
    </div>
  ));

  useEffect(() => {
    onFileUploaded(files);
  }, [files, onFileUploaded]);

  const handleOnConfirm = () => {
    onConfirm(files);
  };

  return (
    <Modal
      open={open}
      onConfirm={handleOnConfirm}
      onClose={onClose}
      title={title}
      {...props}
    >
      <section className="drop-file">
        <div {...getRootProps({ className: 'drop-file_zone' })}>
          <input {...getInputProps()} />
          Arrastra y suelta el archivo aquí, o dale click para seleccionarlos
        </div>
        <div className="drop-file_items">{previews}</div>
      </section>
      {children}
    </Modal>
  );
}

DropFile.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  maxFiles: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.object
};

export default DropFile;
