import request from '../api-connector';

export const getCategoriesService = () => {
  const serviceDef = {
    url: 'service-category',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const getServicesService = () => {
  const serviceDef = {
    url: 'service/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const addServiceService = ({
  title,
  description,
  service_category_id,
  max_time,
  active
}) => {
  const serviceDef = {
    url: 'service/create',
    method: 'post',
    params: { title, description, service_category_id, max_time, active }
  };
  return request(serviceDef);
};

export const updateServiceService = ({
  id,
  title,
  description,
  service_category_id,
  max_time,
  active
}) => {
  const serviceDef = {
    url: 'service/update',
    method: 'post',
    params: { id, title, description, service_category_id, max_time, active }
  };
  return request(serviceDef);
};

export const changeActiveService = ({ id, active }) => {
  const serviceDef = {
    url: 'service/active',
    method: 'post',
    params: {
      id,
      active
    }
  };
  return request(serviceDef);
};

export const deleteServiceService = ({ id }) => {
  const serviceDef = {
    url: 'service/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const readServiceService = ({ id }) => {
  const serviceDef = {
    url: 'service/read',
    method: 'get',
    params: { id }
  };
  return request(serviceDef);
};
