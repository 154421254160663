/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

/** Import helpers section **/
import { toast } from 'react-toastify';
/** Import helpers section **/

/** Import component section **/
import ClientDocumentsRequieredView from './ClientDocumentsRequieredView';

import ModalComponent from '../../components/Modal/ModalComponent';
import {
  getDocumentsRequired,
  updateDocumentsRequiredRowsService,
  updateDocumentsRequiredService
} from '../../api/services/client-documents-services';
import MUITextArea from '../../components/inputs/MUITextArea';

const ClientDocumentsRequiredComponent = () => {
  let params = useParams();
  let clientId = params['id'];

  const [loading, setLoading] = useState(false);
  const [clientName, setClientName] = useState(
    'Documentos requeridos del cliente: '
  );
  const [originalData, setOriginalData] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [modalMode, setModalMode] = useState('');
  // const [showChecked, setShowChecked] = useState(false);
  const [filteringText, setFilteringText] = useState('');
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState([]);

  const getListDocuments = useCallback(() => {
    setLoading(true);
    getDocumentsRequired({ clientId })
      .then((response) => {
        var client = '';

        const documentsData = response?.documents;
        client = clientName + response?.client?.title;

        setDocuments(documentsData);
        setOriginalData(documentsData);

        setClientName(client);
      })
      .catch(() => {
        toast.error('No se pueden leer los documentos');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [clientId]);

  useEffect(() => {
    getListDocuments();
  }, [clientId, getListDocuments]);

  const handleOnModalClose = () => {
    setModalMode('');
    setSelectedDocument(null)
  };

  const handleOnUpdateList = () => {
    getListDocuments();
  };

  const handleOnInputChange = (data) => {

    const { name, value } = data;

    setSelectedDocument((prevState) => ({
      ...prevState,
      [name]: value
    }));


  }

  const handleOnFiltering = useCallback(() => {
    var filteredItems = [...originalData];
    //si mostrar seleccionados
    // if (showChecked) {
    //   filteredItems = filteredItems.filter((item) => item.added);
    // }

    setDocuments(filteredItems);

    if (filteringText.length > 0) {
      const filteringTextLC = filteringText.toLowerCase();
      filteredItems = filteredItems.filter((item) => {
        const field1 =
          item && item?.document_type?.name.toLowerCase().includes(filteringTextLC);
        return field1;
      });
      if (selectedDocuments.length > 0)
        selectedDocuments.forEach(addSelectedDocsIntoFilter);

      function addSelectedDocsIntoFilter(item) {
        //buscamos si existen los documentos seleccionados en el filtro de texto
        const objExist = filteredItems.find((doc) => doc.id === item.id);

        //si no existen los documentos seleccionados en la busqueda los agregaremos
        if (objExist === undefined) {
          filteredItems.push(item);
        }
        setDocuments(filteredItems);
      }
    }
  }, [filteringText, originalData]); //showChecked,

  // const handleOnSwitchChange = (event) => {
  //   //muestra o no seleccionados
  //   setShowChecked(event?.target?.checked);
  // };

  const handleOnFilterBy = (text) => {
    setFilteringText(text);
  };

  useEffect(() => {
    handleOnFiltering();
  }, [filteringText, handleOnFiltering]); //showChecked,

  const handleOnAddRequiredDocs = () => {
    setModalMode('save');
  };

  const updateDocumentsRequired = () => {
    const params = {
      client_id: clientId,
      services: finalSelectedServices()
    };
    updateDocumentsRequiredService(params)
      .then((response) => {
        toast.success('Documentos actualizados');
        getListDocuments();
      })
      .catch((error) => {
        toast.error('Error al actualizar documentos');
      });
  };

  const handleOnSaveConfirm = () => {
    setModalMode('');
    updateDocumentsRequired();
  };

  const finalSelectedServices = () => {
    const finalServices = [];
    for (let i = 0; i < documents.length; i++) {
      const currentDoc = documents[i];
      const found = selectedDocuments.find(
        (element) => element.id === currentDoc.id
      );
      currentDoc.added = found ? true : false;
      finalServices.push(currentDoc);
    }
    return finalServices;
  };

  const handleOnSelectedChange = (state) => {
    // { allSelected, selectedCount, selectedRows }
    setSelectedDocuments(state.selectedRows);
  };

  const handleOnEditComments = (row) => {
    setModalMode('comments')
    setSelectedDocument(row);
    console.log(row)
  }

  const handleOnEditGoogleDriveReference = (row) => {
    setModalMode('drive')
    setSelectedDocument(row);
    console.log('handleOnEditGoogleDriveReference')
  }

  const handleOnUpdateRows = () => {

    let params = {
      client_document_id: selectedDocument?.client_document_id,
      comments: selectedDocument?.comments,
      url: selectedDocument?.url
    }

    updateDocumentsRequiredRowsService(params).then((response) => {
      toast.success("Documento actualizado")
      getListDocuments()
      handleOnModalClose()
    })
      .catch(() => {
        toast.error('No se puedo actualizar la info');
      })
      .finally(() => {
        setLoading(false);
      });
  }



  return (
    <>
      <ClientDocumentsRequieredView
        title={clientName}
        loading={loading}
        onFilterBy={handleOnFilterBy}
        onAddRequiredDocs={handleOnAddRequiredDocs}
        onSelectedChange={handleOnSelectedChange}
        selectedDocuments={selectedDocuments}
        documents={documents}
        onEditComments={handleOnEditComments}
        onEditGoogleDriveReference={handleOnEditGoogleDriveReference}
      />

      {modalMode === 'save' && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnSaveConfirm}
          title="Actualizar servicios"
        >
          <div>¿Estas seguro que quieres actualizar los documentos?</div>
        </ModalComponent>
      )}

      {selectedDocument && modalMode === 'comments' && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnUpdateRows}
          title="Agregar comentarios"
        >
          <MUITextArea
            label="Comentarios"
            value={selectedDocument?.comments}
            type="text"
            name="comments"
            onChange={handleOnInputChange}
          />
        </ModalComponent>
      )}

      {selectedDocument && modalMode === 'drive' && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnUpdateRows}
          title="Agregar enlace de Google Drive"
        >
          <MUITextArea
            label="Enlace"
            value={selectedDocument?.url}
            type="text"
            name="url"
            onChange={handleOnInputChange}
          />

        </ModalComponent>
      )}

    </>
  );
};

export default ClientDocumentsRequiredComponent;
