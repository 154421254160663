/** Import helpers section **/
import * as routes from './URL_ROUTES';

/** Import component section **/
import LoginComponent from '../views/Login';
import RegistrationComponent from '../views/Registration';
import EmailValidationComponent from '../views/EmailValidation';
import ChangePasswordRequestComponent from '../views/ChangePasswordRequest';
import ChangePasswordComponent from '../views/ChangePassword';

import CompaniesComponent from '../views/Companies';
import ClientsComponent from '../views/Clients';
import ClientDocumentsComponent from '../views/ClientDocuments';

import UsersComponent from '../views/Users';
import DocumentsComponent from '../views/Documents';
import ServicesComponent from '../views/Services';
import ServiceDocumentsComponent from '../views/ServiceDocuments';

import WorkOrdersComponent from '../views/WorkOrders';
import WorkOrderServicesComponent from '../views/WorkOrderServices';
import AssignationsComponent from '../views/Assignations';
// import AssignationDeliverablesComponent from '../views/AssignationDeliverables';
import DashboardComponent from '../views/Dashboard';
import ClientDocumentStorageComponent from '../views/ClientDocumentStorage';

import PermissionsComponent from '../views/Permissions';
import RolePermisionsComponent from '../views/RolePermissions';
import ActivitiesComponent from '../views/Activities';
import ClientUsersComponent from '../views/ClientUsers/ClientUsersComponent';
import ClientDocumentsRequiredComponent from '../views/ClientDocumentsRequired/ClientDocumentsRequiredComponent';

/*********************************************************************
 ************************  ROUTES CONTENT  ***************************
 *********************************************************************/

export const PUBLIC_ROUTES = [
  {
    path: '/',
    component: LoginComponent
  },
  {
    path: routes.LOGIN_ROUTE,
    component: LoginComponent
  },
  {
    path: routes.REGISTRATION_ROUTE,
    component: RegistrationComponent
  },
  {
    path: routes.EMAIL_VALIDATION_ROUTE + '/:token',
    component: EmailValidationComponent
  },
  {
    path: routes.CHANGE_PASSWORD_REQUEST_ROUTE,
    component: ChangePasswordRequestComponent
  },
  {
    path: routes.CHANGE_PASSWORD_ROUTE + '/:token',
    component: ChangePasswordComponent
  }
];

export const PRIVATE_ROUTES = [
  {
    path: routes.DASHBOARD_ROUTE,
    component: DashboardComponent
  },
  {
    path: routes.USERS_ROUTE,
    component: UsersComponent
  },
  {
    path: routes.DOCUMENTS_CATALOGUE_ROUTE,
    component: DocumentsComponent
  },
  {
    path: routes.SERVICES_ROUTE,
    component: ServicesComponent
  },
  {
    path: routes.SERVICE_REQUIREMENTS_ROUTE + '/:id',
    component: ServiceDocumentsComponent
  },
  {
    path: routes.SERVICE_DELIVERABLES_ROUTE + '/:id',
    component: ServiceDocumentsComponent
  },
  {
    path: routes.PERMISSIONS_ROUTE,
    component: PermissionsComponent
  },
  {
    path: routes.ROLE_PERMISSIONS_ROUTE,
    component: RolePermisionsComponent
  },
  {
    path: routes.COMPANIES_ROUTE,
    component: CompaniesComponent
  },
  {
    path: routes.CLIENTS_ROUTE,
    component: ClientsComponent
  },
  {
    path: routes.CLIENT_DOCUMENTS_ROUTE + '/:id',
    component: ClientDocumentsComponent
  },
  {
    path: routes.WORK_ORDERS_ROUTE,
    component: WorkOrdersComponent
  },
  {
    path: routes.WORK_ORDER_SERVICES_ROUTE + '/:id',
    component: WorkOrderServicesComponent
  },
  {
    path: routes.ASSIGNATIONS_ROUTE + '/:id',
    component: AssignationsComponent
  },
  // {
  //   path: routes.CLIENT_ASSIGNATIONS_ROUTE,
  //   component: AssignationsComponent
  // },
  // {
  //   path: routes.ASSIGNATION_DELIVERABLES_ROUTE + '/:id',
  //   component: AssignationDeliverablesComponent
  // },
  {
    path: routes.CLIENT_WORKORDERS_STATUS_ROUTE,
    component: DashboardComponent
  },
  {
    path: routes.CLIENT_DOCUMENTS_STORAGE_ROUTE,
    component: ClientDocumentStorageComponent
  },
  {
    path: routes.ACTIVITIES_ROUTE,
    component: ActivitiesComponent
  },
  {
    path: routes.CLIENT_USERS_ROUTE,
    component: ClientUsersComponent
  },
  {
    path: routes.ADD_DELIVERABLE_DOCUMENTS + '/:id',
    component: ClientDocumentsRequiredComponent
  }
];
