/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import resources section **/

/** Import services section **/
import { readAssignationService } from '../../../api/services/assignations-services';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import component section **/
import AssignationModalView from './AssignationModalView';

const AssignationModalComponent = ({ assignation, onClose }) => {
  const [assignationData, setAssignationData] = useState(null);

  const handleOnDownLoad = (row) => {
    const urlDrive = row?.document?.url;
    window.open(urlDrive, '_blank');
  };

  const readAssignation = useCallback(() => {
    readAssignationService({
      work_order_service_id: assignation?.id
    })
      .then((response) => {
        if (response?.work_order_service)
          setAssignationData(response?.work_order_service);
      })
      .catch((error) => {
        toast.error('No se pudo cargar la asignación');
      });
  }, [assignation]);

  useEffect(() => {
    readAssignation();
  }, [readAssignation]);

  return (
    <AssignationModalView
      assignationData={assignationData}
      onDownLoad={handleOnDownLoad}
      onClose={onClose}
    />
  );
};

export default AssignationModalComponent;
