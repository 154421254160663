/*********************************************************************
 ***********************  ROUTES DEFINITION  *************************
 *********************************************************************/

// Public routes
export const LOGIN_ROUTE = '/login';
export const REGISTRATION_ROUTE = '/registration';
export const EMAIL_VALIDATION_ROUTE = '/register-verify';
export const CHANGE_PASSWORD_REQUEST_ROUTE = '/change-password-request';
export const CHANGE_PASSWORD_ROUTE = '/change-password';

// Private routes
export const ROLE_PERMISSIONS_ROUTE = '/permisos-rol';
export const PERMISSIONS_ROUTE = '/catalogo-permisos';

export const ACTIVITIES_ROUTE = '/actividades';

export const USERS_ROUTE = '/usuarios';

export const COMPANIES_ROUTE = '/companias';
export const CLIENTS_ROUTE = '/clientes';
export const CLIENT_DOCUMENTS_ROUTE = '/documentos-cliente';

export const DOCUMENTS_CATALOGUE_ROUTE = '/catalogo-documentos';
export const SERVICES_ROUTE = '/servicios';
export const SERVICE_REQUIREMENTS_ROUTE = '/requisitos-servicio';
export const SERVICE_DELIVERABLES_ROUTE = '/entregables-servicio';
export const WORK_ORDERS_ROUTE = '/ordenes';
export const WORK_ORDER_SERVICES_ROUTE = '/servicios-orden';
export const ASSIGNATIONS_ROUTE = '/asignaciones';
// export const ASSIGNATION_DELIVERABLES_ROUTE = '/entregables-asignacion';
export const DASHBOARD_ROUTE = '/tablero';

export const CLIENT_WORKORDERS_STATUS_ROUTE = '/cliente/status';
export const CLIENT_DOCUMENTS_STORAGE_ROUTE = '/cliente/documentos';
// export const CLIENT_ASSIGNATIONS_ROUTE = '/cliente/asignaciones';
export const CLIENT_USERS_ROUTE = '/usuarios-clientes';
export const ADD_DELIVERABLE_DOCUMENTS = '/agregar-documentos-entregables';
