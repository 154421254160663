/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/
import { assignWorkOrderService } from '../../../api/services/work-order-services-services';

/** Import component section **/
import AssignationModalView from './AssignationModalView';

const AssignationModalComponent = ({
  assignationData,
  services,
  operators = [],
  onClose,
  onUpdateList
}) => {
  const [assignation, setAssignation] = useState(assignationData);
  const [dataComplete, setDataComplete] = useState(null);
  const [selectedColaborator, setSelectedColaborator] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  console.log(assignation);
  useEffect(() => {
    const operatorFound = operators.find(
      (item) => item.id === assignation?.user_id
    );
    const servicesFound = services.find(
      (item) => item.id === assignation?.service_id
    );

    if (operatorFound) setSelectedColaborator(operatorFound);
    if (servicesFound) setSelectedService(servicesFound);
  }, [operators, services, assignation?.service_id, assignation?.user_id]);

  const handleOnColaboratorChange = (data) => {
    setSelectedColaborator(data);
    let userId = data?.id ? data.id : null;
    setAssignation((prevState) => ({
      ...prevState,
      user_id: userId
    }));
  };

  const handleOnServiceChange = (data) => {
    setSelectedService(data);
    let serviceId = data?.id ? data.id : null;
    setAssignation((prevState) => ({
      ...prevState,
      service_id: serviceId
    }));
  };

  const handleOnInputChange = (data, field) => {
    const { name, value } = data;
    setAssignation((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    if (!assignation) return;
    let validated = true;

    const userId = assignation?.user_id;
    if (!userId) {
      validated = false;
    }

    setDataComplete(validated);
  }, [assignation]);

  const assignWorkOrder = () => {
    assignWorkOrderService(assignation)
      .then((response) => {
        toast.success('Servicio asignado');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('Error al asignar servicio');
      });
  };

  const handleOnSave = () => {
    assignWorkOrder();
  };

  return (
    <AssignationModalView
      assignation={assignation}
      operators={operators}
      onInputChange={handleOnInputChange}
      onClose={onClose}
      onSave={handleOnSave}
      disabled={!dataComplete}
      handleOnOperatorChange={handleOnColaboratorChange}
      onServiceChange={handleOnServiceChange}
      selectedService={selectedService}
      selectedColaborator={selectedColaborator}
      services={services}
    />
  );
};

export default AssignationModalComponent;
