/** Import react/libraries section **/
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import ChatIcon from '@material-ui/icons/Chat';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import CheckIcon from '@material-ui/icons/Check';
import PublishIcon from '@material-ui/icons/Publish';
import FeedbackIcon from '@material-ui/icons/Feedback';

/** Import helpers section **/
import './AssignationsStyles.scss';
import {
  CLIENT_ROLE_ID,
  DELIVERABLES_ACCESS_PERMISSION,
  MANAGER_ROLE_ID
} from '../../config/APP_CONFIGS';
import { HasPermission } from '../../access/AccessControl';
import {
  ORDER_SERVICE_EDIT,
  UNBLOCK_DRIVE_FILES
} from '../../access/PERMISSIONS';

/** Import component section **/
import { ActionAccess } from '../../access/AccessControl';
import PageTitle from '../../components/PageTitle';
import DataTable from '../../components/DataTable';
import LoadingComponent from '../../components/Loading';

const AssignationsView = ({
  loading,
  workOrders,
  onMessages,
  onDriveRedirect,
  onRedirectToWOServices,
  onDownloadPDF,
  onClientDocs,
  onDeliverables,
  onAuthorizedDocument,
  onInfoClient,
  backUrl,
  onCloseOrder,
  onPaymentAlert
}) => {
  const ALL_PERMISSION_EDIT = HasPermission(ORDER_SERVICE_EDIT);
  let profile = useSelector((store) => store.session.profile);
  const sessionClient = profile.role_id === CLIENT_ROLE_ID;

  const OrderActionsCell = ({ row }) => {
    const hasWorkOrders =
      row?.work_order_services && row?.work_order_services?.length > 0
        ? true
        : false;

    return (
      <div>
        {/* DELIVERABLES_ACCESS_PERMISSION permiso de descarga de documents para operador y administrador */}
        <ActionAccess permissionsRequest={DELIVERABLES_ACCESS_PERMISSION}>
          {hasWorkOrders ? (
            <Tooltip title="Descarga orden" aria-label="Order pdf">
              <GetAppIcon
                className="g-row-action-button complementary"
                onClick={() => onDownloadPDF(row)}
              />
            </Tooltip>
          ) : (
            <span className="assignations_workOrderHeader_item_data">
              * Sin servicios
            </span>
          )}
          <Tooltip title="Expediente del cliente" aria-label="Client docs">
            <AssignmentTurnedInIcon
              className="g-row-action-button secondary"
              onClick={() => onClientDocs(row)}
            />
          </Tooltip>
          <Tooltip title="Info. de cliente" aria-label="Info. de cliente">
            <InfoIcon
              className="g-row-action-button primary"
              onClick={() => onInfoClient(row)}
            />
          </Tooltip>
          {row?.work_order_status_id === 1 &&
            profile.role_id === MANAGER_ROLE_ID && (
              <Tooltip
                title="Cerrar órden de trabajo"
                aria-label="Cerrar órden de trabajo"
              >
                <CheckIcon
                  className="g-row-action-button complementary"
                  onClick={() => onCloseOrder(row)}
                />
              </Tooltip>
            )}
        </ActionAccess>
        {sessionClient && (
          <div className="assignations_message_client">
            Chat Asesor
            {row?.last_message_readed === false ? (
              <Tooltip title="Nuevo mensaje" aria-label="Mensajes">
                <FeedbackIcon
                  className="g-row-action-button new_message"
                  onClick={() => onMessages(row)}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Mensajes" aria-label="Mensajes">
                <ChatIcon
                  className="g-row-action-button message"
                  onClick={() => onMessages(row)}
                />
              </Tooltip>
            )}
          </div>
        )}
      </div>
    );
  };

  const ServiceActionsCell = ({ row }) => {
    const lockIcon = row?.authorized ? (
      <Tooltip
        title="Bloquear link de Drive a cliente"
        aria-label="Desbloquear link de Drive a cliente"
      >
        <LockOpenIcon
          className="g-row-action-button normal"
          onClick={() => onAuthorizedDocument(row)}
        />
      </Tooltip>
    ) : (
      <Tooltip
        title="Desbloquear link de Drive a cliente"
        aria-label="Desbloquear link de Drive a cliente"
      >
        <LockIcon
          className="g-row-action-button normal"
          onClick={() => onAuthorizedDocument(row)}
        />
      </Tooltip>
    );

    const getClientDriveFile =
      row?.url !== null && sessionClient && row?.authorized === 1 ? (
        <Tooltip title="Acceder al Documento" aria-label="Acceder al Documento">
          <FileCopyIcon
            className="g-row-action-button complementary"
            onClick={() => onDriveRedirect(row)}
          />
        </Tooltip>
      ) : row?.url !== null && sessionClient && row?.authorized === 0 ? (
        <Tooltip title="Acceder al Documento" aria-label="Acceder al Documento">
          <FileCopyIcon
            className="g-row-action-button disabled"
            onClick={() => onPaymentAlert(row)}
          />
        </Tooltip>
      ) : null;

    const getStaffsDriveFile =
      row?.url !== null ? (
        <Tooltip title="Acceder al Documento" aria-label="Acceder al Documento">
          <FileCopyIcon
            className="g-row-action-button complementary"
            onClick={() => onDriveRedirect(row)}
          />
        </Tooltip>
      ) : null;

    return (
      <div className="g-actions-cell">
        <ActionAccess permissionsRequest={DELIVERABLES_ACCESS_PERMISSION}>
          {getStaffsDriveFile}
        </ActionAccess>
        {(!!ALL_PERMISSION_EDIT || row?.user_id === profile.user_id) && (
          <>
            <Tooltip title="Entregables" aria-label="Entregables">
              <PublishIcon
                className="g-row-action-button primary"
                onClick={() => onDeliverables(row)}
              />
            </Tooltip>
          </>
        )}
        <ActionAccess permissionsRequest={UNBLOCK_DRIVE_FILES}>
          {lockIcon}
        </ActionAccess>
        {getClientDriveFile}
      </div>
    );
  };

  const EstatusActionsCell = ({ row }) => {
    const status = row?.delay;
    if (sessionClient) {
      let statusText =
        status === 'Entregado con retraso' || status === 'Entregado a tiempo'
          ? 'Concluido'
          : 'En proceso';

      return statusText;
    }
    return status;
  };

  const ServiceNameCell = ({ row }) => {
    const serviceName = row?.service_name;

    return (
      <>
        {!sessionClient ? (
          <div
            className="assignations_serviceName"
            onClick={() => onRedirectToWOServices(row?.work_order_id)}
          >
            {serviceName}
          </div>
        ) : (
          serviceName
        )}
      </>
    );
  };

  const HeaderItem = ({
    field = '',
    data = '',
    num,
    percentageComplete = false,
    percentageNoComplete = false
  }) => {
    return (
      <div className="assignations_workOrderHeader_info_item">
        <div
          className={
            'assignations_workOrderHeader_info_item_field' +
            (percentageComplete || percentageNoComplete ? '_percentage' : '')
          }
        >
          {field}
        </div>
        <div
          className={
            'assignations_workOrderHeader_info_item_data' +
            (num
              ? ' num'
              : percentageComplete
              ? '_percentageComplete'
              : percentageNoComplete
              ? '_percentageNoComplete'
              : '')
          }
        >
          {data}
        </div>
      </div>
    );
  };

  const WorkOrderHeader = ({ assignationData }) => {
    const client = assignationData?.client;
    const clientName =
      client?.id + '-' + client?.title + ' ' + assignationData?.company_name;
    const responsible = assignationData?.responsible;
    const responsibleName = !!responsible?.first_name
      ? responsible?.first_name + ' ' + responsible?.last_name
      : '';
    const responsibleNameField = sessionClient
      ? 'Asesor de la órden'
      : 'Responsable de la órden';
    const responsibleCellPhoneField = sessionClient
      ? 'Celular del asesor'
      : 'Celular del responsable';
    const responsiblePhoneField = sessionClient
      ? 'Teléfono del asesor'
      : 'Teléfono del responsable';
    const responsibleEmailField = sessionClient
      ? 'Email del asesor'
      : 'Email del responsable';

    return (
      <div className="assignations_workOrderHeader">
        <div className="assignations_workOrderHeader_info">
          <HeaderItem
            num
            field="Número de orden"
            data={assignationData?.order_num}
          />
          <HeaderItem field="Cliente" data={clientName} />
          <HeaderItem
            field="Comentarios generales"
            data={assignationData?.comments}
          />
          <OrderActionsCell row={assignationData} />
          <HeaderItem field={responsibleNameField} data={responsibleName} />
          <HeaderItem field={responsiblePhoneField} data={responsible?.phone} />
          <HeaderItem
            field={responsibleCellPhoneField}
            data={responsible?.cell_phone}
          />
          <HeaderItem field={responsibleEmailField} data={responsible?.email} />
          <HeaderItem
            field="Riesgo"
            data={assignationData?.client?.risk?.title}
          />
          {/* <OrderActionsCell row={assignationData} /> */}
          {assignationData?.services_percentage !== 100 ? (
            <>
              <HeaderItem
                percentageNoComplete
                field="Avance de la órden"
                data={assignationData?.services_percentage + '%'}
              />
            </>
          ) : (
            <>
              <HeaderItem
                percentageComplete
                field="Avance de la órden"
                data={assignationData?.services_percentage + '%'}
              />
            </>
          )}
        </div>
        {!sessionClient && (
          <div className="assignations_message">
            {assignationData?.last_message_readed === false ? (
              <Tooltip title="Nuevo mensaje" aria-label="Mensajes">
                <FeedbackIcon
                  className="g-row-action-button new_message"
                  onClick={() => onMessages(assignationData)}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Mensajes" aria-label="Mensajes">
                <ChatIcon
                  className="g-row-action-button message"
                  onClick={() => onMessages(assignationData)}
                />
              </Tooltip>
            )}
          </div>
        )}
        {/* {assignationData?.last_message_readed === false ? (
          <div className="assignations_message">
            <Tooltip title="Nuevo mensaje" aria-label="Mensajes">
              <FeedbackIcon
                className="g-row-action-button new_message"
                onClick={() => onMessages(assignationData)}
              />
            </Tooltip>
          </div>
        ) : (
          <Tooltip title="Mensajes" aria-label="Mensajes">
            <ChatIcon
              className="g-row-action-button message"
              onClick={() => onMessages(assignationData)}
            />
          </Tooltip>
        )} */}
      </div>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Servicio',
        cell: (row) => <ServiceNameCell row={row} />,
        sortable: true,
        center: true,
        wrap: true,
        grow: 2
      },
      !sessionClient
        ? {
            name: 'Responsable',
            selector: 'operator',
            sortable: true,
            center: true
          }
        : {
            name: 'Asesor',
            selector: 'operator',
            sortable: true,
            center: true
          },
      !sessionClient && {
        name: 'Fecha límite',
        selector: 'deadline_date',
        sortable: true,
        center: true
      },
      {
        name: 'Estatus',
        cell: (row) => <EstatusActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      },
      {
        name: sessionClient ? 'Descargar documento' : '',
        cell: (row) => <ServiceActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    [profile.role_id]
  );

  return (
    <>
      <PageTitle title="Detalles de la Orden de Trabajo" backUrl={backUrl} />
      <div className="assignations_list">
        {loading ? (
          <LoadingComponent />
        ) : workOrders.length > 0 ? (
          workOrders.map((item, index) => (
            <Paper className="assignations_list_item" key={'wo_' + index}>
              <div className="assignations_list_item_header">
                <WorkOrderHeader assignationData={item} />
              </div>
              <DataTable
                data={item?.work_order_services}
                columns={tableColumns}
                pagination={false}
              />
            </Paper>
          ))
        ) : (
          <div className="assignations_list_empty"> No hay órdenes</div>
        )}
      </div>
    </>
  );
};

export default AssignationsView;
