import request from '../api-connector';

export const getClientDocumentsService = ({ client_id }) => {
  const serviceDef = {
    url: 'client-document/list',
    method: 'get',
    params: { client_id }
  };
  return request(serviceDef);
};

export const getClientByIdService = ({ id }) => {
  const serviceDef = {
    url: 'client-document/read',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const addClientDocumentService = ({
  client_id,
  status_id,
  document_type_id,
  document_date,
  url,
  validity_date,
  folio,
  comments
}) => {
  const serviceDef = {
    url: 'client-document/create',
    method: 'post',
    params: {
      client_id,
      status_id,
      document_type_id,
      document_date,
      url,
      validity_date,
      folio,
      comments
    }
  };
  return request(serviceDef);
};
export const editClientDocumentService = ({
  id,
  client_id,
  status_id,
  document_type_id,
  document_date,
  url,
  validity_date,
  folio,
  comments
}) => {
  const serviceDef = {
    url: 'client-document/update',
    method: 'post',
    params: {
      id,
      client_id,
      status_id,
      document_type_id,
      document_date,
      url,
      validity_date,
      folio,
      comments
    }
  };
  return request(serviceDef);
};
export const deleteClientDocumentService = ({ id }) => {
  const serviceDef = {
    url: 'client-document/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const getClientDocumentsStorageService = (clientId) => {
  console.log(clientId);
  const serviceDef = {
    url: 'client-document/storage',
    method: 'get',
    params: { client_id: clientId }
  };
  return request(serviceDef);
};

export const generateReportService = ({ clientId }) => {
  const serviceDef = {
    url: 'client-document/report',
    method: 'post',
    params: { client_id: clientId }
  };
  return request(serviceDef);
};

export const getDocumentsRequired = ({ clientId }) => {
  const serviceDef = {
    url: 'client-document/required',
    method: 'get',
    params: { client_id: clientId }
  };
  return request(serviceDef);
};

export const updateDocumentsRequiredService = ({ client_id, services }) => {
  const serviceDef = {
    url: 'client-document/update-required',
    method: 'post',
    params: { client_id, services }
  };
  return request(serviceDef);
};


export const updateDocumentsRequiredRowsService = ({ client_document_id, comments, url }) => {
  const serviceDef = {
    url: 'client-document/required/update-rows',
    method: 'post',
    params: { client_document_id, comments, url }
  };
  return request(serviceDef);
};

