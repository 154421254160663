/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/** Import helpers section **/
import { ASSIGNATIONS_ROUTE, DASHBOARD_ROUTE } from '../../config/URL_ROUTES';

/** Import services section **/
import { getSelectClientsService } from '../../api/services/clients-services';
import { getOperatorsAssignService } from '../../api/services/assignations-services';
import {
  getDashboardService,
  getClienStatusService
} from '../../api/services/dashboard-services';

/** Import resources section **/

/** Import component section **/
import DashboardView from './DashboardView';
import { toast } from 'react-toastify';
import { workOrderPDFService } from '../../api/services/work-orders-services';
import { useSelector } from 'react-redux';
import MessagesModalComponent from '../Assignations/MessageModal/MessageModalComponent';

const DashboardComponent = () => {
  const [dashboard, setDashboard] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [operators, setOperators] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [roleId, setRoleId] = useState([]);
  const [userId, setUserId] = useState([]);
  const [clientsOperator, setClientsOperator] = useState([]);
  const [modalMode, setModalMode] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);

  let history = useHistory();
  const pathname = useLocation().pathname;
  const isDashboard = pathname.includes(DASHBOARD_ROUTE);
  let profile = useSelector((store) => store.session);

  const getClients = useCallback(() => {
    setLoading(true);
    getSelectClientsService(clientsOperator)
      .then((response) => {
        if (response?.clients) setClients(response.clients);
      })
      .catch((error) => {
        console.error(
          'DashboardComponent - Error requesting clients list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [clientsOperator]);

  const getOperators = useCallback(() => {
    setLoading(true);
    getOperatorsAssignService()
      .then((response) => {
        if (response?.operators) setOperators(response.operators);
      })
      .catch((error) => {
        console.error(
          'DashboardComponent - Error requesting clients list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getDashboard = useCallback(() => {
    setLoading(true);
    const filters = {
      user_id: selectedOperator,
      client_id: selectedClient?.id
    };
    getDashboardService(filters)
      .then((response) => {
        const dashboardArr = response?.dashboard ? response.dashboard : [];
        const userId = response?.user_id ? response.user_id : [];
        const roleId = response?.role_id ? response.role_id : [];
        const clientsOperator = response?.clients_operator
          ? response.clients_operator
          : [];
        if (clientsOperator) setClientsOperator(clientsOperator);
        if (userId) setUserId(userId);
        if (roleId) setRoleId(roleId);
        if (dashboardArr) setDashboard(dashboardArr);
      })
      .catch((error) => {
        console.error(
          'Dashboard component - Error requesting dashboard: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedOperator, selectedClient]);

  const getClientStatus = useCallback(() => {
    setLoading(true);
    getClienStatusService()
      .then((response) => {
        const dasboardArr = response?.dashboard ? response.dashboard : [];
        setDashboard(dasboardArr);
      })
      .catch((error) => {
        console.error(
          'Dashboard component - Error requesting dashboard: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleOnModalClose = () => {
    setSelectedItem(null);
    setModalMode('');
  };

  useEffect(() => {
    if (isDashboard) {
      getDashboard();
    }
  }, [selectedClient, selectedOperator, isDashboard, getDashboard]);

  useEffect(() => {
    if (isDashboard) {
      getClients();
      getOperators();
    } else {
      getClientStatus();
    }
  }, [getClients, getOperators, getClientStatus, isDashboard]);

  const handleOnClientChange = (data) => {
    setSelectedClient(data);
  };

  const handleOnOperatorChange = (data) => {
    setSelectedOperator(data?.value);
  };

  const handleOnDownloadPdf = (row) => {
    setLoading(true);
    workOrderPDFService({ id: row?.id })
      .then((response) => {
        window.open(response?.url, '_blank');
      })
      .catch((err) => {
        if (err?.response_status === 406) {
          if (err?.errors === 'noState') {
            toast.warning('Registre el estado del cliente');
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOnAssignationView = (row) => {
    if (profile?.role === 'cliente') {
      history.push({
        pathname: ASSIGNATIONS_ROUTE + '/' + row?.id,
        state: {
          from: 'orderStatus'
        }
      });
    } else {
      history.push({
        pathname: ASSIGNATIONS_ROUTE + '/' + row?.id
      });
    }
  };

  const handleOnMessages = (row) => {
    setSelectedItem(row);
    setModalMode('messages');
  };

  return (
    <>
      <DashboardView
        isDashboard={isDashboard}
        loading={loading}
        dashboard={dashboard}
        clients={clients}
        selectedClient={selectedClient}
        onClientChange={handleOnClientChange}
        operators={operators}
        selectedOperator={selectedOperator}
        userId={userId}
        roleId={roleId}
        onOperatorChange={handleOnOperatorChange}
        onDownloadPdf={handleOnDownloadPdf}
        onAssignationView={handleOnAssignationView}
        onMessages={handleOnMessages}
      />

      {selectedItem && modalMode === 'messages' && (
        <MessagesModalComponent
          assignation={selectedItem}
          onClose={handleOnModalClose}
        />
      )}
    </>
  );
};

export default DashboardComponent;
