import request from '../api-connector';

export const getCategoriesService = () => {
  const serviceDef = {
    url: 'document-category/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const getValidityService = () => {
  const serviceDef = {
    url: 'document-validity',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const getDocumentsService = (props) => {
  let params = {
    document_category_id: props?.document_category_id
      ? props.document_category_id
      : null
  };
  const serviceDef = {
    url: 'document-type/list',
    method: 'post',
    params: params
  };
  return request(serviceDef);
};

export const addDocumentService = ({
  name,
  description,
  document_category_id,
  document_validity_id,
  active
}) => {
  const serviceDef = {
    url: 'document-type/create',
    method: 'post',
    params: {
      name,
      description,
      document_category_id,
      document_validity_id,
      active
    }
  };
  return request(serviceDef);
};

export const updateDocumentService = ({
  id,
  name,
  description,
  document_category_id,
  document_validity_id,
  active
}) => {
  const serviceDef = {
    url: 'document-type/update',
    method: 'post',
    params: {
      id,
      name,
      description,
      document_category_id,
      document_validity_id,
      active
    }
  };
  return request(serviceDef);
};

export const changeActiveService = ({ id, active }) => {
  const serviceDef = {
    url: 'document-type/active',
    method: 'post',
    params: {
      id,
      active
    }
  };
  return request(serviceDef);
};

export const deleteDocumentService = ({ id }) => {
  const serviceDef = {
    url: 'document-type/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const documentStatusCatalogService = () => {
  const serviceDef = {
    url: 'document-status',
    method: 'post',
    params: null
  };
  return request(serviceDef);
};
