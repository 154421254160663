/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import resources section **/

/** Import services section **/
import {
  getMessagesService,
  addMessageService
} from '../../../api/services/messages-services';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import component section **/
import MessageModalView from './MessageModalView';

const MessagesModalComponent = ({ assignation, onClose }) => {
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [sending, setSending] = useState(false);

  // setTimeout(function () {
  //   getMessages();
  // }, 2000);

  const getMessages = useCallback(() => {
    const params = {
      work_order_id: assignation?.id
    };
    getMessagesService(params)
      .then((response) => {
        if (response?.messages) setMessages(response?.messages);
      })
      .catch((error) => {
        toast.error('No se pudieron cargar los mensajes');
      });
  }, [assignation]);

  useEffect(() => {
    let timeout;
    function timeoutFn(status) {
      getMessages();
      timeout = setTimeout(timeoutFn, 2000);
    }
    timeoutFn();
    return () => {
      clearTimeout(timeout);
    };
  }, [getMessages]);

  const handleOnTextChange = (fieldData) => {
    const { value } = fieldData;
    setMessageText(value);
  };

  const handleOnSendMessage = () => {
    setSending(true);
    const params = {
      work_order_id: assignation?.id,
      text_message: messageText
    };
    addMessageService(params)
      .then((response) => {
        setMessageText('');
        getMessages();
      })
      .catch((error) => {
        toast.error('No se pudo enviar el mensaje');
      })
      .finally(() => {
        setSending(false);
      });
  };

  return (
    <MessageModalView
      messageText={messageText}
      messages={messages}
      sending={sending}
      onTextChange={handleOnTextChange}
      onSendMessage={handleOnSendMessage}
      onClose={onClose}
    />
  );
};

export default MessagesModalComponent;
