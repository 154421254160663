/** Import react/libraries section **/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

/** Import helpers section **/
import { PRIVATE_ROUTES } from '../../config/ROUTES_CONTENT';

/** Import styles section **/
import './PrivateContentStyles.scss';

/** Import resources section **/

/** Import component section **/
import { RouteAccess } from '../../access/AccessControl';

const PrivateContentComponent = () => {
  let permissions = useSelector((store) => store.permissions?.routes);
  const firstRoute = PRIVATE_ROUTES.find((privateRoute) =>
    permissions.some((item) => privateRoute.path === item)
  );
  const firstRoutePath = firstRoute ? firstRoute.path : '/';
  return (
    <>
      <Switch>
        <Route exact path="/">
          <Redirect to={firstRoutePath} />
        </Route>
        <>
          {PRIVATE_ROUTES.map((route, index) => (
            <RouteAccess
              key={index}
              permissionsRequest={route?.path?.split('/:')[0]}
            >
              <Route exact path={route.path} component={route.component} />
            </RouteAccess>
          ))}
        </>
      </Switch>
    </>
  );
};

export default PrivateContentComponent;
