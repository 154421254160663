/** Import react/libraries section **/
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

/** Import helpers section **/
import { isValidEmail } from '../../utils/validations.js';
import { LOGIN_ROUTE } from '../../config/URL_ROUTES';

/** Import styles section **/
import './ChangePasswordRequestStyles.scss';

/** Import resources section **/

/** Import services section **/
import { changePasswordRequestService } from '../../api/services/change-password-services';

/** Import component section **/
import ChangePasswordRequestView from './ChangePasswordRequestView';
import ConfirmModal from '../../components/ConfirmModal';

const ChangePasswordRequestComponent = () => {
  const [email, setEmail] = useState(null);
  const [validEmail, setValidEmail] = useState(false);
  const [inProcess, setInProcess] = useState(false);
  const [requested, setRequested] = useState(false);
  const [successRequest, setSuccessRequest] = useState(false);

  let history = useHistory();

  const handleOnSubmit = (data) => {
    setInProcess(true);
    changePasswordRequestService({ email })
      .then((response) => {
        setSuccessRequest(true);
      })
      .catch((err) => {
        setSuccessRequest(false);
      })
      .finally(() => {
        setRequested(true);
        setInProcess(false);
      });
  };

  const handeOnEmailChange = (data) => {
    setEmail(data.value);
    const isValid = isValidEmail(data.value);
    setValidEmail(isValid);
  };

  const handleOnResetRequest = () => {
    setRequested(false);
    setInProcess(false);
  };

  const handleOnConfirmModalClose = () => {
    history.push(LOGIN_ROUTE);
  };

  return (
    <>
      <ChangePasswordRequestView
        email={email}
        onEmailChange={handeOnEmailChange}
        validEmail={validEmail}
        requested={requested}
        inProcess={inProcess}
        successRequest={successRequest}
        onSubmit={handleOnSubmit}
        onResetRequest={handleOnResetRequest}
      />
      {successRequest && (
        <ConfirmModal
          title={'Password change requested!'}
          message={' You will receive a email to confirm.'}
          onClose={handleOnConfirmModalClose}
        />
      )}
    </>
  );
};

export default ChangePasswordRequestComponent;
