/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/
import { WORK_ORDERS_ROUTE } from '../../config/URL_ROUTES';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

/** Import syles section **/
import './WorkOrderServicesStyles.scss';

/** Import component section **/
import DataTable from '../../components/DataTable';
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/FilterBy';

const WorkOrderServicesView = ({
  title,
  services,
  loading,
  showChecked,
  onFilterBy,
  onSwitchChange,
  onSave,
  onAssign,
  onInputDocs,
  onOutputDocs,
  onSendAdminsNotification,
  onSendClientNotifications,
  onChanceService,
  onAddRequiredDocs
}) => {
  const ActionsCell = ({ row }) => {
    const show = row?.added;
    return (
      <>
        {show && (
          <div className="g-actions-cell">
            <Tooltip title="Assignación" aria-label="Assignación">
              <PermContactCalendarIcon
                className="g-row-action-button"
                onClick={(e) => onAssign(row)}
              />
            </Tooltip>

            <Divider orientation="vertical" flexItem />
            <Tooltip title="Requisitos" aria-label="Requisitos">
              <AssignmentTurnedInIcon
                className="g-row-action-button secondary"
                onClick={() => onInputDocs(row)}
              />
            </Tooltip>
            <Tooltip title="Entregables" aria-label="Entregables">
              <FileCopyIcon
                className={
                  'g-row-action-button ' +
                  (row?.user_name ? 'complementary' : 'disabled')
                }
                onClick={row?.user_name ? () => onOutputDocs(row) : null}
              />
            </Tooltip>
          </div>
        )}
      </>
    );
  };

  const ServiceActiveCell = ({ row }) => {
    if (row.added)
      return (
        <CheckCircleOutlineIcon
          className="g-row-action-button clickEffect"
          onClick={() => onChanceService(row)}
        />
      );
    return (
      <NotInterestedIcon
        className="g-row-action-button clickEffect_disabled"
        onClick={() => onChanceService(row)}
      />
    );
  };

  const tableColumns = [
    {
      name: 'Activo',
      cell: (row) => <ServiceActiveCell row={row} />,
      sortable: true,
      center: true
    },
    {
      name: 'Servicio',
      selector: (row) => row?.title,
      sortable: true,
      wrap: true,
      grow: 1.5
    },
    {
      name: 'Tiempo máximo',
      selector: (row) => row?.max_time,
      sortable: true,
      center: true,
      width: '80px'
    },
    {
      name: 'Categoría',
      selector: (row) => row?.service_category?.title,
      sortable: true,
      center: true
    },
    {
      name: 'Asignado a',
      selector: (row) => (row?.user_name ? row?.user_name : 'Sin asignar'),
      sortable: true,
      center: true
    },
    {
      name: '',
      cell: (row) => <ActionsCell row={row} />,
      sortable: true,
      center: true,
      width: '80'
    }
  ];

  return (
    <>
      <PageTitle title={title} backUrl={WORK_ORDERS_ROUTE} />
      <Paper className="g-page-header woServiceDocs_header">
        <div className="serviceDocs_header_filters">
          <FormControlLabel
            control={
              <Switch
                checked={showChecked}
                onChange={onSwitchChange}
                name="show_switch"
                color="primary"
              />
            }
            label="Mostrar seleccionados"
          />
          <FilterBy onChange={onFilterBy} />
        </div>
        <div className="woServiceDocs_header_buttons">
          <Button
            startIcon={<MailOutlineIcon />}
            onClick={onSendClientNotifications}
          >
            Enviar email a clientes
          </Button>
          <Button
            startIcon={<MailOutlineIcon />}
            onClick={onSendAdminsNotification}
          >
            Enviar email a responsables
          </Button>
          <Button
            className="g-row-action-button_check"
            onClick={onSave}
            startIcon={<SaveIcon />}
          >
            Actualizar servicios de la orden
          </Button>
        </div>
      </Paper>
      <DataTable data={services} columns={tableColumns} loading={loading} />
    </>
  );
};

export default WorkOrderServicesView;
