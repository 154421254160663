/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import component section **/
import ModalComponent from '../../../components/Modal/ModalComponent';

/** Import styles section **/
import './ClientOrdersModalStyles.scss';

const ClientOrdersModal = ({ clientData, onClose }) => {
  const [client, setClient] = useState(clientData);

  useEffect(() => {
    const data = Object.assign({}, clientData);
    setClient(data);
  }, [clientData]);
  console.log(client);

  const handleOnRedirectToWorkOrderDetail = (workOrderId) => {
    const url = window.location.origin + '/asignaciones/' + workOrderId;
    if (!!url) window.open(url, '_blank');
  };

  return (
    <ModalComponent
      open={true}
      title={'Ordenes de trabajo del cliente'}
      element={client}
      onClose={onClose}
    >
      <div className="workOrdersOrdersModal">
        {client.work_orders.length > 0 ? (
          <>
            <div className="workOrdersOrdersModal_table">
              <span>No. órden</span>
              <span>Status</span>
            </div>
            {client.work_orders.map((workOrder, index) => (
              <div key={'wo_' + index} className="workOrdersOrdersModal_table">
                <span
                  className="workOrdersOrdersModal_redirectDetails"
                  onClick={() =>
                    handleOnRedirectToWorkOrderDetail(workOrder?.id)
                  }
                >
                  {workOrder?.order_num}
                </span>
                <span>{workOrder?.status_name}</span>
              </div>
            ))}
          </>
        ) : (
          <div className="assignations_list_empty"> No hay órdenes</div>
        )}
      </div>
    </ModalComponent>
  );
};

export default ClientOrdersModal;
