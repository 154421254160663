/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './ActivityStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal';
import MUITextField from '../../../components/inputs/MUITextField';

const ActivityModalView = ({
  activity,
  onInputChange,
  onClose,
  onSave,
  disabled
}) => {
  return (
    <ModalComponent
      open={true}
      title={activity?.id ? 'Editar actividad' : 'Agregar actividad'}
      element={activity}
      onClose={onClose}
      onConfirm={onSave}
      disabled={disabled}
    >
      <div className="activitymodal">
        <MUITextField
          id="name"
          label="Actividad"
          defaultValue={activity?.name}
          type="text"
          name="name"
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default ActivityModalView;
