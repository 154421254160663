/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './AssignationModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal';
import ActiveCell from '../../../components/ActiveCell';
import LoadingComponent from '../../../components/Loading';

const AssignationModalView = ({ assignationData, onDownLoad, onClose }) => {
  const HeaderItem = ({ field = '', data = '' }) => {
    return (
      <div className="assignationInfoModal_header_item">
        <div className="assignationInfoModal_header_item_field">{field}</div>
        <div className="assignationInfoModal_header_item_data">{data}</div>
      </div>
    );
  };

  const DocsList = ({ docsArr = [], title = '' }) => {
    return (
      <div className="assignationInfoModal_docs_list">
        <div className="assignationInfoModal_docs_title">{title}</div>
        {docsArr.map((doc, index) => (
          <div
            key={'doc_' + index}
            className="assignationInfoModal_docs_list_item"
            onClick={() => onDownLoad(doc)}
          >
            <ActiveCell active={doc?.document ? true : false} />
            <div className="assignationInfoModal_docs_list_item_name">
              {doc?.document_type?.name}
            </div>
          </div>
        ))}
      </div>
    );
  };

  if (!assignationData)
    return (
      <ModalComponent
        open={true}
        title="Información de la asignación"
        onClose={onClose}
        cancelText="Cerrar"
      >
        <LoadingComponent />
      </ModalComponent>
    );
  return (
    <ModalComponent
      open={true}
      title="Información de la asignación"
      onClose={onClose}
      cancelText="Cerrar"
    >
      <div className="assignationInfoModal">
        <div className="assignationInfoModal_header">
          <HeaderItem
            field="Cliente"
            data={
              assignationData?.client_title +
              ' / ' +
              assignationData?.company_name
            }
          />
          <HeaderItem
            field="Domicilio"
            data={
              assignationData?.client?.street +
              ' ' +
              assignationData?.client?.ext_number +
              ', ' +
              assignationData?.client?.neighborhood +
              ', ' +
              assignationData?.client?.city
            }
          />
          <HeaderItem
            field="Contacto"
            data={assignationData?.client?.contact_name}
          />
          <HeaderItem field="Telefono" data={assignationData?.client?.phone} />
          <HeaderItem field="Celular" data={assignationData?.cell_phone} />
          <HeaderItem
            field="Comentarios"
            data={assignationData?.client?.observations}
          />
          <HeaderItem field="Fecha inicio" data={assignationData?.start_date} />
          <HeaderItem
            field="Fecha límite"
            data={assignationData?.deadline_date}
          />
          <HeaderItem
            field="Operador asignado"
            data={assignationData?.user_name}
          />
        </div>
        <div className="assignationInfoModal_docs">
          <DocsList
            docsArr={assignationData?.service_documents}
            title="Documentos requeridos"
          />
          <DocsList
            docsArr={assignationData?.service_deliverables}
            title="Documentos entregables"
          />
        </div>
      </div>
    </ModalComponent>
  );
};

export default AssignationModalView;
