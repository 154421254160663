/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';

/** Import syles section **/
import './ClientModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal';
import MUITextField from '../../../components/inputs/MUITextField';
import MUIAutocomplete from '../../../components/inputs/MUIAutocomplete';
import MUISelect from '../../../components/inputs/MUISelect';

const ClientModalView = ({
  client,
  companies,
  states,
  selectedCompany,
  onCompanyChange,
  onAddCompany,
  risks,
  activities,
  onInputChange,
  onClose,
  onSave,
  disabled,
  view
}) => {
  const title =
    view === 'assignations'
      ? 'Cliente'
      : client?.id
      ? 'Editar cliente'
      : 'Agregar cliente';

  const isValid = view === 'assignations' ? true : disabled;

  return (
    <ModalComponent
      open={true}
      title={title}
      element={client}
      onClose={onClose}
      onConfirm={onSave}
      disabled={isValid}
    >
      <div className="clientmodal">
        <div className="clientmodal_company">
          <MUIAutocomplete
            id="company"
            name="company_id"
            options={companies}
            label="Companía"
            selector="legal_name"
            value={selectedCompany}
            onChange={onCompanyChange}
          />
          <AddCircleIcon
            onClick={onAddCompany}
            className="g-row-action-button"
          />
        </div>
      </div>
      <div className="clientmodal_contact-header">
        <div className="clientmodal_contact-header_title">Cliente</div>
        <div className="clientmodal_contact-header_risk">
          <span className="clientmodal_contact-header_risk_label">Riesgo:</span>
          <div className="clientmodal_contact-header_risk_buttons">
            {risks?.map((item, index) => (
              <Button
                className="clientmodal_contact-header_risk_buttons_button"
                variant={item?.id === client?.risk_id ? null : 'outlined'}
                disableElevation
                key={index}
                onClick={(e) =>
                  onInputChange({ name: 'risk_id', value: item?.id })
                }
              >
                {item.title}
              </Button>
            ))}
          </div>
        </div>
      </div>
      <div className="clientmodal_contact">
        {!!client?.id && (
          <MUITextField
            id="id"
            label="Número de cliente"
            defaultValue={client?.id}
            type="text"
            name="id"
            onChange={onInputChange}
            disabled={true}
          />
        )}
        <MUITextField
          id="title"
          label="Título"
          defaultValue={client?.title}
          type="text"
          name="title"
          onChange={onInputChange}
        />
        <MUISelect
          id="activity_id"
          label="Actividad"
          defaultValue={client?.activity_id}
          items={activities}
          idSelector="id"
          selector="name"
          noSelectedLabel="Select"
          name="activity_id"
          onChange={onInputChange}
        />
        <MUITextField
          id="contact_name"
          label="Contacto"
          value={client?.contact_name}
          type="text"
          name="contact_name"
          onChange={onInputChange}
        />
        <MUITextField
          id="phone"
          label="Telefono"
          value={client?.phone}
          type="text"
          name="phone"
          onChange={onInputChange}
        />
        <MUITextField
          id="email"
          label="Email"
          value={client?.email}
          type="text"
          name="email"
          onChange={onInputChange}
        />
        <MUITextField
          id="cell_phone"
          label="Celular"
          value={client?.cell_phone}
          type="text"
          name="cell_phone"
          onChange={onInputChange}
        />
      </div>
      <div className="clientmodal_location">
        <MUISelect
          id="state_id"
          label="Estado"
          defaultValue={client?.state_id}
          items={states}
          idSelector="id"
          selector="name"
          noSelectedLabel="Select"
          name="state_id"
          onChange={onInputChange}
        />
        <MUITextField
          id="municipality"
          label="Municipio"
          defaultValue={client?.municipality}
          type="text"
          name="municipality"
          onChange={onInputChange}
        />
        <MUITextField
          id="city"
          label="Ubicación Maps"
          defaultValue={client?.city}
          type="text"
          name="city"
          onChange={onInputChange}
        />
      </div>
      <div className="clientmodal_contact">
        <MUITextField
          id="street"
          label="Calle"
          defaultValue={client?.street}
          type="text"
          name="street"
          onChange={onInputChange}
        />
        <MUITextField
          id="ext_number"
          label="No. exterior"
          defaultValue={client?.ext_number}
          type="text"
          name="ext_number"
          onChange={onInputChange}
        />
        <MUITextField
          id="reference"
          label="Referencia"
          defaultValue={client?.reference}
          type="text"
          name="reference"
          onChange={onInputChange}
        />
        <MUITextField
          id="int_number"
          label="No. interior"
          defaultValue={client?.int_number}
          type="text"
          name="int_number"
          onChange={onInputChange}
        />
        <MUITextField
          id="neighborhood"
          label="Colonia"
          defaultValue={client?.neighborhood}
          type="text"
          name="neighborhood"
          onChange={onInputChange}
        />
        <MUITextField
          id="postal_code"
          label="Código postal"
          defaultValue={client?.postal_code}
          type="number"
          name="postal_code"
          onChange={onInputChange}
        />
      </div>
      <div className="clientmodal_infrastructure">
        <MUITextField
          id="area_square_meters"
          label="M2 de superficie"
          defaultValue={client?.area_square_meters}
          type="text"
          name="area_square_meters"
          onChange={onInputChange}
        />
        <MUITextField
          id="structure_square_meters"
          label="M2 de construcción"
          defaultValue={client?.structure_square_meters}
          type="text"
          name="structure_square_meters"
          onChange={onInputChange}
        />
        <MUITextField
          id="employees_number"
          label="No. de empleados"
          defaultValue={client?.employees_number}
          type="text"
          name="employees_number"
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default ClientModalView;
