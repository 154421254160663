import request from '../api-connector';

export const getWorkOrderStatusService = () => {
  const serviceDef = {
    url: 'work-order-status/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const getWorkOrdersService = (workOrderStatus) => {
  const serviceDef = {
    url: 'work-order/list',
    method: 'get',
    params: workOrderStatus
  };
  return request(serviceDef);
};

export const addWorkOrderService = ({
  client_id,
  responsible_id,
  work_order_status_id,
  proposal,
  order_num,
  comments
}) => {
  const serviceDef = {
    url: 'work-order/create',
    method: 'post',
    params: {
      client_id,
      responsible_id,
      work_order_status_id,
      proposal,
      order_num,
      comments
    }
  };
  return request(serviceDef);
};

export const updateStatusWorkOrderService = ({ id, work_order_status_id }) => {
  const serviceDef = {
    url: 'work-order/status/change',
    method: 'post',
    params: { id, work_order_status_id }
  };
  return request(serviceDef);
};
export const updateWorkOrderService = ({
  id,
  client_id,
  responsible_id,
  work_order_status_id,
  proposal,
  order_num,
  comments
}) => {
  const serviceDef = {
    url: 'work-order/update',
    method: 'post',
    params: {
      id,
      client_id,
      responsible_id,
      work_order_status_id,
      proposal,
      order_num,
      comments
    }
  };
  return request(serviceDef);
};

export const deleteWorkOrderService = ({ id }) => {
  const serviceDef = {
    url: 'work-order/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const readWorkOrderService = ({ id }) => {
  const serviceDef = {
    url: 'work-order/read',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const workOrderPDFService = ({ id }) => {
  const serviceDef = {
    url: 'work-order/pdf',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};
