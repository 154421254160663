/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

/** Import helpers section **/
import './ActivitiesStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/FilterBy';
import DataTable from '../../components/DataTable';
const ActivitiesView = ({
  loading,
  activities,
  onFilterBy,
  onAddActivity,
  onEditActivity,
  onDeleteActivity
}) => {
  const ActionsCell = ({ row }) => {
    return (
      <div className="g-actions-cell">
        <EditIcon
          className="g-row-action-button"
          onClick={() => onEditActivity(row)}
        />
        <Divider orientation="vertical" flexItem />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => onDeleteActivity(row)}
        />
      </div>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Actividad',
        selector: 'name',
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        grow: 0
      }
    ],
    []
  );

  return (
    <>
      <PageTitle title="Actividades" />
      <Paper className="g-page-header activities_header">
        <div className="activities_header_filterby">
          <FilterBy onChange={onFilterBy} />
        </div>
        <Button onClick={onAddActivity} startIcon={<AddCircleIcon />}>
          Agregar nueva actividad
        </Button>
      </Paper>

      <DataTable data={activities} columns={tableColumns} loading={loading} />
    </>
  );
};

export default ActivitiesView;
