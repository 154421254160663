/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

/** Import helpers section **/
import { toast } from 'react-toastify';
import {
  SERVICE_REQUIREMENTS_ROUTE,
  SERVICE_DELIVERABLES_ROUTE
} from '../../config/URL_ROUTES';

/** Import helpers section **/
import { readServiceService } from '../../api/services/services-services';
import {
  getRequiredDocumentsService,
  updateRequiredDocumentsService,
  getDeliverableDocumentsService,
  updateDeliverableDocumentsService
} from '../../api/services/service-documents-services';

/** Import component section **/
import ServiceDocumentsView from './ServiceDocumentsView';

const REQUIREMENTS = 'Requisitos';
const DELIVERABLES = 'Entregables';

const ServiceDocumentsComponent = () => {
  let history = useHistory();
  let pathname = history.location.pathname;
  let mode = pathname.includes(SERVICE_REQUIREMENTS_ROUTE)
    ? REQUIREMENTS
    : pathname.includes(SERVICE_DELIVERABLES_ROUTE)
    ? DELIVERABLES
    : '';
  let params = useParams();
  let serviceId = params['id'];

  const [loading, setLoading] = useState(false);
  const [serviceTitle, setServiceTitle] = useState(mode);
  const [docs, setDocs] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [showChecked, setShowChecked] = useState(false);
  const [filteringText, setFilteringText] = useState('');

  const readService = useCallback(() => {
    readServiceService({ id: serviceId })
      .then((response) => {
        var title = '';
        if (response?.title) title = mode + ' del servicio ' + response?.title;
        setServiceTitle(title);
      })
      .catch((error) => {
        toast.error('No se pudo leer el servicio');
      });
  }, [mode, serviceId]);

  const previousSelectedLength = (docsArr) => {
    const prevSelectedDocs = docsArr.filter((item) => item.added);
    return prevSelectedDocs.length > 0;
  };

  const getRequiredDocuments = useCallback(() => {
    setLoading(true);
    getRequiredDocumentsService({ service_id: serviceId })
      .then((response) => {
        setDocs(response);
        setOriginalData(response);
        setShowChecked(previousSelectedLength(response));
      })
      .catch((error) => {
        toast.error('No se pudieron leer los documentos');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [serviceId]);

  const getDeliverableDocuments = useCallback(() => {
    setLoading(true);
    getDeliverableDocumentsService({ service_id: serviceId })
      .then((response) => {
        setDocs(response);
        setOriginalData(response);
        setShowChecked(previousSelectedLength(response));
      })
      .catch((error) => {
        toast.error('No se pudieron leer los documentos');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [serviceId]);

  const getDocuments = useCallback(
    (serviceId) => {
      if (mode === 'Requisitos') {
        getRequiredDocuments();
      } else if (mode === 'Entregables') {
        getDeliverableDocuments();
      }
    },
    [mode, getRequiredDocuments, getDeliverableDocuments]
  );

  useEffect(() => {
    if (mode && serviceId) readService();
  }, [mode, serviceId, readService]);

  useEffect(() => {
    getDocuments();
  }, [serviceTitle, getDocuments]);

  const handleOnSave = () => {
    if (mode === 'Requisitos') {
      updateRequiredDocuments();
    } else if (mode === 'Entregables') {
      updateDeliverableDocuments();
    }
  };

  const selectedDocs = useCallback(() => {
    return originalData.filter((item) => item.added);
  }, [originalData]);

  const updateRequiredDocuments = () => {
    const params = {
      service_id: serviceId,
      documents: selectedDocs()
    };
    updateRequiredDocumentsService(params)
      .then((response) => {
        toast.success('Documentos actualizados');
      })
      .catch((error) => {
        toast.error('Error al actualizar documentos');
      });
  };

  const updateDeliverableDocuments = () => {
    const params = {
      service_id: serviceId,
      documents: selectedDocs()
    };
    updateDeliverableDocumentsService(params)
      .then((response) => {
        toast.success('Documentos actualizados');
      })
      .catch((error) => {
        toast.error('Error al actualizar documentos');
      });
  };

  const handleOnSelectedChange = (state) => {
    // { allSelected, selectedCount, selectedRows }
    const selectedRows = state.selectedRows;
    if (originalData.length > 0) {
      const cloneArr = originalData.map((doc) => {
        // solo setea added si sí está seleccionado, si no lo deja como estaba
        // esto evita borrar seleccionados al estar filtrado
        const itemIsShowed = docs.find((item) => item.id === doc.id);
        doc.added = selectedRows.find((item) => item.id === doc.id)
          ? true
          : itemIsShowed
          ? false
          : doc.added;
        return doc;
      });
      setOriginalData(cloneArr);
    }
  };

  const handleOnFiltering = useCallback(() => {
    var filteredItems = [...originalData];
    if (showChecked) {
      filteredItems = filteredItems.filter((item) => item.added);
    }

    if (filteringText.length > 0) {
      const filteringTextLC = filteringText.toLowerCase();
      filteredItems = filteredItems.filter((item) => {
        const field1 = item?.name.toLowerCase();
        return field1.includes(filteringTextLC);
      });
    }

    setDocs(filteredItems);
  }, [showChecked, filteringText, originalData]);

  const handleOnSwitchChange = (event) => {
    setShowChecked(event?.target?.checked);
  };

  const handleOnFilterBy = (text) => {
    setFilteringText(text);
  };

  useEffect(() => {
    handleOnFiltering();
  }, [showChecked, filteringText, handleOnFiltering]);

  return (
    <ServiceDocumentsView
      title={serviceTitle}
      docs={docs}
      mode={mode}
      loading={loading}
      showChecked={showChecked}
      onFilterBy={handleOnFilterBy}
      onSwitchChange={handleOnSwitchChange}
      onSelectedChange={handleOnSelectedChange}
      onSave={handleOnSave}
    />
  );
};

export default ServiceDocumentsComponent;
