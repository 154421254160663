import request from '../api-connector';

export const getDashboardService = ({ client_id, user_id }) => {
  const serviceDef = {
    url: 'dashboard/list',
    method: 'get',
    params: { client_id, user_id }
  };
  return request(serviceDef);
};
export const getClienStatusService = () => {
  const serviceDef = {
    url: 'dashboard/client/status',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};
