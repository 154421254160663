/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './CompanyModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal';
import MUITextField from '../../../components/inputs/MUITextField';

const CompanyModalView = ({
  company,
  onInputChange,
  onClose,
  onSave,
  disabled
}) => {
  return (
    <ModalComponent
      open={true}
      title={company?.id ? 'Editar compañía' : 'Agregar compañía'}
      element={company}
      onClose={onClose}
      onConfirm={onSave}
      disabled={disabled}
    >
      <div className="companymodal">
        <MUITextField
          id="name"
          label="Denominación comercial"
          defaultValue={company?.name}
          type="text"
          name="name"
          onChange={onInputChange}
        />
        <MUITextField
          id="federal_register"
          label="RFC"
          defaultValue={company?.federal_register}
          type="text"
          name="federal_register"
          onChange={onInputChange}
        />
        <MUITextField
          id="legal_name"
          label="Razon social"
          defaultValue={company?.legal_name}
          type="text"
          name="legal_name"
          onChange={onInputChange}
        />
        <MUITextField
          id="legal_representative"
          label="Representante legal"
          defaultValue={company?.legal_representative}
          type="text"
          name="legal_representative"
          onChange={onInputChange}
        />
      </div>
      <div className="contact_form">
        <MUITextField
          id="contact_name"
          label="Contacto"
          defaultValue={company?.contact_name}
          type="text"
          name="contact_name"
          onChange={onInputChange}
        />
        <MUITextField
          id="phone"
          label="Telefono"
          defaultValue={company?.phone}
          type="text"
          name="phone"
          onChange={onInputChange}
        />
        <MUITextField
          id="email"
          label="Email"
          defaultValue={company?.email}
          type="text"
          name="email"
          onChange={onInputChange}
        />
        <MUITextField
          id="cell_phone"
          label="Celular"
          defaultValue={company?.cell_phone}
          type="text"
          name="cell_phone"
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default CompanyModalView;
