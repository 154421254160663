/** Import react/libraries section **/
import Button from '@material-ui/core/Button';
/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import React, { useMemo } from 'react';
import DataTable from '../../components/DataTable';
import MUICheckBox from '../../components/inputs/MUICheckBox';
import MUISelect from '../../components/inputs/MUISelect';
/** Import component section **/
import PageTitle from '../../components/PageTitle';
/** Import helpers section **/
import './RolePermissionsStyles.scss';

const RolePermissionsView = ({
  loading,
  permissions,
  roles,
  selectedRole,
  onRoleChange,
  onSelectedChange,
  onSave
}) => {
  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Código',
        selector: 'code',
        sortable: true,
        center: true
      },
      {
        name: 'Tipo',
        selector: 'type_name',
        sortable: true,
        center: true
      }
    ],
    []
  );

  return (
    <>
      <PageTitle title="Permisos por rol" />
      <Paper className="g-page-header rolePermissions_header">
        <div className="rolePermissions_header_select">
          <MUISelect
            label="Rol"
            defaultValue={selectedRole}
            items={roles}
            idSelector="id"
            selector="title"
            noSelectedLabel="Select"
            name="role_id"
            onChange={onRoleChange}
          />
        </div>
        <Button onClick={onSave} type="submit">
          {'Guardar permisos'}
        </Button>
      </Paper>
      <DataTable
        data={permissions}
        columns={tableColumns}
        loading={loading}
        selectableRows={true}
        selectableRowsComponent={MUICheckBox}
        selectableRowsNoSelectAll={false}
        selectableRowsHighlight={true}
        onSelectedRowsChange={onSelectedChange}
        selectableRowSelected={(row) => row.selected}
      />
    </>
  );
};

export default RolePermissionsView;
