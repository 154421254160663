/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { WORK_ORDER_SERVICES_ROUTE } from '../../../config/URL_ROUTES';

/** Import helpers section **/
import {
  addWorkOrderService,
  updateWorkOrderService
} from '../../../api/services/work-orders-services';

/** Import component section **/
import WorkOrderModalView from './WorkOrderModalView';

const WorkOrderModalComponent = ({
  workOrderData,
  clients,
  responsibles,
  status,
  onClose,
  onUpdateList
}) => {
  const [workOrder, setWorkOrder] = useState(workOrderData);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedResponsible, setSelectedResponsible] = useState(null);
  const [dataComplete, setDataComplete] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const newWorkOrder = Object.assign({}, workOrderData);
    const clientFound = clients.find(
      (item) => item.id === workOrderData?.client_id
    );
    if (clientFound) setSelectedClient(clientFound);

    const responsibleFound = responsibles.find(
      (item) => item.id === workOrderData?.responsible_id
    );
    if (responsibleFound) setSelectedResponsible(responsibleFound);
    setWorkOrder(newWorkOrder);
  }, [workOrderData, clients, responsibles]);

  const handleOnInputChange = (data) => {
    const { name, value } = data;
    setWorkOrder((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnClientChange = (data) => {
    setSelectedClient(data);
    let clientId = data?.id ? data.id : null;
    setWorkOrder((prevState) => ({
      ...prevState,
      client_id: clientId
    }));
  };

  const handleOnResponsibleChange = (data) => {
    setSelectedResponsible(data);
    let responsibleId = data?.id ? data.id : null;
    setWorkOrder((prevState) => ({
      ...prevState,
      responsible_id: responsibleId
    }));
  };

  useEffect(() => {
    if (!workOrder) return;
    let validated = false;

    const clientId = workOrder?.client_id?.toString().trim();
    const statusId = workOrder?.work_order_status_id;

    if (clientId && statusId) {
      validated = true;
    }
    setDataComplete(validated);
  }, [workOrder]);

  const handleOnSave = () => {
    if (workOrder.id) {
      updateWorkOrder();
    } else {
      createWorkOrder();
    }
  };

  const createWorkOrder = () => {
    addWorkOrderService(workOrder)
      .then((response) => {
        toast.success('Orden de trabajo creada');
        onUpdateList();
        onClose();
        history.push({
          pathname: WORK_ORDER_SERVICES_ROUTE + '/' + response?.id
        });
      })
      .catch((error) => {
        toast.warning('Error en la creación de orden de trabajo');
      });
  };

  const updateWorkOrder = () => {
    updateWorkOrderService(workOrder)
      .then(() => {
        toast.success('Orden de trabajo actualizada');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.warning('Error al actualizar la orden de trabajo');
      });
  };

  return (
    <>
      <WorkOrderModalView
        workOrder={workOrder}
        clients={clients}
        responsibles={responsibles}
        selectedClient={selectedClient}
        selectedResponsible={selectedResponsible}
        onClientChange={handleOnClientChange}
        onResponsibleChange={handleOnResponsibleChange}
        status={status}
        onInputChange={handleOnInputChange}
        onClose={onClose}
        onSave={handleOnSave}
        disabled={!dataComplete}
      />
    </>
  );
};

export default WorkOrderModalComponent;
