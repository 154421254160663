// System colors
export const color_primary = '#b20839';
export const color_secondary = '#998c7c';
export const color_complementary = '#f5f7f8';
export const color_complementary_2 = '#ced6de';
export const color_complementary_3 = '#687d95';
export const color_complementary_4 = '#475b7a';
export const color_light = '#f4f4f4';
export const color_white = '#ffffff';
export const color_dark = '#343434';
export const color_medium = '#bdbdbd';
export const color_normal = '#707070';
export const color_disabled = '#e4e4e4';
export const color_cancel = '#adadad';
export const color_delete = '#fa6347';
export const color_check = '#07b31c';
export const color_check_light = '#8ad48c';
