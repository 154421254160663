/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import FileCopyIcon from '@material-ui/icons/FileCopy';

/** Import helpers section **/
import './ServicesStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/FilterBy';
import ActiveCell from '../../components/ActiveCell';
import DataTable from '../../components/DataTable';

const ServicesView = ({
  loading,
  categories,
  services,
  onFilterServices,
  onAddService,
  onEditService,
  onInputDocs,
  onOutputDocs,
  onDeleteService,
  onActiveService
}) => {
  const ActionsCell = ({ row }) => {
    return (
      <div className="g-actions-cell">
        <EditIcon
          className="g-row-action-button"
          onClick={() => onEditService(row)}
        />
        <Divider orientation="vertical" flexItem />
        <Tooltip title="Requisitos" aria-label="Requisitos">
          <AssignmentTurnedInIcon
            className="g-row-action-button secondary"
            onClick={() => onInputDocs(row)}
          />
        </Tooltip>
        <Tooltip title="Entregables" aria-label="Entregables">
          <FileCopyIcon
            className="g-row-action-button complementary"
            onClick={() => onOutputDocs(row)}
          />
        </Tooltip>
        <Divider orientation="vertical" flexItem />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => onDeleteService(row)}
        />
      </div>
    );
  };

  const getCategoryName = (row) => {
    const categoryId = row?.service_category_id;
    const catFound = categories.find((category) => category.id === categoryId);
    return catFound ? catFound.title : '';
  };

  const tableColumns = [
    {
      name: 'Id',
      selector: 'id',
      sortable: true,
      center: true,
      omit: true
    },
    {
      name: 'Servicio',
      selector: 'title',
      wrap: true,
      center: true,
      grow: 2,
      style: { justifyContent: 'left' }
    },
    {
      name: 'Categoría',
      selector: (row) => getCategoryName(row),
      sortable: true,
      center: true,
      grow: 0.5,
      style: { justifyContent: 'left' }
    },
    {
      name: 'Descripción',
      selector: 'description',
      grow: 2,
      wrap: true,
      center: true,
      style: { justifyContent: 'left' }
    },
    {
      name: 'Tiempo máximo',
      selector: 'max_time',
      grow: 0.5,
      sortable: true,
      center: true
    },
    {
      name: 'Activo',
      selector: 'active',
      cell: (row) => (
        <ActiveCell
          active={row.active}
          onSwitchActive={() => onActiveService(row)}
        />
      ),
      sortable: true,
      center: true,
      width: '100px'
    },
    {
      name: '',
      cell: (row) => <ActionsCell row={row} />,
      ignoreRowClick: true,
      right: true,
      width: '160px'
    }
  ];

  return (
    <>
      <PageTitle title="Servicios" />
      <Paper className="g-page-header services_header">
        <div className="services_header_filterby">
          <FilterBy onChange={onFilterServices} />
        </div>
        <Button onClick={onAddService} startIcon={<AddCircleIcon />}>
          Agregar nuevo servicio
        </Button>
      </Paper>

      <DataTable data={services} columns={tableColumns} loading={loading} />
    </>
  );
};

export default ServicesView;
