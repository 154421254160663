/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/
import SendIcon from '@material-ui/icons/Send';

/** Import syles section **/
import './MessageModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal';
import MUITextField from '../../../components/inputs/MUITextField';

const MessagesModalView = ({
  messages,
  messageText,
  onTextChange,
  onSendMessage,
  onClose
}) => {
  return (
    <ModalComponent
      open={true}
      title={'Mensajes'}
      onClose={onClose}
      cancelText="Cerrar"
    >
      <div className="messagesModal">
        <div className="messagesModal_send">
          <MUITextField
            id="message"
            label="Mensaje"
            value={messageText}
            type="text"
            name="message"
            onChange={onTextChange}
          />
          <SendIcon
            className="messagesModal_send_button"
            onClick={onSendMessage}
          />
        </div>
        <div className="messagesModal_container">
          {messages.map((item, index) => (
            <div
              key={'message_' + index}
              className={
                'messagesModal_container_message' + (item?.mine ? ' mine' : '')
              }
            >
              <div
                className={
                  'messagesModal_container_message_text' +
                  (item?.mine ? ' mine' : '')
                }
              >
                {item.text_message}
              </div>
              <div
                className={
                  'messagesModal_container_message_info' +
                  (item?.mine ? ' mine' : '')
                }
              >
                {item?.user?.last_name + ', ' + item?.user?.first_name}{' '}
                {item?.date}
              </div>
            </div>
          ))}
        </div>
      </div>
    </ModalComponent>
  );
};

export default MessagesModalView;
