/** Import react/libraries section **/
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

/** Import resources section **/
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import logo from '../../assets/espe_logo.png';

/** Import styles section **/
import './LoginStyles.scss';

/** Import helpers section **/
import { CHANGE_PASSWORD_REQUEST_ROUTE } from '../../config/URL_ROUTES';

/** Import component section **/

const LoginView = ({ username, password, onSubmit, inProcess }) => {
  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange'
  });

  const handleOnSubmit = (data) => {
    onSubmit(data);
  };

  return (
    <div className="login">
      <Paper className="login_container">
        <div className="login_container_access">
          <form
            className="login_container_access_form"
            onSubmit={handleSubmit(handleOnSubmit)}
          >
            <TextField
              label="Email"
              id="username"
              name="username"
              type="text"
              variant="outlined"
              {...register('username', {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
              })}
            />

            <TextField
              label="Contraseña"
              id="password"
              name="password"
              type="password"
              variant="outlined"
              {...register('password', {
                required: true,
                message: 'Contraseña requerida'
              })}
            />

            <div className="login_container_access_form_button">
              <Button
                onClick={handleSubmit}
                type="submit"
                disabled={!formState.isValid}
              >
                {inProcess === true ? 'Accediendo...' : 'Ingresar'}
              </Button>
            </div>
          </form>
        </div>

        <div className="login_container_title">
          <img
            src={logo}
            alt="ms-logo"
            className="login_container_title_logo"
          />
          <div className="login_container_title_text">
            Sistema de control de órdenes
            <span className="login_container_title_text_divider" /> v1.0
          </div>
        </div>
        <div className="login_container_footer">
          <div className="login_container_footer_recovery">
            Olvidaste tu contraseña?{' '}
            <Link
              className="login_container_footer-link"
              to={CHANGE_PASSWORD_REQUEST_ROUTE}
            >
              Presiona aquí
            </Link>
          </div>
          <Link className="login_container_footer-link" to="/registration">
            Registrarse
          </Link>
        </div>
      </Paper>
    </div>
  );
};

export default LoginView;
