/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { ReactComponent as DriveIcon } from '../../assets/drive_icon.svg';

/** Import helpers section **/
import './ClientsStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/FilterBy';
import DataTable from '../../components/DataTable';

const ClientsView = ({
  loading,
  clients,
  onFilterBy,
  onAddClient,
  onEditClient,
  onClientWorkOrders,
  onClientDocs,
  onDeleteClient,
  onAddWorkOrder,
  onFolderDrive
}) => {
  const ActionsCell = ({ row }) => {
    return (
      <div className="g-actions-cell">
        {!!row?.id && (
          <Tooltip
            title="Ir a la carpeta de Google Drive"
            aria-label="Ir a la carpeta de Google Drive"
          >
            <DriveIcon
              className="g-row-action-button complementary"
              onClick={() => onFolderDrive(row)}
            />
          </Tooltip>
        )}
        <EditIcon
          className="g-row-action-button"
          onClick={() => onEditClient(row)}
        />
        <Tooltip
          title="Info Ordenes del cliente"
          aria-label="Info ordenes del cliente"
        >
          <InfoIcon
            className="g-row-action-button complementary"
            onClick={() => onClientWorkOrders(row)}
          />
        </Tooltip>
        <Tooltip title="Agregar órden de trabajo" aria-label="órden de trabajo">
          <BusinessCenterIcon
            className="g-row-action-button"
            onClick={() => onAddWorkOrder(row)}
          />
        </Tooltip>
        <Divider orientation="vertical" flexItem />
        <Tooltip title="Documentos" aria-label="Documentos">
          <FileCopyIcon
            className="g-row-action-button complementary"
            onClick={() => onClientDocs(row)}
          />
        </Tooltip>
        <Divider orientation="vertical" flexItem />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => onDeleteClient(row)}
        />
      </div>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Número de cliente',
        selector: 'id',
        sortable: true,
        center: true,
        width: '80px'
      },
      {
        name: 'Cliente',
        selector: (row) => row?.title,
        sortable: true,
        center: true,
        wrap: true,
        style: { justifyContent: 'left' }
      },
      {
        name: 'Compañía',
        selector: (row) => row?.company?.name,
        sortable: true,
        center: true,
        wrap: true,
        style: { justifyContent: 'left' }
      },
      {
        name: 'Actividad / Giro',
        selector: (row) => row?.activity?.name,
        sortable: true,
        center: true,
        wrap: true,
        style: { justifyContent: 'left' }
      },
      {
        name: 'Riesgo',
        selector: (row) => row?.risk?.title,
        sortable: true,
        center: true,
        width: '50px'
      },
      {
        name: 'Contacto',
        selector: 'contact_name',
        sortable: true,
        center: true
      },
      {
        name: 'Email',
        selector: 'email',
        sortable: true,
        center: true,
        wrap: true,
        style: { justifyContent: 'left' }
      },
      {
        name: 'Teléfono',
        selector: 'phone',
        sortable: true,
        center: true
      },
      {
        name: 'Celular',
        selector: 'cell_phone',
        sortable: true,
        center: true
      },

      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        width: '240px',
        right: true
      }
    ],
    []
  );

  return (
    <>
      <PageTitle title="Clientes" />
      <Paper className="g-page-header clients_header">
        <div className="clients_header_filterby">
          <FilterBy onChange={onFilterBy} />
        </div>
        <Button onClick={onAddClient} startIcon={<AddCircleIcon />}>
          Agregar nuevo cliente
        </Button>
      </Paper>

      <DataTable data={clients} columns={tableColumns} loading={loading} />
    </>
  );
};

export default ClientsView;
