/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/
import {
  addClientDocumentService,
  editClientDocumentService
} from '../../../api/services/client-documents-services';

/** Import component section **/
import ClientDocumentModalView from './ClientDocumentModalView';

const ClientDocumentModalComponent = ({
  isClientAccess,
  document,
  documentTypes,
  clientId,
  onClose,
  onUpdateList
}) => {
  const [documentData, setDocumentData] = useState(document);
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const [dataComplete, setDataComplete] = useState(false);
  useEffect(() => {
    setDocumentData(document);
  }, [document]);

  const handleOnInputChange = (data) => {
    const { name, value } = data;
    setDocumentData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleOnFileUploaded = (files = []) => {
    setIsFileLoaded(files.length > 0 ? true : false);
  };

  useEffect(() => {
    let validated = false;

    const document_type_id = documentData?.document_type_id;

    if ((document?.document || isFileLoaded) && document_type_id) {
      validated = true;
    }

    setDataComplete(validated);
  }, [documentData, isFileLoaded, document?.document]);

  const handleOnSave = (files) => {
    documentData.id ? handleOnEdit(files) : handleOnCreate(files);
  };

  const handleOnCreate = (files) => {
    const data = {
      file: files[0],
      client_id: clientId,
      document_type_id: documentData.document_type_id,
      document_date: documentData.document_date,
      validity_date: documentData.validity_date
    };
    addClientDocumentService(data)
      .then((response) => {
        toast.success('Documento agregado');
        onUpdateList(response);
      })
      .catch((error) => {
        toast.error('No se pudo agregar el documento');
      });
  };
  const handleOnEdit = (files) => {
    const data = {
      id: documentData.id,
      document_date: documentData.document_date,
      document_type_id: documentData.document_type_id,
      validity_date: documentData.validity_date,
      folio: documentData.folio,
      file: files[0]
    };
    editClientDocumentService(data)
      .then((response) => {
        toast.success('Documento editado');
        onUpdateList(response);
      })
      .catch((error) => {
        toast.error('No se pudo editar el documento');
      });
  };

  return (
    <ClientDocumentModalView
      isClientAccess={isClientAccess}
      document={documentData}
      documentTypes={documentTypes}
      onInputChange={handleOnInputChange}
      onFileUploaded={handleOnFileUploaded}
      onClose={onClose}
      onSave={handleOnSave}
      disabled={!dataComplete}
    />
  );
};

export default ClientDocumentModalComponent;
