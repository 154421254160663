/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';

/** Import component section **/

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    padding: '20px 40px 20px 20px',
    width: '220px'
  }
}));

export const SpinnerModalComponent = (open) => {
  const classes = useStyles();
  return (
    <Modal open={open} className={classes.modal}>
      <div className={classes.paper}>
        <CircularProgress disableShrink />
        Descargando orden...
      </div>
    </Modal>
  );
};

export default SpinnerModalComponent;
