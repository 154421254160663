/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import services section **/

import {
  addClientDocumentService,
  // deleteClientDocumentService,
  getClientDocumentsStorageService
} from '../../api/services/client-documents-services';
import ModalComponent from '../../components/Modal/ModalComponent';

/** Import resources section **/

/** Import component section **/
import ClientDocumentModal from './ClientDocumentModal';
import ClientDocumentStorageView from './ClientDocumentStorageView';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const FILES_URL_BASE = process.env.REACT_APP_BACK_URL + 'storage/documents/';

const documentModel = {
  id: null,
  client_id: null,
  file: null,
  url: null,
  document_type_id: null,
  document_date: null,
  validity_date: null
};

const ClientDocumentStorageComponent = () => {
  const [documents, setDocuments] = useState([]);
  const [modalMode, setModalMode] = useState('');
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [loading, setLoading] = useState(false);

  const clientId = useLocation().state?.clientId || null;

  const getDocuments = useCallback(() => {
    setLoading(true);
    getClientDocumentsStorageService(clientId)
      .then((response) => {
        const docs = response?.services_documents;
        const url = response?.client_deliverables_url;
        if (docs) setDocuments(docs);
      })
      .catch((error) => {
        toast.error('No se pudieron leer los documentos');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [clientId]);

  useEffect(() => {
    getDocuments();
  }, [getDocuments]);

  const handleOnUpdateList = () => {
    getDocuments();
    handleOnModalClose();
  };

  const handleOnEditDocument = (row) => {
    let item = Object.assign(documentModel, row?.document);
    setSelectedDocument(item);
    setModalMode('add');
  };

  const handleOnUpdateDocument = (row) => {
    let item = Object.assign(row);
    setSelectedDocument(item);
    setModalMode('update');
  };

  const handleOnModalClose = () => {
    setSelectedDocument(null);
  };

  const handleOnDownLoad = (row) => {
    const url = row?.document?.url;
    if (url) window.open(FILES_URL_BASE + url, 'doc');
  };

  const handleOnUploadDocument = (row) => {
    setModalMode('uploaded-confirm');
    setSelectedDocument(row);
    console.log(row);
  };

  const handleOnConfirmUploadDocument = () => {
    console.log(clientId);
    const data = {
      client_id: clientId,
      document_type_id: selectedDocument.document_type_id
    };
    addClientDocumentService(data)
      .then((response) => {
        toast.success('Documento agregado');
        getDocuments();
      })
      .catch((error) => {
        toast.error('No se pudo agregar el documento');
      });
  };
  return (
    <>
      <ClientDocumentStorageView
        loading={loading}
        documents={documents}
        onDownLoad={handleOnDownLoad}
        onEditDocument={handleOnEditDocument}
        onUpdateDocument={handleOnUpdateDocument}
        onUploadDocument={handleOnUploadDocument}
      />

      {selectedDocument && modalMode === 'uploaded-confirm' && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnConfirmUploadDocument}
          title={
            selectedDocument?.added === false
              ? 'Confirmar entrega'
              : 'Cancelar entrega'
          }
        >
          {'¿Estas seguro que quieres ' +
            (selectedDocument?.added === false ? 'confirmar' : 'cancelar') +
            ' la entrega del documento ' +
            selectedDocument.title +
            '?'}
        </ModalComponent>
      )}
      {selectedDocument && (modalMode === 'add' || modalMode === 'update') && (
        <ClientDocumentModal
          document={selectedDocument}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
    </>
  );
};

export default ClientDocumentStorageComponent;
