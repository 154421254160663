/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/
import {
  addServiceService,
  updateServiceService
} from '../../../api/services/services-services';

/** Import component section **/
import ServiceModalView from './ServiceModalView';

const ServiceModalComponent = ({
  serviceData,
  categories,
  onClose,
  onUpdateList
}) => {
  const [service, setService] = useState(null);
  const [dataComplete, setDataComplete] = useState(null);

  useEffect(() => {
    const newService = Object.assign(serviceData);
    setService(newService);
  }, [serviceData]);

  const onInputChange = (data, field) => {
    const { name, value } = data;
    setService((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const onSwitchChange = (event) => {
    let value = event.target.checked === false ? 0 : 1;
    setService((prevState) => ({
      ...prevState,
      active: value
    }));
  };

  useEffect(() => {
    if (!service) return;
    let validated = true;

    const title = service?.title?.trim();
    const description = service?.description?.trim();
    const categoryId = service?.service_category_id;
    if (title === '' || description === '' || !categoryId) {
      validated = false;
    }

    setDataComplete(validated);
  }, [service]);

  const handleOnSave = () => {
    if (service.id) {
      updateService();
    } else {
      createService();
    }
  };

  const createService = () => {
    addServiceService(service)
      .then((response) => {
        toast.success('Servicio creado');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('Error al crear servicio');
      });
  };

  const updateService = () => {
    updateServiceService(service)
      .then(() => {
        toast.success('Servicio actualizado');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('Error al actualizar servicio');
      });
  };

  return (
    <ServiceModalView
      service={service}
      categories={categories}
      onInputChange={onInputChange}
      onSwitchChange={onSwitchChange}
      onClose={onClose}
      onSave={handleOnSave}
      disabled={!dataComplete}
    />
  );
};

export default ServiceModalComponent;
