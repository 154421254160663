/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

/** Import helpers section **/
import './DocumentsStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/FilterBy';
import MUISelect from '../../components/inputs/MUISelect';
import ActiveCell from '../../components/ActiveCell';
import DataTable from '../../components/DataTable';

const DocumentsView = ({
  loading,
  documents,
  categories,
  selectedCategory,
  onFilterDocuments,
  onCategoryChange,
  onAddDocument,
  onEditDocument,
  onDeleteDocument,
  onActiveDocument
}) => {
  const ActionsCell = ({ row }) => {
    return (
      <div className="g-actions-cell">
        <EditIcon
          className="g-row-action-button"
          onClick={() => onEditDocument(row)}
        />
        <Divider orientation="vertical" flexItem />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => onDeleteDocument(row)}
        />
      </div>
    );
  };

  const tableColumns = [
    {
      name: 'Documento',
      selector: 'name',
      sortable: true,
      wrap: true,
      style: { justifyContent: 'left' }
    },
    {
      name: 'Descripción',
      selector: 'description',
      sortable: true,
      wrap: true,
      style: { justifyContent: 'left' }
    },
    {
      name: 'Vigencia',
      selector: (row) => row?.document_validity?.name,
      sortable: true,
      center: true
    },
    {
      name: 'Categoría',
      selector: (row) => row?.document_category?.name,
      sortable: true,
      center: true
    },
    {
      name: 'Activo',
      selector: 'active',
      cell: (row) => (
        <ActiveCell
          active={row.active}
          onSwitchActive={() => onActiveDocument(row)}
        />
      ),
      sortable: true,
      center: true
    },
    {
      name: '',
      cell: (row) => <ActionsCell row={row} />,
      ignoreRowClick: true,
      width: '90px',
      right: true
    }
  ];

  return (
    <>
      <PageTitle title="Tipo de documentos" />
      <Paper className="g-page-header documentsCatalogue_header">
        <div className="documentsCatalogue_header_filters">
          <MUISelect
            label="Categoría"
            defaultValue={selectedCategory}
            items={categories}
            idSelector="id"
            selector="name"
            noSelectedLabel="Select"
            name="document_category_id"
            onChange={onCategoryChange}
          />
          <div className="documentsCatalogue_header_filters_filterby">
            <FilterBy onChange={onFilterDocuments} />
          </div>
        </div>
        <Button onClick={onAddDocument} startIcon={<AddCircleIcon />}>
          Agregar nuevo tipo de documento
        </Button>
      </Paper>

      <DataTable data={documents} columns={tableColumns} loading={loading} />
    </>
  );
};

export default DocumentsView;
