import request from '../api-connector';

export const getMessagesService = ({ work_order_id, text_message }) => {
  const serviceDef = {
    url: 'message/list',
    method: 'get',
    params: { work_order_id, text_message }
  };
  return request(serviceDef);
};

export const addMessageService = ({ work_order_id, text_message }) => {
  const serviceDef = {
    url: 'message/add',
    method: 'post',
    params: { work_order_id, text_message }
  };
  return request(serviceDef);
};
