/** Import react/libraries section **/
import React from 'react';
import PropTypes from 'prop-types';

/** Import resources section **/
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

/** Import styles section **/

/** Import helpers section **/
import { color_medium, color_check } from '../../styles/colors';

/** Import component section **/

const iconStyle = {
  color: color_medium,
  width: '25px',
  height: '25px'
};

const activeIconStyle = {
  color: color_check
};

const actionIconStyle = {
  cursor: ' pointer'
};

export const ActiveCell = ({ active = false, onSwitchActive }) => {
  const handleOnSwitchActive = () => {
    if (onSwitchActive) onSwitchActive();
  };
  const actionStyle = onSwitchActive ? actionIconStyle : {};

  if (active)
    return (
      <CheckCircleIcon
        style={{ ...iconStyle, ...actionStyle, ...activeIconStyle }}
        onClick={handleOnSwitchActive}
      />
    );
  return (
    <RemoveCircleIcon
      style={{ ...iconStyle, ...actionStyle }}
      onClick={handleOnSwitchActive}
    />
  );
};

ActiveCell.propTypes = {
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([1, 0])]),
  onSwitchActive: PropTypes.func
};

export default ActiveCell;
