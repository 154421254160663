/*********************************************************************
 **************************  APP CONFIG  *****************************
 *********************************************************************/
export const LOCAL_STORAGE_PREFIX = 'espeorder';
export const APP_NAME = 'Sistema de control de órdenes';

export const VALIDATE_PERMISSION = 'validate';
export const DELIVERABLES_ACCESS_PERMISSION = 'deliverables-access';
export const CLIENT_ACCESS_DOCUMENT_PERMISSION = 'client-document-access';
export const OPERATOR_ROLE_ID = 5;
export const CLIENT_ROLE_ID = 6;
export const MANAGER_ROLE_ID = 2;
