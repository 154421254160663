import request from '../api-connector';

export const getRolesService = () => {
  const serviceDef = {
    url: 'roles/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const getUsersService = (isClient) => {
  const serviceDef = {
    url: 'user/list',
    method: 'get',
    params: { is_client: isClient }
  };
  return request(serviceDef);
};

export const getUserByIdService = ({ id }) => {
  const serviceDef = {
    url: 'user/read',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const addUserService = ({
  first_name,
  last_name,
  email,
  cell_phone,
  phone,
  role_id,
  password,
  active,
  responsible
}) => {
  const serviceDef = {
    url: 'user/create',
    method: 'post',
    params: {
      first_name,
      last_name,
      email,
      cell_phone,
      phone,
      role_id,
      password,
      active,
      responsible
    }
  };
  return request(serviceDef);
};

export const updateUserService = ({
  id,
  first_name,
  last_name,
  email,
  cell_phone,
  phone,
  role_id,
  active,
  responsible
}) => {
  const serviceDef = {
    url: 'user/update',
    method: 'post',
    params: {
      id,
      first_name,
      last_name,
      email,
      cell_phone,
      phone,
      role_id,
      active,
      responsible
    }
  };
  return request(serviceDef);
};

export const changePasswordService = ({ id, password }) => {
  const serviceDef = {
    url: 'user/update/password',
    method: 'post',
    params: {
      id,
      password
    }
  };
  return request(serviceDef);
};
export const changeActiveService = ({ id }) => {
  const serviceDef = {
    url: 'user/update/active',
    method: 'post',
    params: {
      id
    }
  };
  return request(serviceDef);
};

export const changeResponsibleService = ({ id }) => {
  const serviceDef = {
    url: 'user/update/responsible',
    method: 'post',
    params: {
      id
    }
  };
  return request(serviceDef);
};

export const deleteUserService = ({ id }) => {
  const serviceDef = {
    url: 'user/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const getResponsibleListService = () => {
  const serviceDef = {
    url: 'user/list/responsible',
    method: 'post',
    params: null
  };
  return request(serviceDef);
};
