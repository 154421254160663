export function dashboardStatusText(delay, document) {
  const statatusText =
    delay >= 0
      ? document
        ? 'Entregado a tiempo'
        : 'Tiene ' + delay + ' días para entregar'
      : delay > -3
      ? 'Retrasado ' + Math.abs(delay) + ' días'
      : 'Retrasado ' + Math.abs(delay) + ' días';
  return statatusText;
}
