/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';

import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      paddingTop: '10px'
    },
    '& .MuiInputBase-adornedStart': {
      paddingLeft: '10px',
      paddingTop: '5px',
      width: '100%'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1.5px'
    },
    '& .MuiIconButton-root': {
      padding: '0'
    },
    '& .MuiInputLabel-root': {
      // whiteSpace: 'nowrap',
      height: '12px',
      fontSize: '16px',
      backgroundColor: 'white',
      padding: '10px 5px 10px 0', // padding right para separación con línea
      color: theme.palette.normal.main
    }
  },
  datePicker: {},
  input: {
    padding: '5px 10px 5px 0',
    fontSize: '12px',
    height: '30px'
  },
  invalid: {
    color: 'red'
  }
}));

export const MUIDateField = ({
  id,
  name,
  label,
  value,
  onChange,
  minDate = undefined,
  disabled,
  ...props
}) => {
  const classes = useStyles();
  const [date, setDate] = useState(null);
  const [valid, setValid] = useState(true);

  const handleOnDateChange = (dateData) => {
    setDate(dateData);
    setValid(dateData.isValid());
    if (onChange) {
      const returnDate = dateData.isValid()
        ? dateData.format('yyy-MM-DD')
        : null;
      onChange({ value: returnDate, id: id, name: name });
    }
  };

  useEffect(() => {
    if (!value) {
      // si se recibe nulo no se hace nada
      setDate(null);
      return;
    }

    var newDate = moment.isMoment(value)
      ? value
      : moment(value, ['DD/MM/yyy', 'yyy-MM-DD']);

    if (!date) {
      setDate(newDate);
      return;
    }

    const actualDateStr = date.format('yyy-MM-DD');

    var inputDateStr = moment.isMoment(value)
      ? value.format('yyy-MM-DD')
      : value;

    if (actualDateStr !== inputDateStr) setDate(newDate);
  }, [value]);

  return (
    <div id={'MUI_' + id} className={classes.root}>
      <KeyboardDatePicker
        className={classes.datePicker}
        autoOk
        id={id}
        label={label}
        value={date}
        onChange={handleOnDateChange}
        placeholder="dd/mm/YY"
        format="DD/MM/YY"
        mask="__/__/__"
        InputAdornmentProps={{ position: 'start' }}
        disableToolbar
        inputVariant="outlined"
        variant="inline"
        disabled={disabled}
        minDate={disabled === true ? undefined : minDate}
        invalidDateMessage={null}
        InputProps={{
          classes: {
            input: classes.input + (!valid ? ' ' + classes.invalid : '')
          }
        }}
      />
    </div>
  );
};

MUIDateField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

export default MUIDateField;
