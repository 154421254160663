/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

/** Import helpers section **/
import { toast } from 'react-toastify';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

/** Import helpers section **/
import { readWorkOrderService } from '../../api/services/work-orders-services';
import {
  getWorkOrderServicesService,
  updateWorkOrderServicesService
} from '../../api/services/work-order-services-services';

import {
  sendAssignationAdminsNotificationService,
  sendAssignationClientNotificationService
} from '../../api/services/assignations-services';

import { getOperatorsAssignService } from '../../api/services/assignations-services';
import { } from '../../api/services/services-services';

/** Import component section **/
import WorkOrderServicesView from './WorkOrderServicesView';
import AssignationModal from './AssignationModal';
import DocsModal from './DocsModal';
import ModalComponent from '../../components/Modal';
import MUITextArea from '../../components/inputs/MUITextArea';
import { ADD_DELIVERABLE_DOCUMENTS } from '../../config/URL_ROUTES';

const WorkOrderServicesComponent = () => {
  let history = useHistory();
  let params = useParams();
  let workOrderId = params['id'];

  const [loading, setLoading] = useState(false);
  const [workOrderTitle, setWorkOrderTitle] = useState('Servicios de la orden');
  const [workOrder, setWorkOrder] = useState(null);
  const [services, setServices] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [operators, setOperators] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalMode, setModalMode] = useState('');
  const [showChecked, setShowChecked] = useState(false);
  const [needTraining, setNeedTraining] = useState(0);
  const [filteringText, setFilteringText] = useState('');

  const getOperatorsAssign = useCallback(() => {
    getOperatorsAssignService()
      .then((response) => {
        if (response?.operators) setOperators(response.operators);
      })
      .catch((error) => {
        console.warn('No se pudo leer la lista de oreradores', error);
      });
  }, []);

  const readWorkOrder = useCallback(() => {
    readWorkOrderService({ id: workOrderId })
      .then((response) => {
        var title = 'Servicios de la orden';
        if (response?.work_order) {
          const workOrderData = response.work_order;
          const clientName =
            workOrderData?.client?.title + ' ' + workOrderData?.company?.name;
          title =
            title +
            ' ' +
            workOrderData?.order_num +
            ' del cliente ' +
            clientName;

          setWorkOrder(workOrderData);
        }
        setWorkOrderTitle(title);
      })
      .catch((error) => {
        toast.error('No se pudo leer el servicio');
      });
  }, [workOrderId]);

  useEffect(() => {
    getOperatorsAssign();
    if (workOrderId) readWorkOrder();
  }, [workOrderId, readWorkOrder, getOperatorsAssign]);

  const getWorkOrderServices = useCallback(() => {
    setLoading(true);
    getWorkOrderServicesService({ work_order_id: workOrderId })
      .then((response) => {
        const servicesArr = response?.services;
        if (servicesArr) {
          setServices(servicesArr);
          setOriginalData(servicesArr);
          setFilteringText('');
        }
      })
      .catch((error) => {
        toast.error('No se pudieron leer los documentos');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [workOrderId]);

  useEffect(() => {
    getWorkOrderServices();
  }, [workOrderId, getWorkOrderServices]);

  useEffect(() => {
    // getWorkOrderServices();
  }, []);

  const handleOnSave = () => {
    const assignation = {
      title: '',
      work_order_service_id: null,
      work_order_id: workOrderId,
      service_id: '',
      user_id: '',
      comments: ''
    };
    setSelectedItem(assignation);
    setModalMode('assign');
  };

  const handleOnSaveConfirm = () => {
    setModalMode('');
  };

  const handleOnAssign = (row) => {
    const assignation = {
      title: row?.title,
      work_order_service_id: row?.work_order_service_id,
      work_order_id: workOrderId,
      service_id: row?.id,
      user_id: row?.user_id,
      comments: row?.comments
    };
    setSelectedItem(assignation);
    setModalMode('assign');
  };

  const handleOnModalClose = () => {
    setSelectedItem(null);
    setModalMode('');
  };

  const handleOnUpdateList = (selectedService) => {
    getWorkOrderServices();
    getOperatorsAssign();
  };

  const handleOnInputDocs = (row) => {
    const docs = row?.service_documents;
    setSelectedItem(docs);
    setModalMode('docs');
  };

  const handleOnOutputDocs = (row) => {
    const docs = row?.service_deliverables;
    setSelectedItem(docs);
    setModalMode('delivs');
  };

  const handleOnFiltering = useCallback(() => {
    var filteredItems = [...originalData];
    //si mostrar seleccionados
    if (showChecked) {
      filteredItems = filteredItems.filter((item) => item.added);
    }

    if (filteringText.length > 0) {
      const filteringTextLC = filteringText.toLowerCase().normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      filteredItems = filteredItems.filter((item) => {
        const field1 =
          item && item?.title.toLowerCase().normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '').includes(filteringTextLC);
        return field1;
      });
    }

    //limpia la lista dependiendo los filtros ingresados
    setServices(filteredItems);
  }, [showChecked, filteringText, originalData]);

  const handleOnSwitchChange = (event) => {
    //muestra o no seleccionados
    setShowChecked(event?.target?.checked);
  };

  const handleOnFilterBy = (text) => {
    setFilteringText(text);
  };

  useEffect(() => {
    handleOnFiltering();
  }, [showChecked, filteringText, handleOnFiltering]);

  const sendClientNotifications = () => {
    let params = {
      work_order_id: workOrderId,
      need_training: needTraining,
      comments: workOrder?.comments
    };
    sendAssignationClientNotificationService(params)
      .then((response) => {
        if (response?.assignation) {
          toast.success(
            'Las notificaciones han sido enviadas a los responsables.'
          );
        }
        handleOnModalClose();
      })
      .catch((error) => {
        toast.error('Error al enviar notificaciones a los responsables.');
      });
  };

  const sendAdminsNotification = () => {
    let params = {
      work_order_id: workOrderId,
      need_training: needTraining,
      comments: workOrder?.comments
    };
    sendAssignationAdminsNotificationService(params)
      .then((response) => {
        if (response?.assignation) {
          toast.success(
            'Las notificaciones han sido enviadas a los responsables.'
          );
        }
        handleOnModalClose();
      })
      .catch((error) => {
        toast.error('Error al enviar notificaciones a los responsables.');
      });
  };

  const handleOnSendClientNotifications = () => {
    setModalMode('client-notifications');
  };

  const handleOnSendAdminsNotification = () => {
    setModalMode('admin-notifications');
  };

  const handleOnChanceService = (row) => {
    const params = {
      work_order_id: workOrderId,
      service_id: row?.id
    };
    updateWorkOrderServicesService(params)
      .then(() => {
        getWorkOrderServices();
        toast.success('Servicio modificado');
      })
      .catch((error) => {
        toast.error('Error, no se pudo deshabilitar el servicio');
      });
  };

  const onSwitchTrainingChange = (event) => {
    let value = event.target.checked === false ? 0 : 1;
    setNeedTraining(value);
  };

  const onInputChange = (data) => {
    const { name, value } = data;
    setWorkOrder((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnAddRequiredDocs = () => {
    const clientId = workOrder?.client_id;
    history.push(ADD_DELIVERABLE_DOCUMENTS + '/' + clientId);
  };

  return (
    <>
      <WorkOrderServicesView
        title={workOrderTitle}
        services={services}
        loading={loading}
        showChecked={showChecked}
        onFilterBy={handleOnFilterBy}
        onSwitchChange={handleOnSwitchChange}
        onSave={handleOnSave}
        onAssign={handleOnAssign}
        onInputDocs={handleOnInputDocs}
        onOutputDocs={handleOnOutputDocs}
        onSendClientNotifications={handleOnSendClientNotifications}
        onSendAdminsNotification={handleOnSendAdminsNotification}
        onChanceService={handleOnChanceService}
        onAddRequiredDocs={handleOnAddRequiredDocs}
      />
      {selectedItem && modalMode === 'assign' && (
        <AssignationModal
          assignationData={selectedItem}
          services={services}
          operators={operators}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedItem && (modalMode === 'docs' || modalMode === 'delivs') && (
        <DocsModal
          modalMode={modalMode}
          docs={selectedItem}
          onClose={handleOnModalClose}
        />
      )}
      {modalMode === 'save' && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnSaveConfirm}
          title="Actualizar servicios"
        >
          <div>
            <div>¿Estas seguro que quieres actualizar los servicios?</div>
            <div>
              Si retira un servicio se eliminará toda la información existente.
            </div>
          </div>
        </ModalComponent>
      )}

      {modalMode === 'admin-notifications' && workOrder && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={sendAdminsNotification}
          title="Enviar notificacion a responsables"
        >
          <div>
            <div>
              ¿Estas seguro que quieres enviar email de notificación a los
              responsables?
            </div>
            <div className="woServiceDocs_training">
              <FormControlLabel
                control={
                  <Switch
                    label="Capacitación"
                    checked={needTraining === 1}
                    onChange={(event) => onSwitchTrainingChange(event)}
                    name="training"
                    color="primary"
                    inputProps={{
                      'aria-label': 'secondary checkbox'
                    }}
                  />
                }
                label="Requiere capacitación"
              />
            </div>
            <MUITextArea
              label="Comentarios"
              value={workOrder?.comments}
              type="text"
              name="comments"
              onChange={onInputChange}
            />
            {console.log(workOrder)}
          </div>
        </ModalComponent>
      )}

      {modalMode === 'client-notifications' && workOrder && (
        <ModalComponent
          open={true}
          onClose={handleOnModalClose}
          onConfirm={sendClientNotifications}
          title="Enviar notificacion a cliente"
        >
          <div>
            <div>
              ¿Estas seguro que quieres enviar email de notificación al cliente?
            </div>
            <div className="woServiceDocs_training">
              <FormControlLabel
                control={
                  <Switch
                    label="Capacitación"
                    checked={needTraining === 1}
                    onChange={(event) => onSwitchTrainingChange(event)}
                    name="training"
                    color="primary"
                    inputProps={{
                      'aria-label': 'secondary checkbox'
                    }}
                  />
                }
                label="Requiere capacitación"
              />
            </div>
            <MUITextArea
              label="Comentarios"
              value={workOrder?.comments}
              type="text"
              name="comments"
              onChange={onInputChange}
            />
            {console.log(workOrder)}
          </div>
        </ModalComponent>
      )}
    </>
  );
};

export default WorkOrderServicesComponent;
