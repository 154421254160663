/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './ClientDocumentModalStyles.scss';

/** Import component section **/
import MUIDateField from '../../../components/inputs/MUIDateField';
import DropFile from '../../../components/DropFile';

const ClientDocumentModalView = ({
  isClientAccess,
  document,
  documentTypes,
  disabled,
  onInputChange,
  onFileUploaded,
  onClose,
  onSave
}) => {
  return (
    <DropFile
      open={true}
      title={'Agregar documento '}
      url={document?.url}
      onFileUploaded={onFileUploaded}
      onClose={onClose}
      onConfirm={onSave}
      confirmText="Enviar"
      cancelText="Cancelar"
      disabled={disabled}
      width={700}
    >
      <div className="clientDocumentModal">
        <MUIDateField
          id="document_date"
          name="document_date"
          label="Fecha del documento"
          value={document?.document_date}
          onChange={onInputChange}
        />
        <MUIDateField
          id="validity_date"
          name="validity_date"
          label="Fecha de caducidad"
          value={document?.validity_date}
          onChange={onInputChange}
        />
      </div>
    </DropFile>
  );
};

export default ClientDocumentModalView;
