/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import helpers section **/
import { toast } from 'react-toastify';
import {
  getPermissionsService,
  deletePermissionService,
  getPermissionByIdService
} from '../../api/services/permission-services';

/** Import resources section **/

/** Import component section **/
import PermissionsView from './PermissionsView';
import PermissionsModal from './PermissionsModal';
import Modal from '../../components/Modal';

const permissionModel = {
  id: null,
  code: '',
  last_name: '',
  type: ''
};

const PermissionsComponent = () => {
  const [permissions, setpermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState(null);
  const [modalMode, setModalMode] = useState(null);

  const getPermissions = () => {
    setLoading(true);
    getPermissionsService()
      .then((response) => {
        setpermissions(response?.permissions);
      })
      .catch((error) => {
        console.error(
          'permissionsComponent - Error requesting permissions list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getPermissions();
  }, []);
  const handleOnUpdateList = () => {
    getPermissions();
  };

  const handleOnAddPermission = () => {
    setSelectedPermission(Object.assign({}, permissionModel));
    setModalMode('permission');
  };

  const handelOnDeletePermission = (row) => {
    setSelectedPermission(row);
    setModalMode('delete');
  };

  const handleOnModalClose = () => {
    setSelectedPermission(null);
    setModalMode(null);
  };

  const handleOnDeleteConfirm = () => {
    deletePermissionService({ id: selectedPermission.id })
      .then(() => {
        getPermissions();
        toast.success('Permiso borrado');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar el permiso.');
      })
      .finally(() => {
        setSelectedPermission(null);
        setModalMode(null);
      });
  };

  const getPermissionById = (id, mode) => {
    getPermissionByIdService({ id })
      .then((response) => {
        setSelectedPermission(response?.permission);
        setModalMode(mode);
      })
      .catch((error) => {
        console.error(
          'PemissionComp - Error fetching permission data: ',
          error
        );
      });
  };
  const handleOnEditPermission = (row) => {
    getPermissionById(row.id, 'permission');
  };

  return (
    <>
      <PermissionsView
        loading={loading}
        permissions={permissions}
        onAddPermission={handleOnAddPermission}
        onEditPermission={handleOnEditPermission}
        onDeletePermission={handelOnDeletePermission}
      />

      {selectedPermission && modalMode === 'permission' && (
        <PermissionsModal
          permissionData={selectedPermission}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedPermission && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar Usuario"
        >
          {'¿Estas seguro que quieres borrar el usuario el permiso ' +
            selectedPermission.code +
            '?'}
        </Modal>
      )}
    </>
  );
};

export default PermissionsComponent;
