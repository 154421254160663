/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useSelector } from 'react-redux';
import ChatIcon from '@material-ui/icons/Chat';
import FeedbackIcon from '@material-ui/icons/Feedback';

/** Import helpers section **/
import './DashboardStyles.scss';
import { dashboardStatusText } from './dashboardUtils';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import DataTable from '../../components/DataTable';
import MUIAutocomplete from '../../components/inputs/MUIAutocomplete';
import MUISelect from '../../components/inputs/MUISelect';

const DashboardView = ({
  isDashboard,
  loading,
  dashboard,
  clients,
  selectedClient,
  onClientChange,
  operators,
  selectedOperator,
  userId,
  roleId,
  onOperatorChange,
  onDownloadPdf,
  onMessages,
  onAssignationView
}) => {
  const IsOperatorView = roleId === 5 ? true : false;
  let profile = useSelector((store) => store.session);

  const ColorIndicator = ({ color }) => {
    const addColor =
      color === 'red' ? 'danger' : color === 'yellow' ? 'alert' : '';
    return <div className={'dashboard_color ' + addColor}> </div>;
  };

  const IndicatorCell = ({ data }) => {
    const dashboardColor = data?.dashboard_color;
    return (
      <>
        <ColorIndicator color={dashboardColor} />
        {dashboardStatusText(data?.delay, null)}
      </>
    );
  };

  const WorkOderDetailsCell = ({ data }) => {
    return (
      <div className="g-actions-cell">
        {data?.work_order_services.length > 0 && (
          <>
            <Tooltip
              title="Detalles de la orden de trabajo"
              aria-label="Client docs"
            >
              <AssignmentIcon
                className="g-row-action-button normal"
                onClick={() => onAssignationView(data)}
              />
            </Tooltip>
          </>
        )}
      </div>
    );
  };

  const DownloadCell = ({ data }) => {
    return (
      <div className="g-actions-cell">
        {data?.work_order_services.length > 0 && (
          <>
            <Tooltip title="Descargar pdf" aria-label="Descargar pdf">
              <GetAppIcon
                className="g-row-action-button complementary"
                onClick={() => onDownloadPdf(data)}
              />
            </Tooltip>
          </>
        )}
      </div>
    );
  };

  const MessageCell = ({ data }) => {
    return (
      <div className="g-actions-cell">
        {data?.last_message_readed === false ? (
          <div className="dashboard_message">
            <Tooltip title="Nuevo mensaje" aria-label="Mensajes">
              <FeedbackIcon
                className="g-row-action-button delete"
                onClick={() => onMessages(data)}
              />
            </Tooltip>
            Tienes un nuevo mensaje
          </div>
        ) : (
          <Tooltip title="Mensajes" aria-label="Mensajes">
            <ChatIcon
              className="g-row-action-button complementary"
              onClick={() => onMessages(data)}
            />
          </Tooltip>
        )}
      </div>
    );
  };

  const tableColumns = [
    {
      name: 'Id',
      selector: 'id',
      sortable: true,
      center: true,
      omit: true
    },
    {
      name: 'Cliente',
      selector: (row) => row?.client?.title + ' ' + row?.company_name,
      sortable: true,
      center: true
    },
    {
      name: 'Número de órden',
      selector: 'order_num',
      sortable: true,
      center: true
    },
    profile?.role !== 'cliente' ? (
      ({
        name: 'Fecha de inicio',
        selector: 'start_date',
        sortable: true,
        center: true
      },
      {
        name: 'Indicadores',
        cell: (row) => <IndicatorCell data={row} />,
        ignoreRowClick: true
      })
    ) : (
      <></>
    ),
    {
      name: 'Descargar órden',
      cell: (row) => <DownloadCell data={row} />,
      ignoreRowClick: true,
      center: true
    },
    {
      name: 'Detalles de la órden',
      cell: (row) => <WorkOderDetailsCell data={row} />,
      ignoreRowClick: true,
      center: true
    },
    {
      name: 'Mensajes',
      cell: (row) => <MessageCell data={row} />,
      ignoreRowClick: true,
      center: true
    }
  ];

  const DocContent = ({ data }) => {
    const dateStr = data?.created_at.substring(0, 10);
    return (
      <div className="dashboard_deliverables_item_doc">
        <FileCopyIcon className="dashboard_deliverables_item_doc_icon" />
        {dateStr}
      </div>
    );
  };

  const DeliverablesContent = ({ data = [] }) => {
    if (data.length === 0) {
      return <></>;
    }

    return (
      <div className="dashboard_deliverables">
        {data.map((item, index) => (
          <div key={'deliv' + index} className="dashboard_deliverables_item">
            {item?.document_type?.name}
            <span className="dashboard_deliverables_item_indicator">
              <ColorIndicator color={item.dashboard_color} />
              {dashboardStatusText(item?.delay, item?.document)}
            </span>
            {item?.document && <DocContent data={item?.document} />}
          </div>
        ))}
      </div>
    );
  };

  const ServicesContent = ({ data = [] }) => {
    if (data?.work_order_services.length === 0) {
      return <></>;
    }

    return (
      <div className="dashboard_services">
        {data?.work_order_services.map((item, index) => (
          <div key={'service' + index} className="dashboard_services_item">
            <div className="dashboard_services_item_header">
              <span>{item?.service_name}</span>
              <span>Limite: {item?.deadline_date}</span>
              <span>
                {item?.user_name
                  ? 'Asignado a: ' + item.user_name
                  : 'No asignado'}
              </span>
              <div className="dashboard_services_item_header_indicator">
                <ColorIndicator color={item.dashboard_color} />
                <span>{dashboardStatusText(item?.delay, item?.document)}</span>
              </div>
            </div>
            <DeliverablesContent data={item?.deliverables} />
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <PageTitle title={isDashboard ? 'Tablero' : 'Avance de servicios'} />
      {isDashboard && (
        <Paper className="g-page-header dashboard_header">
          <MUIAutocomplete
            id="client"
            name="client_id"
            options={clients}
            label="Cliente"
            selector="name"
            value={selectedClient}
            onChange={onClientChange}
          />

          <MUISelect
            id="operator_id"
            label="Operador"
            defaultValue={IsOperatorView ? userId : selectedOperator}
            items={operators}
            idSelector="id"
            selector="name"
            noSelectedLabel="Seleccione"
            name="operador"
            disabled={IsOperatorView}
            onChange={onOperatorChange}
          />
        </Paper>
      )}

      {profile?.role !== 'cliente' ? (
        <DataTable
          data={dashboard}
          columns={tableColumns}
          loading={loading}
          expandableRows
          expandableRowsComponent={<ServicesContent />}
          pagination={false}
        />
      ) : (
        <DataTable
          data={dashboard}
          columns={tableColumns}
          loading={loading}
          pagination={false}
        />
      )}
    </>
  );
};

export default DashboardView;
