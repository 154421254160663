/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/

/** Import helpers section **/

/** Import component section **/
import DocsModalView from './DocsModalView';

const FILES_URL_BASE = process.env.REACT_APP_BACK_URL + 'storage/documents/';

const DocsModalComponent = ({ modalMode, docs = [], onClose }) => {
  const handleOnDownLoad = (row) => {
    const url = row?.document?.url;
    if (url) window.open(FILES_URL_BASE + url, 'Image');
  };
  return (
    <DocsModalView
      modalMode={modalMode}
      docs={docs}
      onDownLoad={handleOnDownLoad}
      onClose={onClose}
    />
  );
};

export default DocsModalComponent;
