/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import helpers section **/
import { toast } from 'react-toastify';
import {
  getCategoriesService,
  getValidityService,
  getDocumentsService,
  deleteDocumentService,
  changeActiveService
} from '../../api/services/documents-catalogue-services';

/** Import resources section **/

/** Import component section **/
import Modal from '../../components/Modal';
import DocumentModal from './DocumentModal';
import DocumentsView from './DocumentsView';

const documentModel = {
  id: null,
  name: '',
  client_document_category_id: null,
  document_validity_id: 1,
  active: 1
};

const DocumentsComponent = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [validity, setValidity] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [modalMode, setModalMode] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCategories = useCallback(() => {
    getCategoriesService()
      .then((response) => {
        if (response?.document_category)
          setCategories(response?.document_category);
      })
      .catch((error) => {
        console.error('getCategoriesService - Error getting: ', error);
      });
  }, []);

  const getValidity = useCallback(() => {
    getValidityService()
      .then((response) => {
        setValidity(response);
      })
      .catch((error) => {
        console.error('getValidityService - Error getting: ', error);
      });
  }, []);

  const getDocuments = useCallback(
    (categoryId) => {
      const document_category_id = categoryId ? categoryId : selectedCategory;
      setLoading(true);
      getDocumentsService({ document_category_id })
        .then((response) => {
          if (response?.document_types) {
            setDocuments(response?.document_types);
            setOriginalData(response?.document_types);
          }
        })
        .catch((error) => {
          console.error(
            'Documents Component - Error requesting documents list: ',
            error
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [selectedCategory]
  );

  useEffect(() => {
    getCategories();
    getValidity();
    getDocuments();
  }, [getCategories, getValidity, getDocuments]);

  const handleOnUpdateList = (selectedDocument) => {
    getDocuments();
  };

  const handleOnFilterDocuments = (filterVal) => {
    let filteredItems;

    if (!filterVal || filterVal.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterVal
        ?.toLowerCase()
        ?.normalize('NFD')
        ?.replace(/[\u0300-\u036f]/g, '');

      filteredItems = originalData.filter((item) => {
        const field1 = item.name
          ?.toString()
          .toLowerCase()
          ?.normalize('NFD')
          ?.replace(/[\u0300-\u036f]/g, '');
        const field2 = item?.description
          ? item.description
              ?.toLowerCase()
              ?.normalize('NFD')
              ?.replace(/[\u0300-\u036f]/g, '')
          : '';
        const field3 = item?.client_document_category?.name
          ? item.client_document_category.name
              ?.toLowerCase()
              ?.normalize('NFD')
              ?.replace(/[\u0300-\u036f]/g, '')
          : '';

        return (
          field1.match(filteringText) ||
          field2.match(filteringText) ||
          (field3.match(filteringText) && true)
        );
      });
    }

    setDocuments(filteredItems);
  };

  const handleOnCategoryChange = (data) => {
    const categoryId = data.value;
    setSelectedCategory(categoryId);
    getDocuments(categoryId);
  };

  const handleOnAddDocument = () => {
    const documentItem = Object.assign({}, documentModel);
    setSelectedDocument(documentItem);
    setModalMode('edit');
  };

  const handleOnEditDocument = (row) => {
    const documentItem = Object.assign({}, row);
    documentItem.client_document_category_id = row.client_document_category?.id;
    setSelectedDocument(documentItem);
    setModalMode('edit');
  };

  const handleOnModalClose = () => {
    setSelectedDocument(null);
    setModalMode(null);
  };

  const handleOnDeleteDocument = (row) => {
    setSelectedDocument(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteDocumentService({ id: selectedDocument.id })
      .then(() => {
        getDocuments();
        toast.success('Documento borrado');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar el documento.');
      })
      .finally(() => {
        setSelectedDocument(null);
        setModalMode(null);
      });
  };

  const handleOnActiveChange = (row) => {
    setSelectedDocument(row);
    setModalMode('active');
  };

  const handleOnActiveConfirm = () => {
    changeActiveService({
      id: selectedDocument.id,
      active: selectedDocument.active ? 0 : 1
    })
      .then(() => {
        getDocuments();
        toast.success('Estatus cambiado');
      })
      .catch((error) => {
        toast.error('Error, no se pudo cambiar el estatus');
      })
      .finally(() => {
        setSelectedDocument(null);
        setModalMode(null);
      });
  };

  return (
    <>
      <DocumentsView
        loading={loading}
        documents={documents}
        categories={categories}
        selectedCategory={selectedCategory}
        onFilterDocuments={handleOnFilterDocuments}
        onCategoryChange={handleOnCategoryChange}
        onAddDocument={handleOnAddDocument}
        onEditDocument={handleOnEditDocument}
        onDeleteDocument={handleOnDeleteDocument}
        onActiveDocument={handleOnActiveChange}
      />
      {selectedDocument && modalMode === 'edit' && (
        <DocumentModal
          documentData={selectedDocument}
          categories={categories}
          validity={validity}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedDocument && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar documento"
        >
          {'¿Estas seguro de borrar el documento ' +
            selectedDocument.name +
            '?'}
        </Modal>
      )}

      {selectedDocument && modalMode === 'active' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnActiveConfirm}
          title={
            selectedDocument?.active === 0
              ? 'Activar documento?'
              : 'Desactivar documento'
          }
        >
          {'¿Estas seguro de ' +
            (selectedDocument?.active === 0 ? 'activar' : 'desactivar') +
            ' el documento ' +
            selectedDocument.name +
            '?'}
        </Modal>
      )}
    </>
  );
};

export default DocumentsComponent;
