/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
// import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import { Paper, Tooltip } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

/** Import helpers section **/
import './ClientDocumentStorageStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import DataTable from '../../components/DataTable';

const ClientDocumentStorageView = ({
  loading,
  documents,
  onDownLoad,
  onEditDocument,
  onUpdateDocument,
  onDriveRedirect,
  onUploadDocument
}) => {
  const ActionsCell = ({ row }) => {
    return (
      <>
        {row?.document ? (
          <>
            <GetAppIcon
              className="g-row-action-button complementary"
              onClick={() => onDownLoad(row)}
            />
            <EditIcon
              className="g-row-action-button"
              onClick={() => onEditDocument(row)}
            />
          </>
        ) : (
          <PublishIcon
            className="g-row-action-button secondary"
            onClick={() => onUpdateDocument(row)}
          />
        )}
      </>
    );
  };

  const ServiceActiveCell = ({ row }) => {
    if (row.url)
      return (
        <Tooltip title="Entregado">
          <CheckCircleOutlineIcon
            className="g-row-action-button clickEffect"
            onClick={() => onUploadDocument(row)}
          />
        </Tooltip>
      );
    return (
      <Tooltip title="Pendiente">
        <NotInterestedIcon
          className="g-row-action-button clickEffect_disabled"
          onClick={() => onUploadDocument(row)}
        />
      </Tooltip>
    );
  };

  const tableColumns = [
    {
      name: 'Id',
      selector: 'id',
      sortable: true,
      center: true,
      omit: true
    },
    {
      name: 'Documento',
      selector: (row) => row?.document_type?.name,
      sortable: true,
      center: true,
      grow: 2
    },
    {
      name: 'Descripción',
      selector: (row) => row?.document_type?.description,
      sortable: true,
      center: true,
      grow: 2
    },
    {
      name: 'Estatus',
      cell: (row) => <ServiceActiveCell row={row} />,
      sortable: true,
      center: true
    },
    {
      name: 'Comentarios',
      selector: (row) => row?.comments,
      sortable: true,
      center: true
    }
  ];
  return (
    <>
      <Paper className="g-page-header clients_header">
        <PageTitle title="Documentos requeridos" />
      </Paper>
      <DataTable data={documents} columns={tableColumns} loading={loading} />
    </>
  );
};

export default ClientDocumentStorageView;
