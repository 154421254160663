import request from '../api-connector';

export const getRequiredDocumentsService = ({ service_id }) => {
  const serviceDef = {
    url: 'service-document',
    method: 'post',
    params: { service_id }
  };
  return request(serviceDef);
};

export const updateRequiredDocumentsService = ({ service_id, documents }) => {
  const serviceDef = {
    url: 'service-document/update',
    method: 'post',
    params: { service_id, documents }
  };
  return request(serviceDef);
};

export const getDeliverableDocumentsService = ({ service_id, documents }) => {
  const serviceDef = {
    url: 'service-deliverable',
    method: 'post',
    params: { service_id }
  };
  return request(serviceDef);
};

export const createDeliverableDocumentsService = ({
  service_id,
  document_type_id
}) => {
  const serviceDef = {
    url: 'service-deliverable/create',
    method: 'post',
    params: { service_id, document_type_id }
  };
  return request(serviceDef);
};

export const updateDeliverableDocumentsService = ({
  service_id,
  documents
}) => {
  const serviceDef = {
    url: 'service-deliverable/update',
    method: 'post',
    params: { service_id, documents }
  };
  return request(serviceDef);
};
